import React, {useEffect, useState} from 'react';
import {ICase} from "../../models/ICase";
import WorkService from "../../services/WorkService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import {WorkModel, WorkModelDisable} from "../../models/Work";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import LineChart from "../../components/common/LineChart";
import {Data} from "../../components/common/Data";
import zIndex from '@mui/material/styles/zIndex';
import Loader from "../../components/common/Loader";
import {useLocation} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    works : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    visitStatusList : [];
    admissionStatusList : [];
    exitStatusList : [];
    expectEndDateList : [];
    supplier_list : [];
    affiliation_company_list : [];
    isChecked : boolean;
    work_model : WorkModel;
    disable_work_model : WorkModelDisable;
    loading :boolean;
}
interface IProps{}
let WorkList:React.FC<IProps> = () => {
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    
    const defaultcolumns = [
        {
            name: "work_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                        updateWork(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name : 'work_id',
            label :  "稼働ID",
            // setCellProps: () => ({ style: { border : "1px solid rgb(242,242,242)" }}),
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person-full_name',
            label : '要員名',
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px",maxWidth: "300px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[2])}>
                            <div className='longText' title={value} style={{color:"blue",width:"130px"}}>
                                    {value}
                            </div>
                        </Link>);
                    },
                },
        },
        // {
        //     name : 'person-full_name',
        //     label : '要員名',
        // },
        {
            name : 'work_company',
            label : '稼働会社',
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px"}})
            }
        },
        {
            name: "case-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
        {
            name :  'case-case_name',
            label : '案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "200px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>caseDetail(tableMeta.rowData[5])}>
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            name : 'case-saler-name_jp',
            label : '営業担当',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'case-customer-company',
            label : '取引先',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[30]}&type=${tableMeta.rowData[31]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                               {value}
                       </div></Link>
                     );
                    },
                },
        },
        // {
        //     name : 'person-company-company',
        //     label : '所属会社名',
        //     class: "longText",
        //         options: {
        //             filter: true, 
        //             setCellProps: () => ({ style: { maxWidth: "100px" }}),
        //             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //                 return(<Link to={`/persons/${tableMeta.rowData[2]}`} >
        //                      <div className='longText' title={value} style={{color:"blue"}}>
        //                             {value}
        //                     </div>
        //                     </Link>);
        //                 },
        //             },
        // },
        {
            name : 'buyer_contract',
            label : '受注契約',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name :  'seller_contract',
            label : '発注契約',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'work_start_date',
            label : '稼働開始日',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "130px", maxWidth: "200px"}})
            }
        },
        {
            name : 'work_place',
            label : '勤務場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: {  minWidth: "103px", maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'work_end_date_plan',
            label: '稼働終了予定日',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'settlement',
            label: '精算',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'settlement_to',
            label :  '精算To',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'settlement_biko' ,
            label : '精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "80px", maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'payment_site',
            label :'支払サイト',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "130px", maxWidth: "200px"}})
            }
        },
        {
            name : 'o_adj_hours_low' ,
            label : '乙方精算From',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'o_adj_hours_high' ,
            label: '乙方精算To',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'o_adj_hours_biko' ,
            label : '乙方精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'o_payment_site' ,
            label : '乙方支払サイト',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "120px", maxWidth: "200px"}})
            }
        },
        {
            name : 'flb_shared_cmt',
            label : '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'accept_proc_date',
            label : '入場手続き完了日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'security_edu_date',
            label : 'セキュリティ教育日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"150px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name :  'entrance_date',
            label : '入場日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_date',
            label : '訪問日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_remarks',
            label : '訪問記録',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'customer_remarks',
            label : '現場評価',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}})
            }
        },
        {
            name : 'exit_date',
            label : '退場日',
            options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "103px", maxWidth: "200px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'case-customer-partner_id',
            label : '取引先 ID',
            "options":{
                "display":false,
             }
        },
        {
            name : 'case-customer-partner_type',
            label : 'パートナタープ',
            "options":{
                "display":false,
             }
        },
        ];
    const allcolumns = [
            {
                name: "work_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "103px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateWork(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                        },
                    },
            },
            {
                name : 'work_id',
                label :  "稼働ID"
            },
            {
                name: "person-encode",
                label: "Encode",
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'person-full_name',
                label : '要員名',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "103px",maxWidth: "300px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[2])}>
                             <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                            </div>
                            </Link>);
                        },
                    },
            },
            {
                name : 'work_company',
                label : '稼働会社',
                options: {
                    filter: true,
                    setCellProps: () => ({ style: { maxWidth: "100px"}})
                }
            },
            {
                name : 'case-hr-name_jp',
                label : 'HR担当者'
            },
            {
                name :  'person-belong_type',
                label : '所属'
            },
            {
                name : 'person-company-company',
                label : '所属会社名',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<div className='longText' title={value}>
                                    {value}
                            </div>);
                        },
                    },
            },
            {
                name : 'person-belong_contact_email',
                label : '所属連絡メールアドレス'
            },
            {
                name :  'person_cost' ,
                label : 'コスト'
            },
            {
                name : 'person-cost_memo',
                label : 'コスト備考'
            },
            {
                name: "case-encode",
                label: "Encode",
                "options":{
                    "display":false,
                 }
               },
            {
                name :  'case-case_name',
                label : '案件名',
                class : 'LongText',
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "300px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to="" onClick={() =>caseDetail(tableMeta.rowData[11])} >
                             <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                            </div>
                            </Link>);
                        },
                    },
            },
            {
                name : 'case-saler-name_jp',
                label : '営業担当',
                class: "longText",
                options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
            },
            {
                name : 'case-customer-company',
                label : '取引先',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "200px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[39]}&type=${tableMeta.rowData[40]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                   {value}
                           </div></Link>
                         );
                        },
                    },
            },
            {
                name : 'buyer_contract',
                label : '受注契約'
            },
            {
                name :  'seller_contract',
                label : '発注契約'
            },
            {
                name : 'work_start_date',
                label : '稼働開始日'
            },
            {
                name : 'work_place',
                label : '勤務場所',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'work_end_date_plan',
                label: '稼働終了予定日'
            },
            {
                name : 'contract_price',
                label : '稼働単金'
            },
            {
                name : 'contract_price_type',
                label : '稼働単金種別'
            },
            {
                name : 'settlement',
                label: '精算'
            },
            {
                name : 'settlement_to',
                label :  '精算To'
            },
            {
                name : 'settlement_biko' ,
                label : '精算備考',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'payment_site',
                label :'支払サイト'
            },
            {
                name : 'o_adj_hours_low' ,
                label : '乙方精算Flom'
            },
            {
                name : 'o_adj_hours_high' ,
                label: '乙方精算To'
            },
            {
                name : 'o_adj_hours_biko' ,
                label : '乙方精算備考',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'o_payment_site' ,
                label : '乙方支払サイト'
            },
            {
                name : 'flb_shared_cmt',
                label : '社内共有メモ',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'accept_proc_date',
                label : '入場手続き完了日',
                class: "longText",
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'security_edu_date',
                label : 'セキュリティ教育日',
                class: "longText",
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name :  'entrance_date',
                label : '入場日',
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'hearing_date',
                label : '訪問日',
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'hearing_remarks',
                label : '訪問記録'
            },
            {
                name : 'customer_remarks',
                label : '現場評価'
            },
            {
                name : 'exit_date',
                label : '退場日',
                options: {
                    filter: true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'case-customer-partner_id',
                label : '取引先 ID',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'case-customer-partner_type',
                label : 'パートナタープ',
                "options":{
                    "display":false,
                 }
            },

         ];
 

    const showCaseColumns = [
        {
            name: "work_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                        updateWork(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name : 'work_id',
            label :  "稼働ID"
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person-full_name',
            label : '要員名',
            class : 'LongText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px",maxWidth: "300px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[2])} >
                       <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            name : 'work_company',
            label : '稼働会社',
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px"}})
            }
        },
        {
            name : 'case-hr-name_jp',
            label : 'HR担当者'
        },
        {
            name :  'person-belong_type',
            label : '所属'
        },
        {
            name : 'person-company-company',
            label : '所属会社名',
            class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<div className='longText' title={value}>
                                    {value}
                            </div>);
                        },
                    },
        },
        {
            name : 'person-belong_contact_email',
            label : '所属連絡メールアドレス'
        },
        {
            name :  'person_cost' ,
            label : 'コスト'
        },
        {
            name : 'person-cost_memo',
            label : 'コスト備考'
        },
        {
            name: "case-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
        {
            name :  'case-case_name',
            label : '案件名',
            class : 'LongText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>caseDetail(tableMeta.rowData[11])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            name : 'case-saler-name_jp',
            label : '営業担当',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                }
        },
        {
            name : 'case-customer-company',
            label : '取引先',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[36]}&type=${tableMeta.rowData[37]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                               {value}
                       </div></Link>
                     );
                    },
                },
        },
        {
            name : 'buyer_contract',
            label : '受注契約'
        },
        {
            name :  'seller_contract',
            label : '発注契約'
        },
        {
            name : 'work_start_date',
            label : '稼働開始日'
        },
        {
            name : 'work_place',
            label : '勤務場所',
            class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
        },
        {
            name : 'work_end_date_plan',
            label: '稼働終了予定日'
        },
        {
            name : 'settlement',
            label: '精算'
        },
        {
            name : 'settlement_to',
            label :  '精算To'
        },
        {
            name : 'settlement_biko' ,
            label : '精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'payment_site',
            label :'支払サイト'
        },
        {
            name : 'o_adj_hours_low' ,
            label : '乙方精算Flom'
        },
        {
            name : 'o_adj_hours_high' ,
            label: '乙方精算To'
        },
        {
            name : 'o_adj_hours_biko',
            label : '乙方精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'o_payment_site' ,
            label : '乙方支払サイト'
        },
        {
            name : 'flb_shared_cmt',
            label : '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'accept_proc_date',
            label : '入場手続き完了日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'security_edu_date',
            label : 'セキュリティ教育日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name :  'entrance_date',
            label : '入場日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_date',
            label : '訪問日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_remarks',
            label : '訪問記録'
        },
        {
            name : 'customer_remarks',
            label : '現場評価'
        },
        {
            name : 'exit_date',
            label : '退場日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'case-customer-partner_id',
            label : '取引先 ID',
            "options":{
                "display":false,
             }
        },
        {
            name : 'case-customer-partner_type',
            label : 'パートナタープ',
            "options":{
                "display":false,
             }
        },
       ];

    const moneycolumns = [
        {
            name: "work_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                        updateWork(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name : 'work_id',
            label :  "稼働ID"
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person-full_name',
            label : '要員名',
            class : "LongText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "103px",maxWidth: "300px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[2])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                    </Link>);
                    },
                },
        },
        {
            name : 'work_company',
            label : '稼働会社',
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "100px"}})
            }
        },
        {
            name: "case-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
           },
        {
            name :  'case-case_name',
            label : '案件名',
            class : 'LongText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>caseDetail(tableMeta.rowData[5])} >
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                    },
                },
        },
        {
            name : 'case-saler-name_jp',
            label : '営業担当',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'case-customer-company',
            label : '取引先',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[32]}&type=${tableMeta.rowData[33]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                               {value}
                       </div></Link>
                     );
                    },
                },
        },
        {
            name : 'buyer_contract',
            label : '受注契約'
        },
        {
            name :  'seller_contract',
            label : '発注契約'
        },
        {
            name : 'work_start_date',
            label : '稼働開始日'
        },
        {
            name : 'work_place',
            label : '勤務場所',
            class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
        },
        {
            name : 'work_end_date_plan',
            label: '稼働終了予定日'
        },
        {
            name : 'contract_price',
            label : '稼働単金'
        },
        {
            name : 'contract_price_type',
            label : '稼働単金種別'
        },
        {
            name : 'settlement',
            label: '精算'
        },
        {
            name : 'settlement_to',
            label :  '精算To'
        },
        {
            name : 'settlement_biko' ,
            label : '精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'payment_site',
            label :'支払サイト'
        },
        {
            name : 'o_adj_hours_low' ,
            label : '乙方精算From'
        },
        {
            name : 'o_adj_hours_high' ,
            label: '乙方精算To'
        },
        {
            name : 'o_adj_hours_biko' ,
            label : '乙方精算備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'o_payment_site' ,
            label : '乙方支払サイト'
        },
        {
            name : 'flb_shared_cmt',
            label : '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'accept_proc_date',
            label : '入場手続き完了日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'security_edu_date',
            label : 'セキュリティ教育日',
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name :  'entrance_date',
            label : '入場日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_date',
            label : '訪問日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'hearing_remarks',
            label : '訪問記録'
        },
        {
            name : 'customer_remarks',
            label : '現場評価'
        },
        {
            name : 'exit_date',
            label : '退場日',
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'case-customer-partner_id',
            label : '取引先 ID',
            "options":{
                "display":false,
             }
        },
        {
            name : 'case-customer-partner_type',
            label : 'パートナタープ',
            "options":{
                "display":false,
             }
        },
     ];



       const getMuiTheme = () =>
    createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                    //   padding: '0px'
                  }
                }
        },
        // MuiTableCell: {
        //     head: {
        //         backgroundColor: "red !important"
        //     }
        // }

        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                //   padding: '0px'
              }
            }
          }
        }
      })

    let [state , setState] = useState<IState>({
        works : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        visitStatusList : [],
        admissionStatusList : [],
        exitStatusList : [],
        expectEndDateList : [],
        supplier_list : [],
        affiliation_company_list : [],
        isChecked: false,
        work_model : {
            work_id: "",
            person_id : 0,
            case_id : 0,
            buyer_contract: "",
            seller_contract : "",
            work_start_date : "",
            work_place : "",
            work_end_date_plan : "",
            contract_price : "",
            contract_price_type : "",
            settlement : "",
            settlement_to : "",
            settlement_biko : "",
            payment_site : "",
            person_cost : "",
            o_adj_hours_low : "",
            o_adj_hours_high : "",
            o_adj_hours_biko : "",
            o_payment_site : "",
            flb_shared_cmt : "",
            accept_proc_date : "",
            security_edu_date : "",
            entrance_date : "",
            hearing_date: "",
            hearing_remarks : "",
            customer_remarks : "",
            exit_date : "",
        },
        disable_work_model : {
            person_full_name : "",
            work_company : "",
            case_hr_name_jp : "",
            person_belong_type : "",
            person_company_company : "",
            person_belong_contact_email : "",
            person_cost_memo : "",
            case_case_name : "",
            case_saler_name_jp : "",
            case_customer_company : ""
        },
        loading :false,
    });

    const [admission_status, setAdmissionStatus] = useState("1");
    const [visit_status, setVisitStatus] = useState("1");
    const [exit_status, setExitStatus] = useState("1");
    const [expect_end_date, setExpectedEndDate] = useState("1");
    const [year_months, setYearMonths] = useState("");
    const [operation_flg, setOperationFlg] = useState("0");
    const [money_flg, setMoneyFlg] = useState("1");
    const [share_memo_filter, setShareMemoFilter] = useState("");
    const [belongs_filter, setBelongsFilter] = useState("");
    const [supplier_filter, setSupplierFilter] = useState("");
    const [affiliation_company_filter, setAffiliationCompanyFilter] = useState("");
    const [search, setSearch] = useState("");
    const result : string[] = [];
    const [casechecked, setCaseChecked] = useState<boolean>(false);
    const [moneychecked, setMoneyChecked] = useState<boolean>(false);
    const [columnVisible, setColumnVisible] = React.useState(defaultcolumns);
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  
    const [checked, isChecked] = useState(false);
    const [selectedYearMonth, setSelectedYearMonth] = useState(null);
    const [admission_proc, setAdmissionProc] = useState([]); 
    const [security_edu, setSecurityEdu] = useState([]); 
    const [entrance, setEntrance] = useState([]); 
    const [visit, setVisit] = useState([]); 
    const [site_eva, setSiteEva] = useState([]); 
    const [exit, setExit] = useState([]); 
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    const [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
    const [selectedWorkEndDatePlan, setSelectedWorkEndDatePlan] = useState(null);
    const [selectedAcceptProcDate, setSelectedAcceptProcDate] = useState(null);
    const [selectedSecurityEduDate, setSelectedSecurityEduDate] = useState(null);
    const [selectedEntranceDate, setSelectedEntranceDate] = useState(null);
    const [selectedHearingDate, setSelectedHearingDate] = useState(null);
    const [selectedExitDate, setSelectedExitDate] = useState(null);
    const [expect_contract_type, setExpectContractType] = useState([]);
    const [payment_site_list, setPaymentSite] = useState([]);
    const [o_payment_site_list, setOPaymentSite] = useState([]);
    var now = new Date();
    const [monthflg, setMonthflg] = useState((now.getMonth() + 1).toString());
    const [yearflg, setYearFlg] = useState("1");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(734);
    const [showChart, setShowChart] = useState(true);


    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4
    //   };
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
          }
        ]
      });
    // const [chartData, setChartData] = useState();
  
    const getData = async (admission_status:any,visit_status:any,exit_status:any,expect_end_date:any,year_months:any,operation_flg:any, money_flg:any, 
        share_memo_filter:any, belongs_filter:any, supplier_filter:any, affiliation_company_filter:any, perPage:number, page:number,
        search:any, sort:any, column:string, order:string, businessFlow: any, monthflg:any, yearflg:any) => {
            setState({...state,loading :true,})
            await WorkService.list(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
             share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order ,businessFlow,monthflg,yearflg).then((response)=>{

            setState({
                ...state,
                works:response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                column : response.data.column,
                order : response.data.order,
                totalRows : response.data.total,
                visitStatusList : response.data.select_options.visit_status,
                admissionStatusList : response.data.select_options.admission_status,
                exitStatusList : response.data.select_options.exit_status,
                expectEndDateList : response.data.select_options.expect_end_date,
                supplier_list : response.data.suppliers,
                affiliation_company_list : response.data.affiliation_company,
                loading :false,
            })
            setAdmissionProc(response.data.admission_proc);
            setSecurityEdu(response.data.security_edu);
            setEntrance(response.data.entrance);
            setVisit(response.data.visit);
            setSiteEva(response.data.site_eva);
            setExit(response.data.exit);
            setExpectContractType(response.data.select_options.expect_contract_type);
            setPaymentSite(response.data.select_options.payment_site);
            setOPaymentSite(response.data.select_options.o_payment_site);

            if(Math.floor(response.data.total/response.data.per_page) >0){
                console.log("Response1111 " +response.data.per_page);
                setShowNextColor("blue");
            }else{
                console.log("Response2222 " +response.data.per_page);
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                console.log("Response3333 " +response.data.per_page);
                setShowPrevColor("grey");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            }

            let flonet_data = response.data.chart_data.chartYflonet;
            let floboard_data = response.data.chart_data.chartYfloboard;
            let xAxis = response.data.chart_data.chartXaxis;

            setMonthflg(response.data.chart_data.monthFlg);
            setYearFlg(response.data.chart_data.yearFlg);

            var flonet_chart_data:any[] = [];
            for(var i in flonet_data){
                flonet_chart_data.push(flonet_data[i]);
            }

            var floboard_chart_data:any[] = [];
            for(var j in floboard_data){
                floboard_chart_data.push(floboard_data[j]);
            }

            var x_axis:any[] = [];
            for(var k in xAxis)
            x_axis.push(xAxis[k]);

            setChartData({
                labels: x_axis,
                datasets: [
                {
                    label: '稼働人数:Flonet',
                    data:   flonet_chart_data,
                },
                {
                    label: '稼働人数:FloBoard',
                    data:   floboard_chart_data,
                },
                ],
            });
            //return  WorkService.chartData(monthflg,yearflg);

        })
        // .then(response2 => {
        //     console.log(response2);
        //         let flonet_data = response2.data.chart_data.chartYflonet;
        //         let floboard_data = response2.data.chart_data.chartYfloboard;
        //         let xAxis = response2.data.chart_data.chartXaxis;

        //         setMonthflg(response2.data.chart_data.monthFlg);
        //         setYearFlg(response2.data.chart_data.yearFlg);

        //         var flonet_chart_data:any[] = [];
        //         for(var i in flonet_data){
        //             flonet_chart_data.push(flonet_data[i]);
        //         }

        //         var floboard_chart_data:any[] = [];
        //         for(var j in floboard_data){
        //             floboard_chart_data.push(floboard_data[j]);
        //         }

        //         var x_axis:any[] = [];
        //         for(var k in xAxis)
        //         x_axis.push(xAxis[k]);

        //         setChartData({
        //             labels: x_axis,
        //             datasets: [
        //             {
        //                 label: '稼働人数:Flonet',
        //                 data:   flonet_chart_data,
        //             },
        //             {
        //                 label: '稼働人数:FloBoard',
        //                 data:   floboard_chart_data,
        //             },
        //             ],
        //         });
        // })
        .catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                errorMessage:error.message,
                loading :false,
            })
           
        });

              
        // await WorkService.chartData(monthflg,yearflg).then((response)=>{
        //     console.log(response);
            
           
        // })


       
    }
    let {works, perPage, page, column, order, totalRows, visitStatusList, admissionStatusList, exitStatusList, expectEndDateList, 
        supplier_list, affiliation_company_list, errorMessage , work_model ,disable_work_model, loading} = state;

    const selectAll = (e:any) => {
        if(checked == false){
            isChecked(true);
            let ids : any[] = [];
            // works.forEach((element, index) => {
            //     ids.push(element["case_id"]);
            //     setCheckedRows(ids);
            //   });
            $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
        }else{
            isChecked(false);
            setCheckedRows([]);
            $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
        }

        // .addClass("list_check_all");
        console.log(checkedRows);
      };

    useEffect(()=>{
        setState({...state, loading: true});
        getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
            share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow,monthflg,yearflg);
        
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
            share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page+1,search, sort, column, order,businessFlow,monthflg,yearflg)
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
            share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow,monthflg,yearflg)
    };
    


    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "case":
                if(e.target.checked == true){
                    setCaseChecked(e.target.checked)
                    if(moneychecked){
                        setColumnVisible(allcolumns);
                    }else{
                        setColumnVisible(showCaseColumns);
                    }
                }else
                {
                    setCaseChecked(e.target.checked);
                    if(moneychecked){
                        setColumnVisible(moneycolumns);
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            case "money":
                if(e.target.checked == true){
                    setMoneyChecked(e.target.checked);
                    if(casechecked){
                        setColumnVisible(allcolumns);
                    }else{
                        setColumnVisible(moneycolumns);
                    }
                }else
                {
                    setMoneyChecked(e.target.checked)
                    if(casechecked){
                        setColumnVisible(showCaseColumns);
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            default:
                break;
        }
        
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "visit_status":
                setVisitStatus(e.target.value);
                getData(admission_status,e.target.value,exit_status,expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "admission_status":
                setAdmissionStatus(e.target.value);
                getData(e.target.value,visit_status, exit_status,expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "exit_status":
                setExitStatus(e.target.value);
                getData(admission_status,visit_status, e.target.value,expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "expect_end_date":
                setExpectedEndDate(e.target.value);
                getData(admission_status,visit_status, exit_status, e.target.value,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,e.target.value);
                break;
            case "share_memo_filter":
                setShareMemoFilter(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    e.target.value, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "belongs_filter":
                setBelongsFilter(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, e.target.value, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "supplier_filter":
                setSupplierFilter(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, e.target.value, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "affiliation_company_filter":
                setAffiliationCompanyFilter(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, e.target.value, perPage, page,search, sort, column, order,businessFlow, monthflg,yearflg);
                break;
            case "expect_start_month":
                setMonthflg(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, e.target.value,yearflg);
                break;
            case "expect_end_date":
                setYearFlg(e.target.value);
                getData(admission_status,visit_status, exit_status, expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow, monthflg, e.target.value);
                break;
            case "sort":
                setSort(e.target.value);
                getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, e.target.value, column, order,businessFlow, monthflg,yearflg)
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })

                break;
            default:
                break;
        }
     };

     let handleDelete = (rowData:any, rowState:any) => {
        let rows : any[] = [];
        rowData.data.forEach((element:any, index:any) => {
            rows.push(element.dataIndex);
        })
        let ids : any[] = [];
        works.forEach((element, index) => {
          if (rows.includes(index)) {
            ids.push(element["case_id"]);
          }
        });
        // CaseListService.delete(ids).then((response:any)=>{
        //     if(response.data.success == false){
        //         alert(response.data.info.message);
        //         window.location.reload();
        //     }
        // }).catch((error:any) => {
        //     setState({
        //         ...state,
        //         errorMessage:error.message
        //     })
        // })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        console.log(temp);
        // temp.page = this.state.page;
        getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
            share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, "", column, order,businessFlow, monthflg,yearflg)
      };

    const handleDateChange = function(date:any, column_name:any){
        // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()); 
        let selected_data = (date!=null)?date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()):""; 
        // let selected_yearmonth = date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0");
        let selected_yearmonth = (date!=null)?date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0"):"";
            switch (column_name) {
                case "year_months":
                    setSelectedYearMonth(date);
                    getData(admission_status,visit_status,exit_status,expect_end_date,selected_yearmonth,operation_flg, money_flg,
                        share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, "", column, order,businessFlow, monthflg,yearflg)
                    break;
                case "work_start_date":
                    setSelectedWorkStartDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            work_start_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "work_end_date_plan":
                    setSelectedWorkEndDatePlan(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            work_end_date_plan : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "accept_proc_date":
                    setSelectedAcceptProcDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            accept_proc_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "security_edu_date":
                    setSelectedSecurityEduDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            security_edu_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "entrance_date":
                    setSelectedEntranceDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            entrance_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "hearing_date":
                    setSelectedHearingDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            hearing_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "exit_date":
                    setSelectedExitDate(date);
                    setState({
                        ...state,
                        work_model : {
                            ...state.work_model,
                            exit_date : date // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                    
                default:
                    break;
            }
    
        }
    
   
    
        let updateWork = (work_id:any) => {
        WorkService.edit(work_id).then((response)=>{
            console.log( response.data);
            if(response.data.status == "error"){
                alert(response.data.msg);
            }else{
                let data = response.data.work;
                setState({
                    ...state,
                    work_model : {
                        work_id: data.work_id,
                        person_id : data.person_id,
                        case_id : data.case_id,
                        buyer_contract: data.buyer_contract,
                        seller_contract : data.seller_contract,
                        work_start_date : data.work_start_date,
                        work_place : data.work_place,
                        work_end_date_plan : data.work_end_date_plan,
                        contract_price : data.contract_price,
                        contract_price_type : data.contract_price_type,
                        settlement : data.settlement,
                        settlement_to : data.settlement_to,
                        settlement_biko : data.settlement_biko,
                        payment_site : data.payment_site,
                        person_cost : data.person_cost,
                        o_adj_hours_low : data.o_adj_hours_low,
                        o_adj_hours_high : data.o_adj_hours_high,
                        o_adj_hours_biko : data.o_adj_hours_biko,
                        o_payment_site : data.o_payment_site,
                        flb_shared_cmt : data.flb_shared_cmt,
                        accept_proc_date : data.accept_proc_date,
                        security_edu_date : data.security_edu_date,
                        entrance_date : data.entrance_date,
                        hearing_date: data.hearing_date,
                        hearing_remarks : data.hearing_remarks,
                        customer_remarks : data.customer_remarks,
                        exit_date : data.exit_date,
                    },
                    disable_work_model : {
                        person_full_name : data.person_full_name,
                        work_company : data.work_company,
                        case_hr_name_jp : data.case_hr_name_jp,
                        person_belong_type : data.person_belong_type,
                        person_company_company : data.person_company_company,
                        person_belong_contact_email : data.person_belong_contact_email,
                        person_cost_memo : data.person_cost_memo,
                        case_case_name : data.case_case_name,
                        case_saler_name_jp : data.case_saler_name_jp,
                        case_customer_company : data.case_customer_company,
                    }
                   })
                  
                   setOpen(true);
           }
         
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const options = {
        filterType: 'checkbox' as any,
        responsive: "scrollMaxHeight" as any,
        // selectableRowsHeader: true as any, 
        page: page,
        rowsPerPage: perPage,
        rowsPerPageOptions:[10,20,50,100,200],
        selectableRows: true as any,
        // selectableRowsOnClick: true  as any,
        selectableRowsHeader: true,
        fixedHeader: true,
        fixedSelectColumn: true,
        tableBodyHeight: '400px',
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // enableColumnResizing: true,
        // columnResizeDirection: 'rtl',
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //     console.log(rows); //  <-------- can't get only selected rows
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {        
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                works.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "稼働管理" +date;
                }
                let headers  : any[]= [];
                if (moneychecked && casechecked){
                    headers =  [
                        {
                            key : 'work_id',
                            label :  "稼働ID"
                        },
                        {
                            key : 'person-full_name',
                            label : '要員名',
                        },
                        {
                            key : 'work_company',
                            label : '稼働会社'
                        },
                        {
                            key : 'case-hr-name_jp',
                            label : 'HR担当者'
                        },
                        {
                            key :  'person-belong_type',
                            label : '所属'
                        },
                        {
                            key : 'person-company-company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person-belong_contact_email',
                            label : '所属連絡メールアドレス'
                        },
                        {
                            key :  'person_cost' ,
                            label : 'コスト'
                        },
                        {
                            key : 'person-cost_memo',
                            label : 'コスト備考'
                        },
                        {
                            key :  'case-case_name',
                            label : '案件名'
                        },
                        {
                            key : 'case-saler-name_jp',
                            label : '営業担当'
                        },
                        {
                            key : 'case-customer-company',
                            label : '取引先'
                        },
                        {
                            key : 'buyer_contract',
                            label : '受注契約'
                        },
                        {
                            key :  'seller_contract',
                            label : '発注契約'
                        },
                        {
                            key : 'work_start_date',
                            label : '稼働開始日'
                        },
                        {
                            key : 'work_place',
                            label : '勤務場所'
                        },
                        {
                            key : 'work_end_date_plan',
                            label: '稼働終了予定日'
                        },
                        {
                            key : 'contract_price',
                            label : '稼働単金'
                        },
                        {
                            key : 'contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'settlement',
                            label: '精算'
                        },
                        {
                            key : 'settlement_to',
                            label :  '精算To'
                        },
                        {
                            key : 'settlement_biko' ,
                            label : '精算備考'
                        },
                        {
                            key : 'payment_site',
                            label :'支払サイト'
                        },
                        {
                            key : 'o_adj_hours_low' ,
                            label : '乙方精算Flom'
                        },
                        {
                            key : 'o_adj_hours_high' ,
                            label: '乙方精算To'
                        },
                        {
                            key : 'o_adj_hours_biko' ,
                            label : '乙方精算備考'
                        },
                        {
                            key : 'o_payment_site' ,
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'flb_shared_cmt',
                            label : '社内共有メモ'
                        },
                        {
                            key : 'accept_proc_date',
                            label : '入場手続き完了日'
                        },
                        {
                            key : 'security_edu_date',
                            label : 'セキュリティ教育日'
                        },
                        {
                            key :  'entrance_date',
                            label : '入場日'
                        },
                        {
                            key : 'hearing_date',
                            label : '訪問日'
                        },
                        {
                            key : 'hearing_remarks',
                            label : '訪問記録'
                        },
                        {
                            key : 'customer_remarks',
                            label : '現場評価'
                        },
                        {
                            key : 'exit_date',
                            label : '退場日'
                        }
                     ];
             
            
               
                }else if(moneychecked){
                    headers = [
                        {
                            key : 'work_id',
                            label :  "稼働ID"
                        },
                        {
                            key : 'person-full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_company',
                            label : '稼働会社'
                        },
                        {
                            key :  'case-case_name',
                            label : '案件名',
                        },
                        {
                            key : 'case-saler-name_jp',
                            label : '営業担当'
                        },
                        {
                            key : 'case-customer-company',
                            label : '取引先'
                        },
                        {
                            key : 'buyer_contract',
                            label : '受注契約'
                        },
                        {
                            key :  'seller_contract',
                            label : '発注契約'
                        },
                        {
                            key : 'work_start_date',
                            label : '稼働開始日'
                        },
                        {
                            key : 'work_place',
                            label : '勤務場所'
                        },
                        {
                            key : 'work_end_date_plan',
                            label: '稼働終了予定日'
                        },
                        {
                            key : 'contract_price',
                            label : '稼働単金'
                        },
                        {
                            key : 'contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'settlement',
                            label: '精算'
                        },
                        {
                            key : 'settlement_to',
                            label :  '精算To'
                        },
                        {
                            key : 'settlement_biko' ,
                            label : '精算備考'
                        },
                        {
                            key : 'payment_site',
                            label :'支払サイト'
                        },
                        {
                            key : 'o_adj_hours_low' ,
                            label : '乙方精算From'
                        },
                        {
                            key : 'o_adj_hours_high' ,
                            label: '乙方精算To'
                        },
                        {
                            key : 'o_adj_hours_biko' ,
                            label : '乙方精算備考'
                        },
                        {
                            key : 'o_payment_site' ,
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'flb_shared_cmt',
                            label : '社内共有メモ'
                        },
                        {
                            key : 'accept_proc_date',
                            label : '入場手続き完了日'
                        },
                        {
                            key : 'security_edu_date',
                            label : 'セキュリティ教育日'
                        },
                        {
                            key :  'entrance_date',
                            label : '入場日'
                        },
                        {
                            key : 'hearing_date',
                            label : '訪問日'
                        },
                        {
                            key : 'hearing_remarks',
                            label : '訪問記録'
                        },
                        {
                            key : 'customer_remarks',
                            label : '現場評価'
                        },
                        {
                            key : 'exit_date',
                            label : '退場日'
                        }
                     ];
    
                
                }else if (casechecked) {
                    headers = [
                        {
                            key : 'work_id',
                            label :  "稼働ID"
                        },
                        {
                            key : 'person-full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_company',
                            label : '稼働会社'
                        },
                        {
                            key : 'case-hr-name_jp',
                            label : 'HR担当者'
                        },
                        {
                            key :  'person-belong_type',
                            label : '所属'
                        },
                        {
                            key : 'person-company-company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person-belong_contact_email',
                            label : '所属連絡メールアドレス'
                        },
                        {
                            key :  'person_cost' ,
                            label : 'コスト'
                        },
                        {
                            key : 'person-cost_memo',
                            label : 'コスト備考'
                        },
                        {
                            key :  'case-case_name',
                            label : '案件名'
                        },
                        {
                            key : 'case-saler-name_jp',
                            label : '営業担当'
                        },
                        {
                            key : 'case-customer-company',
                            label : '取引先'
                        },
                        {
                            key : 'buyer_contract',
                            label : '受注契約'
                        },
                        {
                            key :  'seller_contract',
                            label : '発注契約'
                        },
                        {
                            key : 'work_start_date',
                            label : '稼働開始日'
                        },
                        {
                            key : 'work_place',
                            label : '勤務場所'
                        },
                        {
                            key : 'work_end_date_plan',
                            label: '稼働終了予定日'
                        },
                        {
                            key : 'settlement',
                            label: '精算'
                        },
                        {
                            key : 'settlement_to',
                            label :  '精算To'
                        },
                        {
                            key : 'settlement_biko' ,
                            label : '精算備考'
                        },
                        {
                            key : 'payment_site',
                            label :'支払サイト'
                        },
                        {
                            key : 'o_adj_hours_low' ,
                            label : '乙方精算Flom'
                        },
                        {
                            key : 'o_adj_hours_high' ,
                            label: '乙方精算To'
                        },
                        {
                            key : 'o_adj_hours_biko' ,
                            label : '乙方精算備考'
                        },
                        {
                            key : 'o_payment_site' ,
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'flb_shared_cmt',
                            label : '社内共有メモ'
                        },
                        {
                            key : 'accept_proc_date',
                            label : '入場手続き完了日'
                        },
                        {
                            key : 'security_edu_date',
                            label : 'セキュリティ教育日'
                        },
                        {
                            key :  'entrance_date',
                            label : '入場日'
                        },
                        {
                            key : 'hearing_date',
                            label : '訪問日'
                        },
                        {
                            key : 'hearing_remarks',
                            label : '訪問記録'
                        },
                        {
                            key : 'customer_remarks',
                            label : '現場評価'
                        },
                        {
                            key : 'exit_date',
                            label : '退場日'
                        }
                       ];
                
                   
                }else{
                    headers =  [
                        {
                            key : 'work_id',
                            label :  "稼働ID"
                        },
                        {
                            key : 'person-full_name',
                            label : '要員名',
                        },
                        {
                            key : 'person-full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_company',
                            label : '稼働会社'
                        },
                        {
                            key :  'case-case_name',
                            label : '案件名'
                        },
                        {
                            key : 'case-saler-name_jp',
                            label : '営業担当'
                        },
                        {
                            key : 'case-customer-company',
                            label : '取引先'
                        },
                        {
                            key : 'buyer_contract',
                            label : '受注契約'
                        },
                        {
                            key :  'seller_contract',
                            label : '発注契約'
                        },
                        {
                            key : 'work_start_date',
                            label : '稼働開始日'
                        },
                        {
                            key : 'work_place',
                            label : '勤務場所'
                        },
                        {
                            key : 'work_end_date_plan',
                            label: '稼働終了予定日'
                        },
                        {
                            key : 'settlement',
                            label: '精算'
                        },
                        {
                            key : 'settlement_to',
                            label :  '精算To'
                        },
                        {
                            key : 'settlement_biko' ,
                            label : '精算備考'
                        },
                        {
                            key : 'payment_site',
                            label :'支払サイト'
                        },
                        {
                            key : 'o_adj_hours_low' ,
                            label : '乙方精算From'
                        },
                        {
                            key : 'o_adj_hours_high' ,
                            label: '乙方精算To'
                        },
                        {
                            key : 'o_adj_hours_biko' ,
                            label : '乙方精算備考'
                        },
                        {
                            key : 'o_payment_site' ,
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'flb_shared_cmt',
                            label : '社内共有メモ'
                        },
                        {
                            key : 'accept_proc_date',
                            label : '入場手続き完了日'
                        },
                        {
                            key : 'security_edu_date',
                            label : 'セキュリティ教育日'
                        },
                        {
                            key :  'entrance_date',
                            label : '入場日'
                        },
                        {
                            key : 'hearing_date',
                            label : '訪問日'
                        },
                        {
                            key : 'hearing_remarks',
                            label : '訪問記録'
                        },
                        {
                            key : 'customer_remarks',
                            label : '現場評価'
                        },
                        {
                            key : 'exit_date',
                            label : '退場日'
                        }
                
                        ];
                   
                }
            var now = new Date();
            if(login_user.role == 9){
                return (
                    <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
                );
            }
            // return (
            //     <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            // );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            console.log("selected");
            console.log(rowsSelected);
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            console.log(currentPage);
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            console.log("No of Row" + numberOfRows);
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //     // console.log("start");
        //    goToDetail(rowData, rowState);
        //     // console.log("end");
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            console.log(colData);
            console.log(cellMeta);
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
                        share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,e.target.value,sort, column, order,businessFlow, monthflg,yearflg);
                    break;
            default:
                break;
        }
        
    };  

    const businessFlow = function(value:any) {
        getData(admission_status,visit_status,exit_status,expect_end_date,year_months,operation_flg, money_flg,
            share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page, search,sort, column, order, value, monthflg,yearflg);
    }

    const onSelectWork = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            work_model : {
                ...state.work_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    let updateInput = (e:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            work_model : {
                ...state.work_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });      
    };

    let updateTextArea = (e:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            work_model : {
                ...state.work_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
          console.log("work id "+state.work_model.work_id);
            WorkService.update(state.work_model, state.work_model.work_id).then((response) => {
                getData(admission_status,visit_status,exit_status,expect_end_date,selectedYearMonth,operation_flg, money_flg,
                    share_memo_filter, belongs_filter, supplier_filter, affiliation_company_filter, perPage, page,search, sort, column, order,businessFlow,monthflg,yearflg);
                handleClose();
                // window.location.href = "/management/works/list";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
    };

    const deleteWork = () => {
        if(selectedRows.length == 0){
            alert("削除したいをチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            works.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["work_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            WorkService.deleteList(data).then((data)=>{
                if(data.data.success === false){
                    alert(data.data.info.message);
                }else{
                    window.location.reload(); 
                }
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
       
    }

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];

    let location = useLocation();
    const personDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.open(`/persons/${encode}`, '_blank');
    }
    const caseDetail = function (encode:any) {
        localStorage.setItem('rootRoute', location.pathname);
        localStorage.setItem('previousRoute', location.pathname);
        window.open(`/cases/${encode}`, '_blank');
    }

 const edit_modal_box  = (
        <div>
               <div className="row">
                    <div className="col-6">
                        <h3>要員稼働情報編集</h3>
                    </div>
                    <div className="col-6">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form create-form" onSubmit={store}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="work_id" value={work_model["work_id"]} />
                    <div className="form-group">
                        <label htmlFor="work_id">稼働ID:</label> 
                        <span>{work_model["work_id"]}</span>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group ">
                                <label htmlFor="exampleInputEmail1" className="text"> 要員名:</label> 
                                <input type="text" name="person_full_name" className="form-control" value={disable_work_model["person_full_name"]} disabled /> 
                            </div>       
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required text">稼働:</label> 
                                <select name="work_company" onChange={onSelectWork} value={work_model["work_company"]} style={{width:'100%'}}>
                                    <option value="FloBoard">FloBoard</option>
                                    <option value="FloNet">FloNet</option>
                                </select>    
                            </div>          
                        </div>
                    </div>                  
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">HR担当者:</label> 
                                <input type="text" className="form-control" name="case_hr_name_jp"  value={disable_work_model['case_hr_name_jp']}  disabled />                                     
                            </div>
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required text">所属:</label> 
                                <input type="text" className="form-control" name="person_belong_type"  value={disable_work_model['person_belong_type']}  disabled />
                            </div>           
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">所属会社名:</label> 
                                <input type="text" className="form-control" name="person_company_company"  value={disable_work_model['person_company_company']}  disabled />
                            </div>  
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1" className="text">所属連絡メールアドレス:</label> 
                            <input type="text" className="form-control" name="person_belong_contact_email"  value={disable_work_model['person_belong_contact_email']}  disabled/>       
                            </div>     
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">コスト備考:</label> 
                                <input type="text" className="form-control" name="person_cost_memo"  value={disable_work_model['person_cost_memo']} disabled/>
                            </div>        
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="contract_price_type" className="text">案件名:</label>
                                <input type="text" className="form-control" name="case_case_name"  value={disable_work_model['case_case_name']}  disabled />                      
                            </div>         
                        </div>
                    </div>
                   <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">営業担当:</label> 
                                <input type="text" className="form-control" name=""  value={disable_work_model['case_saler_name_jp']} disabled />                      
                            </div>        
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">取引先:</label> 
                                <input type="text" className="form-control" name="case_customer_company"  value={disable_work_model['case_customer_company']}  disabled/>
                            </div>             
                        </div>
                   </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="text">受注契約:</label>
                                <select name="buyer_contract" className="input-box form-control" value={(work_model["buyer_contract"] != "")?work_model["buyer_contract"]:""} onChange={onSelectWork} aria-label=".form-select-sm example" aria-readonly= {(login_user.role==5)?true:false}>
                                        <option value="" hidden> 選択なし</option>
                                        {expect_contract_type.map(buyer_contract => {
                                                if (buyer_contract !== null){
                                                    return(<option value={buyer_contract}> {buyer_contract}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                            </div>         
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <span id='text' className="input-required text">発注契約</span>
                                    <select name="seller_contract" className="input-box form-control" value={(work_model["seller_contract"] != "")?work_model["seller_contract"]:""} onChange={onSelectWork} aria-label=".form-select-sm example"  aria-readonly= {(login_user.role==5)?true:false}>
                                        <option value="" hidden> 選択なし</option>
                                        {expect_contract_type.map(seller_contract => {
                                                if (seller_contract !== null){
                                                    return(<option value={seller_contract}> {seller_contract}</option>)
                                                }
                                            })
                                        }
                                    </select>      
                            </div>        
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className="input-required text">稼働開始日</span>
                            <DatePicker 
                                    value={(work_model["work_start_date"] != null)?work_model["work_start_date"]: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedWorkStartDate} 
                                    onChange={date =>
                                        handleDateChange(date, "work_start_date")
                                    }
                                  
                                    name="work_start_date"
                                    readOnly = {(login_user.role==5)?true:false}
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />
                        </div>
                        <div className="col-6 input-box">
                            <span id='text' className="input-required text">勤務場所</span>
                            <input type="text" name="work_place" id="work_place" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={work_model["work_place"]} onChange={updateInput}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box text">
                        <span id='text'>稼働終了予定日</span>
                        <DatePicker 
                            value={(work_model["work_end_date_plan"] != null)?work_model["work_end_date_plan"]: ""}
                            dateFormat="yyyy/MM/dd"
                            locale="ja"
                            selected={selectedWorkEndDatePlan} 
                            onChange={date =>
                                handleDateChange(date, "work_end_date_plan")
                            }
                            name="work_end_date_plan"
                            readOnly = {(login_user.role==5)?true:false}
                            isClearable
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            />
                        </div>
                        <div className="col-6 input-box">
                            <span id='text' className="input-required text">稼働単金</span>
                            <input type={(login_user.role==5)?"hidden":"text"} name="contract_price" id="contract_price" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["contract_price"]} onChange={updateInput} readOnly={(login_user.role==6)?true:false}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className="input-required text">稼働単金種別</span>
                            <select name="contract_price_type" className="input-box form-control" value={(work_model["contract_price_type"] != "")?work_model["contract_price_type"]:""} onChange={onSelectWork} aria-label=".form-select-sm example" style={(login_user.role==5)?{display:"none"}:{display:"box"}}>
                                <option value="0"> 月額単金</option>
                                <option value="1"> 時間単価</option>                                       
                            </select> 
                        </div>
                        <div className="col-6 input-box">
                            <span className='text'>精算</span>
                            <input type="text" name="settlement" id="settlement" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["settlement"]} onChange={updateInput} readOnly={(login_user.role==5)?true:false}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box ">
                            <span className="input-required text">精算To</span>
                            <input type="text" name="settlement_to" id="settlement_to" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["settlement_to"]} onChange={updateInput} readOnly={(login_user.role==5)?true:false}/>
                        </div>
                        <div className="col-6 input-box">
                            <span className='text'>精算備考</span>
                            <input type="text" name="settlement_biko" id="settlement_biko" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["settlement_biko"]} onChange={updateInput} readOnly={(login_user.role==9)?true:false}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className='text'>支払サイト</span> 
                            <select name="payment_site" className="input-box form-control" value={(work_model["payment_site"] != "")?work_model["payment_site"]:""} onChange={onSelectWork} aria-label=".form-select-sm example" disabled={(login_user.role==5)?true:false}>
                                <option value="" hidden> 選択なし</option>
                                {payment_site_list.map(payment_site => {
                                        if (payment_site !== null){
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        }
                                    })
                                }
                            </select> 
                        </div>
                        <div className="col-6 input-box">
                            <span className='text'>コスト</span>
                            <input type="text" name="person_cost" id="person_cost" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["person_cost"]} onChange={updateInput}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className='text'>乙方精算From</span>
                            <input type="text" name="o_adj_hours_low" id="o_adj_hours_low" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["o_adj_hours_low"]} onChange={updateInput} readOnly ={(login_user.role==5)?true:false}/>
                        </div>    
                        <div className="col-6 input-box">
                            <span className='text'>乙方精算To</span>
                            <input type="text" name="o_adj_hours_high" id="o_adj_hours_high" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["o_adj_hours_high"]} onChange={updateInput} readOnly ={(login_user.role==5)?true:false}/>
                        </div>         
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className='text'>乙方精算備考</span>
                            <input type="text" name="o_adj_hours_biko" id="o_adj_hours_biko" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={work_model["o_adj_hours_biko"]} onChange={updateInput} readOnly={(login_user.role==5)?true:false}/>
                        </div>
                        <div className="col-6 input-box">
                            <span className='text'>乙方支払サイト</span> 
                            <select name="o_payment_site" className="input-box form-control" value={(work_model["o_payment_site"] != "")?work_model["o_payment_site"]:""} onChange={onSelectWork} aria-label=".form-select-sm example" disabled={(login_user.role==5)? true:false}>
                                <option value="" hidden> 選択なし</option>
                                {o_payment_site_list.map(payment_site => {
                                        if (payment_site !== null){
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        }
                                    })
                                }
                            </select> 
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className='text'>社内共有メモ</span>
                            <textarea className="form-control" rows={3} name = "flb_shared_cmt" id= "flb_shared_cmt" aria-label="With textarea" value={work_model["flb_shared_cmt"]} onChange={updateTextArea}></textarea>
                        </div> 
                        <div className="col-6 input-box">
                            <span className='text'>入場手続き完了日</span>
                            <DatePicker 
                                value={(work_model["accept_proc_date"] != null)?work_model["accept_proc_date"] : ""}
                                dateFormat="yyyy/MM/dd"
                                locale="ja"
                                selected={selectedAcceptProcDate} 
                                onChange={date =>
                                    handleDateChange(date, "accept_proc_date")
                                }
                                name="accept_proc_date"
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span className='text'>セキュリティ教育日</span>
                            <DatePicker 
                                value={(work_model["security_edu_date"] != null)?work_model["security_edu_date"]: ""}
                                dateFormat="yyyy/MM/dd"
                                locale="ja"
                                selected={selectedSecurityEduDate} 
                                onChange={date =>
                                    handleDateChange(date, "security_edu_date")
                                }
                                name="security_edu_date"
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                />
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>入場日</span>
                            <DatePicker 
                                    value={(work_model["entrance_date"] != null)?work_model["entrance_date"]: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedEntranceDate} 
                                    onChange={date =>
                                        handleDateChange(date, "entrance_date")
                                    }
                                    name="entrance_date"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />
                        </div>
                    </div>
                    <div className="row mt-3">
                         <div className="col-6 input-box">
                            <span id='text'>訪問日</span>
                            <DatePicker 
                                value={(work_model["hearing_date"] != null)?work_model["hearing_date"]: ""}
                                dateFormat="yyyy/MM/dd"
                                locale="ja"
                                selected={selectedHearingDate} 
                                onChange={date =>
                                    handleDateChange(date, "hearing_date")
                                }
                                name="hearing_date"
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                />
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>訪問日登録</span>
                            <textarea className="form-control" rows={3} name = "hearing_remarks" id= "hearing_remarks" aria-label="With textarea" value={work_model["hearing_remarks"]} onChange={updateTextArea}></textarea>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>現場評価</span>
                            <textarea className="form-control" rows={3} name = "customer_remarks" id= "customer_remarks" aria-label="With textarea" value={work_model["customer_remarks"]} onChange={updateTextArea}></textarea>
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>退場日</span>
                            <DatePicker 
                                value={(work_model["exit_date"] != null)?work_model["exit_date"]: ""}
                                dateFormat="yyyy/MM/dd"
                                locale="ja"
                                selected={selectedExitDate} 
                                onChange={date =>
                                    handleDateChange(date, "exit_date")
                                }
                                name="exit_date"
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(work_model['partner_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </div>
                </form>
        </div>
    )
    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(稼働ID、要員ID、要員名、案件ID、案件名、取引先名、受注契約、勤務場所、現場評価を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <Row>
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                <h3 className='header-left-design'>稼働管理<span className="attention">(受発注手続き画面の経理テーブルに受注日が設定されると、ここでの稼働データの変更は経理テーブルに反映されなくなります。)</span></h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={{ span: 4, offset: 4 }} xl={{ span: 4, offset: 4 }} md={{ span: 4, offset: 4 }} 
                                sm={6} xs={6}>
                                <div className="mt-3 text-center">
                                    {( loading   == true) ? <Loader /> :<> </>}
                                </div>
                            </Col>
                            <Col xxl={8} xl={8} md={8} sm={6} xs={6} className="float-end">
                                <div className="anken-add mt-4">
                                    {
                                        (() => {
                                            if(login_user.role == 6 || login_user.role == 9) {
                                            return(<Link to={`/management/work/create`} className="btn_add">
                                            要員稼働追加
                                        </Link>)
                                            }
                                        })()
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col xxl={4} xl={4} md={4} sm={6} xs={12}>
                                <div className="table-responsive work-table">
                                    <table className="table-bordered">
                                        <thead>
                                            <tr>
                                                <th>業務フロー</th>
                                                <th>未完了（件数）</th>
                                                <th>本月完（件数）</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>入場手続き</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(1);
                                                        }}>{admission_proc["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(2);
                                                        }}>{admission_proc["finished"]}
                                                    </button>
                                                </td>                                               
                                            </tr>
                                            <tr>
                                                <td>セキュリティ教育</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(3);
                                                        }}>{security_edu["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(4);
                                                        }}>{security_edu["finished"]}
                                                    </button>
                                                </td>    
                                            </tr>
                                            <tr>
                                                <td>入場</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(5);
                                                        }}>{entrance["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(6);
                                                        }}>{entrance["finished"]}
                                                    </button>
                                                </td>    
                                            </tr>
                                            <tr>
                                                <td>訪問</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(7);
                                                        }}>{visit["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(8);
                                                        }}>{visit["finished"]}
                                                    </button>
                                                </td> 
                                            </tr>
                                            <tr>
                                                <td>現場評価登録</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(9);
                                                        }}>{site_eva["unfinished"]}
                                                    </button>
                                                </td>
                                                <td style={{textAlign:"center"}}>-</td>
                                            </tr>
                                            <tr>
                                                <td>退場</td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(10);
                                                        }}>{exit["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard work-count ml_20 btn-sm" onClick={() => {
                                                        businessFlow(11);
                                                        }}>{exit["finished"]}
                                                    </button>
                                                </td>   
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                           </Col>
                           <Col xxl={8} xl={8} md={8} sm={6} xs={12}>
                              <div className="row">
                                <div className="col-12">
                                    <label htmlFor="sort" className="control-label">対象月</label>
                                    <select className="filter" name="expect_start_month"  value={monthflg} aria-label=".form-select-sm example" onChange={onSelect}>
                                            {monthList.map(month => {
                                                return(<option value={month}> {month}月</option>)
                                            })
                                        }
                                    </select>  
                                    <label htmlFor="sort" className="control-label">期間</label>
                                    <select className="filter" name="expect_end_date" value={yearflg} aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="1">直近1年間</option>
                                        <option value="2">直近2年間</option>
                                        <option value="3">直近3年間</option>
                                    </select>                   
                                </div>
                              </div>
                              <LineChart chartData={chartData} />
                           </Col>
                        </Row>
                        <Row className="p-2">
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="check-list">
                                <input className="ml_20" type="checkbox" value="" id="case_checkbox" name ="case"  checked={casechecked} onChange={handleChange} /> 
                                <label htmlFor="case_checkbox" className="control-label">所属・案件表示</label>
                                <label htmlFor="sort" className="control-label" style={{paddingLeft:"10px"}}>訪問状況</label>
                                <select className="filter" name="visit_status" aria-label=".form-select-sm example" onChange={onSelect} value={visit_status}>
                                        {visitStatusList.map(visit_status => {
                                            return(<option value={visit_status[0]}> {visit_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">入場状況</label>
                                <select className="filter" name="admission_status" aria-label=".form-select-sm example"  value={admission_status} onChange={onSelect}>
                                        {admissionStatusList.map(admission_status => {
                                            return(<option value={admission_status[0]}> {admission_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">退場状況</label>
                                <select className="filter" name="exit_status" aria-label=".form-select-sm example"  value={exit_status} onChange={onSelect}>
                                        {exitStatusList.map(exit_status => {
                                            return(<option value={exit_status[0]}> {exit_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">終了予定日</label>
                                <select className="filter" name="expect_end_date" aria-label=".form-select-sm example" value={expect_end_date} onChange={onSelect}>
                                        {expectEndDateList.map(expect_end_date => {
                                            return(<option value={expect_end_date[0]}> {expect_end_date[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">稼働年月</label>
                                <DatePicker 
                                    value={(selectedYearMonth != null)?selectedYearMonth: ""}
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={selectedYearMonth} 
                                    onChange={date =>
                                        handleDateChange(date, "year_months")
                                    }
                                    isClearable
                                    name="year_months"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    />
                            </Col>
                        </Row>
                        {
                                    (() => {
                                        if(login_user.role ==6 || login_user.role == 9) {
                                        return(
                                            <Row className="p-2">
                                                <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="check-list">
                                                    <input className="ml_20" type="checkbox" value="" id="money_checkbox" name ="money" checked={moneychecked} onChange={handleChange} /> 
                                                    <label htmlFor="money_checkbox" className="control-label">単金情報表示</label>
                                                    <label htmlFor="sort" className="control-label" style={{paddingLeft:"10px"}}>稼働</label>
                                                    <select className="filter" name="share_memo_filter"  value={share_memo_filter} aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="">選択なし</option>
                                                        <option value="FloBoard">FloBoard</option>
                                                        <option value="FloNet">FloNet</option>              
                                                    </select>
                                                    <label htmlFor="sort" className="control-label">所属</label>
                                                    <select className="filter" name="belongs_filter" value={belongs_filter} aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="">選択なし</option>
                                                        <option value="社員">社員</option>
                                                        <option value="個人">個人</option>     
                                                        <option value="他社">他社</option>                                                                   
                                                    </select>
                                                    <label htmlFor="sort" className="control-label">取引先</label>
                                                    <select className="filter" name="supplier_filter" value={supplier_filter} aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="">選択なし</option>
                                                            {Object.keys(supplier_list).map((key, value) => {
                                                                if (value !== null){
                                                                    return(<option value={[key]}> {supplier_list[key]}</option>)
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                    <label htmlFor="sort" className="control-label">所属会社</label>
                                                    <select className="filter" name="affiliation_company_filter" value={affiliation_company_filter} aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="">選択なし</option>
                                                        {Object.keys(affiliation_company_list).map((key, value) => {
                                                                if (value !== null){
                                                                    return(<option value={[key]}> {affiliation_company_list[key]}</option>)
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                    <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteWork}>削除
                                                    </button>
                                                </Col>
                                            </Row>
                                        )
                                        }
                                    })()
                        }
                        <Row className="p-2">
                            <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            </Col>
                            <Col xxl={8} xl={8} md={8} sm={8} xs={6} className="filter_select">
                                <div className="float-right">
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>                                   
                                    {/* <label htmlFor="sort" className="control-label ml_20 ">すべての件数：{totalRows}件</label> */}
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                        </button>
                                        <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                            <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button> 
                                </div>
                            </Col>
                        </Row>
                        <div className='datatable work_list_table freeze-header freeze-cell frozen-columns xs-hidden'>
                            <MUIDataTable
                                title={''}
                                data={works}
                                columns={columnVisible}
                                options = {options}
                            />
                        </div>
                        <div className='datatable mobile pc-hidden'>
                            <MUIDataTable
                                title={''}
                                data={works}
                                columns={columnVisible}
                                options = {options}
                            />
                        </div>
                </Container>

        
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
             {edit_modal_box}
            </Box>
        </Modal>  

         <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
             {edit_modal_box}
            </Box>
        </Modal>                   
        
        </React.Fragment>
    );
};
export default WorkList;