import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class ProcedureService {
    list(target_months:any,order_status:any,purchase_status:any,filter_bill_status:any,share_memo_filter:any,belongs_filter:any, supplier_filter:any, 
        affiliation_company_filter:any, perPage:number, page:number,
        search_keywords:any, sort:any, column:string, order:string,proce_count:any, monthflg:any, yearflg:any){
        let dataURL:string = API_URL + `/procedures/list?target_months=${target_months}&order_status=${order_status}`;
        dataURL += `&purchase_status=${purchase_status}&filter_bill_status=${filter_bill_status}&filter_bill_status=${filter_bill_status}`;
        dataURL += `&share_memo_filter=${share_memo_filter}&belongs_filter=${belongs_filter}&supplier_filter=${supplier_filter}`;
        dataURL += `&affiliation_company_filter=${affiliation_company_filter}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}&proce_count=${proce_count}`;
        dataURL += `&monthflg=${monthflg}&yearflg=${yearflg}`
        return axios.get(dataURL, header);
    }

    // create(){
    //     let dataURL:string = API_URL + `/works/create1`;
    //     return axios.get(dataURL, header);
    // }

    // store(data:any) {
    //     let dataURL:string = API_URL + "/works/store1";
    //     return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` } });
    // }

    edit(id:any){
        let dataURL:string = API_URL + "/procedures/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        console.log(data);
        let dataURL:string = API_URL + "/procedures/update1/"+id;
        return axios.post(dataURL,data, header);
    }

    deleteList(data:any) {
        let dataURL:string = API_URL + "/procedures/list/destroy1";
        return axios.post(dataURL,data, header);
    }

    copy(data:any){
        let dataURL:string = API_URL + "/procedures/copy1";
        return axios.post(dataURL,data, header);
    }

    createPurchaseOrder(data:any){
        let dataURL:string = API_URL + "/procedures/purchase-order-file1";
        return axios.post(dataURL,data, header);
    }



    downloadPurchaseOrder(data:any){
        let dataURL:string = API_URL + "/download/purchase-order-file1";
        return axios.post(dataURL,data,  {
            headers :  {
                // "server_url" : `${SERVER_URL}`,
                "client-domain" :  `${CLIENT_DOMAIN}`,
                "Access-Control-Allow-Origin": "*" ,
               } 
        });

    }

    admitPurchaseOrder(data:any){
        let dataURL:string = API_URL + "/procedures/purchase-order-admit1";
        return axios.post(dataURL,data, header);
    }

    admitBill(data:any){
        let dataURL:string = API_URL + "/bill/bill-admit1";
        return axios.post(dataURL,data, header);
    }

    dlPurchaseOrderFile(accounting_id:any){
        let dataURL:string = API_URL + "/procedures/download-purchase-order-file1/"+accounting_id;
        return axios.get(dataURL, header);
    }

    dlBillFile(accounting_id:any){
        let dataURL:string = API_URL + "/bill/download-bill-file1/"+accounting_id;
        return axios.get(dataURL, header);
    }

    createBill(data:any){
        let dataURL:string = API_URL + "/bill/invoice1";
        return axios.post(dataURL,data, header);
    }
    downloadBill(data:any){
        let dataURL:string = API_URL + "/download/bill";
        return axios.post(dataURL,data,  {
            headers :  {
                // "server_url" : `${SERVER_URL}`,
                "client-domain" :  `${CLIENT_DOMAIN}`,
                "Access-Control-Allow-Origin": "*" ,
               } 
        });

    }
  

    checkKey(id:any){
        let dataURL:string = API_URL + "/checkkey/"+id;
        return axios.get(dataURL, header);
    }
    
    // sendPurchaseOrderMail(data:any){
    //     let dataURL:string = API_URL + "bill/send-purchaseorder-mail";
    //     return axios.post(dataURL,data, header);
    // }

    
}

  export default new ProcedureService();