import React, {useEffect, useState} from 'react';
import {ICase} from "../../models/ICase";
import ProcedureService from "../../services/ProcedureService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {SendBulkMail} from "../../models/ICase";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import {ProcedureModel, ProcedureModelDisable} from "../../models/Procedure";
import ResourceService from "../../services/ResourceService";
import FileSaver from 'file-saver';
import LineChart from "../../components/common/LineChart";
import {Data} from "../../components/common/Data";
import { blue } from '@mui/material/colors';
import Loader from "../../components/common/Loader";
import {useLocation} from "react-router-dom";
import {showErrorInfo, inputNumber, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    procedures : [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    orderStatusList : [];
    purchaseStatusList : [];
    filterBillStatusList : [];
    supplier_list : [];
    affiliation_company_list : [];
    procedure_model : ProcedureModel;
    disable_procedure_model : ProcedureModelDisable;
    loading :boolean;
}
interface IProps{}
let ProcedureList:React.FC<IProps> = () => {
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');

    const isDisplay = () => {
        if (login_user.role !=5) return true;
        else return false;
      };

    const defaultColumns =[
        {
            name: "accounting_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        updateAccounting(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name :  'accounting_id' ,
            label : '経理ID',
        },
        {
            name : 'work_id',
            label : '稼働ID'
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person_full_name',
            label : '要員名',
            class : "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to="" onClick={() =>personDetail(tableMeta.rowData[3])}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                    </Link>);
                },
            },
        },
        {
            name : 'work_month',
            label : '対応月'
        },
        {
            name : 'person_company_company',
            label : '所属会社名',
            class: "longText",
            options:{
                display:true,
                setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/procurement/partners/list/2?id=${tableMeta.rowData[53]}&type=${tableMeta.rowData[54]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                    </Link>);
                    },
                }
        },
        {
            name : 'case_customer_company',
            label : '取引先',
            class: 'longText',
            options:{
            display : true,
            setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
            customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[55]}&type=${tableMeta.rowData[56]}`}  target="_blank">
                     <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                    </div></Link>
                  );
                },
            }
        },
        {
            name : 'bill_from_date',
            label : '当月稼働開始日',
            // display : (login_user.role==5)?true:false
        },
        {
            name : 'bill_to_date',
            label : '当月稼働終了日',
            // options:{
            // display : (login_user.role==5)?true:false
            // }
        },
        {
            name : 'work_place',
            label : '稼働場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                    );
                    },
                },
            // options:{
            // display : (login_user.role==5)?true:false
            // }
        },
        {
            name : 'work_hours',
            label : '稼働時間',
        },
        {
            name : 'worked_days',
            label : '出勤日数',
        },
        {
            name : 'holidaywork_days',
            label : '休日日数',
        },
        {
            name : 'substitute_holiday',
            label : '振替日数',
        },
        {
            name : 'days_absence',
            label : '欠勤日数',
        },
        {
            name : 'work_end_date_plan',
            label : '終了予定日',
            // options:{
            // display : (login_user.role==5)?true:false
            // }
        },
        {
            name : 'sale_order_date',
            label : '受注日'
        },
        {
            name : 'sale_order_no',
            label : '受注番号'
        },
        {
            name : 'sale_order_nm',
            label : '受注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'buyer_contruct',
            label : '受注契約'
        },
        {
            name : 'work_contract_price_type',
            label : '単金種別'
        },
        {
            name : 'sale_price_hour',
            label : '受注時間単金'
        },
        {
            name : 'sale_price_excess',
            label : '受注超過単金'
        },
        {
            name : 'sale_price_deduct',
            label: '受注控除単金'
        },
        {
            name : 'sale_price_month',
            label: '受注月標準単金'
        },
        {
            name : 'sale_work_rate' ,
            label: '受注稼働率'
        },
        {
            name : 'sale_order_amount',
            label : '受注金額'
        },
        {
            name : 'sale_order_end_d',
            label : '受注終了予定日'
        },
        {
            name : 'buyup_order_date',
            label : '発注日'
        },
        {
            name : 'buyup_order_no',
            label: '発注番号'
        },
        {
            name : 'buyup_order_nm',
            label : '発注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name :  'seller_contruct',
            label: '発注契約'
        },
        {
            name : 'buyup_price_hour',
            label: '発注時間単金'
        },
        {
            name : 'buyup_price_excess',
            label: '発注超過単金'
        },
        {
            name : 'buyup_price_deduct',
            label: '発注控除単金'
        },
        {
            name : 'buyup_price_month',
            label: '発注月標準単金'
        },
        {
            name :'buyup_work_rate' ,
            label:  '発注稼働率'
        },
        {
            name : 'buyup_order_amount',
            label: '発注金額'
        },
        {
            name : 'buyup_order_end_d',
            label: '発注終了予定日'
        },
        {
            name : 'work_settlement',
            label: '精算From',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work_settlement_to',
            label: '精算To',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work_payment_site',
            label: '支払サイト',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work_o_adj_hours_low',
            label : '乙方精算From',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work_o_adj_hours_high',
            label : '乙方精算To',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work_o_payment_site',
            label : '乙方支払サイト',
            display : (login_user.role==5)?true:false
        },
        {
            name : 'work-flb_shared_cmt',
            label: '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'bill_status',
            label: '請求ステータス'
        },
        {
            name : 'buyup_status' ,
            label :  '乙方請求チェック'
        },
        {
            name : 'person_belong_type' ,
            label :  'Person Belong Type',
            "options":{
                "display":false,
             }
        },
        {
            name : 'admit_status' ,
            label :  'Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_admit_status' ,
            label :  'Bill Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_no',
            label : '請求番号',
            class: "longText",
            options:{
                "display":false,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"160px"}}>
                                    {value}
                        </div>
                    );
                    },
             }
        },
        {
            name: "accounting_id",
            label: "注文書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[17] != "" && tableMeta.rowData[49] != "自社" && 
                       (tableMeta.rowData[32] != "派遣" && tableMeta.rowData[32] != "その他")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createPurchaseOrder(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                    }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[50] == "0" || tableMeta.rowData[50] == 0){
                        if(tableMeta.rowData[29] != "" && tableMeta.rowData[49] != "自社" && tableMeta.rowData[30] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                }}>承認申請
                            </button>);
                        }else{
                            return ('承認前');
                        }
                    }else if(tableMeta.rowData[50] == "1" || tableMeta.rowData[50] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[50] == "2" || tableMeta.rowData[50] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[50] == "3" || tableMeta.rowData[50] == 3){
                        return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[50] == "4" || tableMeta.rowData[50] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(tableMeta.rowData[17] != "" && tableMeta.rowData[11] > 0 && 
                       (tableMeta.rowData[51] == "" || tableMeta.rowData[51] == "0" || tableMeta.rowData[51] == "2")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createBill(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                  
                    // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //     createBill(`${tableMeta.rowData[0]}`);
                    //     }}>作成
                    // </button>);
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role != 5){
                    if(tableMeta.rowData[51] == "" || tableMeta.rowData[51] == "0"){
                        if(tableMeta.rowData[17] != "" && tableMeta.rowData[52] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'create');
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[51] == "1" || tableMeta.rowData[51] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[51] == "2" || tableMeta.rowData[51] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[51] == "3" || tableMeta.rowData[51] == 3){
                        return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}> 申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[51] == "4" || tableMeta.rowData[51] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                  
                    },
                },
        },
        {
            name : 'person_company_id',
            label : '所属会社 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'person_company_partner_type',
            label : '所属会社パートナタープ',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_id',
            label : '取引先 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_partner_type',
            label : '取引先 パートナタープ',
            options:{
                display: false,
            }
        },
        

     ];


    const allColumns = [
            {
                name: "accounting_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            updateAccounting(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                        },
                    },
            
            },
            {
                name :  'accounting_id' ,
                label : '経理ID',
            },
            {
                name : 'work_id',
                label : '稼働ID'
            },
            {
                name: "person-encode",
                label: "Encode",
                "options":{
                    "display":false,
                }
            },
            {
                name : 'person_full_name',
                label : '要員名',
                class: 'longText',
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                            </div>
                            </Link>);
                    },
                },
            },
            {
                name : 'work_month',
                label : '対応月'
            },
                {
                name : 'person_company_company',
                label : '所属会社名',
                class: "longText",
                options:{
                    display:true,
                    setCellProps: () => ({ style: { minWidth: "170px", maxWidth: "200px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/procurement/partners/list/2?id=${tableMeta.rowData[53]}&type=${tableMeta.rowData[54]}`}  target="_blank">
                                <div className='longText' title={value} style={{color:"blue"}}>
                                            {value}
                                </div>
                            </Link>
                        );
                        },
                    }
            },
            {
                name : 'person_company_officer_name',
                label : '所属事務担当者',
                options:{
                    display:true,
                }
            },
            {
                name : 'person_company_officer_mailadress',
                label : '所属事務連絡先メール',
                options:{
                    display:true,
                }
            },
            {
                name : 'person_person_cost',
                label : 'コスト',
                options:{
                    display:true,
                }
            },
            {
                name :  'case_customer_invoice_no' ,
                label : 'インボイス番号',
                options:{
                    display:true,
                }
            },
            {
                name : 'case_customer_company',
                label : '取引先',
                class: 'longText',
                options:{
                display : true,
                setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[55]}&type=${tableMeta.rowData[56]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                               {value}
                       </div></Link>
                     );
                    },
                }
            },
            {
                name : 'case_customer_officer_name',
                label : '取引先事務担当',
                options:{
                    display: true,
                }
            },
            {
                name : 'case_customer_officer_mailadress',
                label : '取引先事務担当メール',
                options:{
                    display: true,
                }
            },
            {
                name : 'bill_from_date',
                label : '当月稼働開始日'
            },
            {
                name : 'bill_to_date',
                label : '当月稼働終了日'
            },
            {
                name : 'work_place',
                label : '稼働場所',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                        },
                    }
            },
            {
                name : 'work_hours',
                label : '稼働時間'
            },
            {
                name : 'worked_days',
                label : '出勤日数',
            },
            {
                name : 'holidaywork_days',
                label : '休日日数',
            },
            {
                name : 'substitute_holiday',
                label : '振替日数',
            },
            {
                name : 'days_absence',
                label : '欠勤日数',
            },
            {
                name : 'work_end_date_plan',
                label : '稼働終了予定日'
            },
            {
                name : 'sale_order_date',
                label : '受注日'
            },
            {
                name :  'seller_contruct',
                label: '発注契約',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'person_belong_type' ,
                label :  'Person Belong Type',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'admit_status' ,
                label :  'Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'buyup_order_no',
                label: '発注番号',
                "options" :{
                    "display":false,
                }
            },
            {
                name : 'bill_admit_status' ,
                label :  'Bill Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'sale_order_amount',
                label : '受注金額'
            },
            {
                name : 'sale_order_end_d',
                label : '受注終了予定日'
            },
            {
                name : 'buyup_order_date',
                label : '発注日'
            },
            {
                name : 'buyup_order_amount',
                label : '発注金額'
            },
            {
                name : 'buyup_order_end_d',
                label : '発注終了予定日'
            },
            {
                name : 'work_settlement',
                label : '精算From'
            },
            {
                name : 'work_settlement_to',
                label : '精算To'
            },
            {
                name : 'work_payment_site',
                label: '支払サイト'
            },
            {
                name : 'work_o_adj_hours_low',
                label : '乙方精算From'
            },
            {
                name : 'work_o_adj_hours_high',
                label : '乙方精算To'
            },
            {
                name : 'work_o_payment_site',
                label : '乙方支払サイト'
            },
            {
                name : 'work-flb_shared_cmt',
                label: '社内共有メモ',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name : 'bill_create_date',
                label : '請求日'
            },
            {
                name : 'bill_no',
                label : '請求番号',
                class: "longText",
                options:{
                    display:true,
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value} style={{width:"160px"}}>
                                        {value}
                            </div>
                        );
                        },
                }
            },
            {
                name : 'rate' ,
                label :  '税率'
            },
            {
                name : 'sale_travel_expense' ,
                label: '請求交通費'
            },
            {
                name : 'bill_amount',
                label: '請求金額'
            },
            {
                name : 'bill_status',
                label: '請求ステータス'
            },
            {
                name : 'bill_memo' ,
                label:  '請求備考',
                class: 'longText',
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name : 'buyup_bill_no' ,
                label : '乙方請求番号'
            },
            {
                name : 'buyup_travel_expense' ,
                label: '乙方交通費'
            },
            {
                name :  'buyup_bill_amount' ,
                label: '乙方請求金額'
            },
            {
                name : 'buyup_status' ,
                label :  '乙方請求チェック'
            },
            {
                name : 'buyup_bill_memo' ,
                label: '乙方請求備考',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                            );
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "注文書作成",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[23] != "" && tableMeta.rowData[25] != "自社" && 
                           (tableMeta.rowData[24] != "派遣" && tableMeta.rowData[24] != "その他")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createPurchaseOrder(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                           }else{
                            return ("")
                           }
                      
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "注文書承認",
                options: {
                    filter: true,
                    display : isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){ 
                        if(tableMeta.rowData[26] == "0" || tableMeta.rowData[26] == 0){
                            if(tableMeta.rowData[29] != "" && tableMeta.rowData[29] != "自社" && tableMeta.rowData[27] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[26] == "1" || tableMeta.rowData[26] == 1){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[26] == "2" || tableMeta.rowData[26] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[26] == "3" || tableMeta.rowData[26] == 3){
                            return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[26] == "4" || tableMeta.rowData[26] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        } 
                    }                     
                        },
                }
            },
            {
                name: "accounting_id",
                label: "請求書作成",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        if(tableMeta.rowData[23] != "" && tableMeta.rowData[17] > 0 && 
                           (tableMeta.rowData[28] == "" || tableMeta.rowData[28] == "0" || tableMeta.rowData[28] == "2")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createBill(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                            // return(<Link to={`/management/download/svg/${tableMeta.rowData[0]}`}>作成</Link>);

                           }else{
                            return ("")
                           }
                      
                        // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        //     createBill(`${tableMeta.rowData[0]}`);
                        //     }}>作成
                        // </button>);
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "請求書承認",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[28] == "" || tableMeta.rowData[28] == "0"){
                            if(tableMeta.rowData[23] != "" && tableMeta.rowData[42] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitBill(`${tableMeta.rowData[0]}`, 'create');
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[28] == "1"){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[28] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[28] == 3){
                            return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}> 申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[28] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }
                    }                     
                        },
                    },
            },
            {
                name : 'person_company_id',
                label : '所属会社 ID',
                options:{
                    display: false,
                }
            },
            {
                name : 'person_company_partner_type',
                label : '所属会社パートナタープ',
                options:{
                    display: false,
                }
            },
            {
                name : 'case_customer_id',
                label : '取引先 ID',
                options:{
                    display: false,
                }
            },
            {
                name : 'case_customer_partner_type',
                label : '取引先 パートナタープ',
                options:{
                    display: false,
                }
            },
         ];
    
    const companyBillInfoColumns = [
        {
            name: "accounting_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        updateAccounting(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
        
        },
        {
            name :  'accounting_id' ,
            label : '経理ID',
        },
        {
            name : 'work_id',
            label : '稼働ID'
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
            }
        },
        {
            name : 'person_full_name',
            label : '要員名',
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                },
            },
        },
        // {
        //     name : 'seller_contruct',
        //     label: '発注契約',
        //     "options":{
        //         "display":false,
        //      }
        // },
        {
            name : 'person_belong_type' ,
            label :  'Person Belong Type',
            "options":{
                "display":false,
             }
        },
        {
            name : 'admit_status' ,
            label :  'Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_admit_status' ,
            label :  'Bill Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'work_month',
            label : '対応月'
        },
        {
            name : 'person_company_company',
            label : '所属会社名',
            class: "longText",
            options:{
                display:true,
                setCellProps: () => ({ style: { minWidth: "170px", maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/procurement/partners/list/2?id=${tableMeta.rowData[68]}&type=${tableMeta.rowData[69]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                        {value}
                            </div>
                        </Link>
                    );
                    },
                }
        },
        {
            name : 'person_company_officer_name',
            label : '所属事務担当者',
            options:{
                display:true,
            }
        },
        {
            name : 'person_company_officer_mailadress',
            label : '所属事務連絡先メール',
            options:{
                display:true,
            }
        },
        {
            name : 'person_person_cost',
            label : 'コスト',
            options:{
                display:true,
            }
        },
        {
            name :  'case_customer_invoice_no' ,
            label : 'インボイス番号',
            options:{
                display:true,
            }
        },
        {
            name : 'case_customer_company',
            label : '取引先',
            class: 'longText',
            options:{
                display : true,
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                      );
                    },
                }
        },
        {
            name : 'case_customer_officer_name',
            label : '取引先事務担当',
            options:{
                display: true,
            }
        },
        {
            name : 'case_customer_officer_mailadress',
            label : '取引先事務担当メール',
            options:{
                display: true,
            }
        },
        {
            name : 'bill_from_date',
            label : '当月稼働開始日'
        },
        {
            name : 'bill_to_date',
            label : '当月稼働終了日'
        },
        {
            name : 'work_place',
            label : '稼働場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                    );
                    },
                }
        },
        {
            name : 'work_hours',
            label : '稼働時間'
        },
        {
            name : 'worked_days',
            label : '出勤日数',
        },
        {
            name : 'holidaywork_days',
            label : '休日日数',
        },
        {
            name : 'substitute_holiday',
            label : '振替日数',
        },
        {
            name : 'days_absence',
            label : '欠勤日数',
        },
        {
            name : 'work_end_date_plan',
            label : '稼働終了予定日'
        },
        {
            name : 'sale_order_date',
            label : '受注日'
        },
        {
            name : 'sale_order_no',
            label : '受注番号'
        },
        {
            name : 'sale_order_nm',
            label : '受注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'buyer_contruct',
            label : '受注契約'
        },
        {
            name : 'work_contract_price_type',
            label : '稼働単金種別'
        },
        {
            name : 'sale_price_hour',
            label : '受注時間単金'
        },
        {
            name : 'sale_price_excess',
            label : '受注超過単金'
        },
        {
            name : 'sale_price_deduct',
            label: '受注控除単金'
        },
        {
            name : 'sale_price_month',
            label: '受注月標準単金'
        },
        {
            name : 'sale_work_rate' ,
            label: '受注稼働率'
        },
        {
            name : 'sale_order_amount',
            label : '受注金額'
        },
        {
            name : 'sale_order_end_d',
            label : '受注終了予定日'
        },
        {
            name : 'buyup_order_date',
            label : '発注日'
        },
        {
            name : 'buyup_order_no',
            label: '発注番号'
        },
        {
            name : 'buyup_order_nm',
            label : '発注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name :  'seller_contruct',
            label: '発注契約'
        },
        {
            name : 'buyup_price_hour',
            label: '発注時間単金'
        },
        {
            name : 'buyup_price_excess',
            label: '発注超過単金'
        },
        {
            name : 'buyup_price_deduct',
            label: '発注控除単金'
        },
        {
            name : 'buyup_price_month',
            label: '発注月標準単金'
        },
        {
            name :'buyup_work_rate' ,
            label:  '発注稼働率'
        },
        {
            name : 'buyup_order_amount',
            label: '発注金額'
        },
        {
            name : 'buyup_order_end_d',
            label: '発注終了予定日'
        },
        {
            name : 'work_settlement',
            label : '精算From'
        },
        {
            name : 'work_settlement_to',
            label : '精算To'
        },
        {
            name : 'work_payment_site',
            label: '支払サイト'
        },
        {
            name : 'work_o_adj_hours_low',
            label : '乙方精算From'
        },
        {
            name : 'work_o_adj_hours_high',
            label : '乙方精算To'
        },
        {
            name : 'work_o_payment_site',
            label : '乙方支払サイト'
        },
        {
            name : 'work-flb_shared_cmt',
            label: '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'bill_create_date',
            label : '請求日'
        },
        {
            name : 'bill_no',
            label : '請求番号',
            class: "longText",
            options:{
                display:true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"160px"}}>
                                    {value}
                        </div>
                    );
                    },
            }
        },
        {
            name : 'rate' ,
            label :  '税率'
        },
        {
            name : 'sale_travel_expense' ,
            label: '請求交通費'
        },
        {
            name : 'bill_amount',
            label: '請求金額'
        },
        {
            name : 'bill_status',
            label: '請求ステータス'
        },
        {
            name : 'bill_memo' ,
            label:  '請求備考',
            class: 'longText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'buyup_bill_no' ,
            label : '乙方請求番号'
        },
        {
            name : 'buyup_travel_expense' ,
            label: '乙方交通費'
        },
        {
            name :  'buyup_bill_amount' ,
            label: '乙方請求金額'
        },
        {
            name : 'buyup_status' ,
            label :  '乙方請求チェック'
        },
        {
            name : 'buyup_bill_memo' ,
            label: '乙方請求備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書作成",
            options: {
                filter: true, 
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[26] != "" && tableMeta.rowData[5] != "自社" && 
                            (tableMeta.rowData[41] != "派遣" && tableMeta.rowData[41] != "その他")){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    createPurchaseOrder(`${tableMeta.rowData[0]}`);
                                    }}>作成
                                </button>);
                            }else{
                                return ("")
                            }
                    
                        }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role!=5){
                    if(tableMeta.rowData[6] == "0" || tableMeta.rowData[6] == 0){
                        if(tableMeta.rowData[38] != "" && tableMeta.rowData[5] != "自社" && tableMeta.rowData[39] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[6] == "1" || tableMeta.rowData[6] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[6] == "2" || tableMeta.rowData[6] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[6] == "3" || tableMeta.rowData[6] == 3){
                        return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[6] == "4" || tableMeta.rowData[6] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                      
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書作成",
            options: {
                filter: true,
                display : isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role!=5){   
                            if(tableMeta.rowData[26] != "" && tableMeta.rowData[20] > 0 && 
                            (tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0" || tableMeta.rowData[7] == "2")){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    createBill(`${tableMeta.rowData[0]}`);
                                    }}>作成
                                </button>);
                                // return(<Link to={`/management/download/svg/${tableMeta.rowData[0]}`}>作成</Link>);

                            }else{
                                return ("")
                            }
                        }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role != 5){
                    if(tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0"){
                        if(tableMeta.rowData[26] != "" && tableMeta.rowData[57] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'create');
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[7] == "1" || tableMeta.rowData[7] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[7] == "2" ||  tableMeta.rowData[7] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[7] == "3" || tableMeta.rowData[7] == 3){
                        return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}> 申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[8] == "4" || tableMeta.rowData[8] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }
                    },
                },
        },
        {
            name : 'person_company_id',
            label : '所属会社 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'person_company_partner_type',
            label : '所属会社パートナタープ',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_id',
            label : '取引先 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_partner_type',
            label : '取引先 パートナタープ',
            options:{
                display: false,
            }
        },
    ]
    const billOrderInfoColumns = [
        {
            name: "accounting_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        updateAccounting(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name :  'accounting_id' ,
            label : '経理ID',
        },
        {
            name : 'work_id',
            label : '稼働ID'
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person_full_name',
            label : '要員名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                    </Link>);
                },
            },
        },
        {
            name :  'seller_contruct',
            label: '発注契約',
            "options":{
                "display":false,
             }
        },
        {
            name : 'person_belong_type' ,
            label :  'Person Belong Type',
            "options":{
                "display":false,
             }
        },
        {
            name : 'admit_status' ,
            label :  'Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_admit_status' ,
            label :  'Bill Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'buyup_order_no',
            label: '発注番号',
            "options":{
                "display":false,
             }
        },
        {
            name : 'work_month',
            label : '対応月'
        },
        {
            name : 'bill_from_date',
            label : '当月稼働開始日'
        },
        {
            name : 'bill_to_date',
            label : '当月稼働終了日'
        },
        {
            name : 'work_place',
            label : '稼働場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                    );
                    },
                }
        },
        {
            name : 'work_hours',
            label : '稼働時間'
        },
        {
            name : 'worked_days',
            label : '出勤日数',
        },
        {
            name : 'holidaywork_days',
            label : '休日日数',
        },
        {
            name : 'substitute_holiday',
            label : '振替日数',
        },
        {
            name : 'days_absence',
            label : '欠勤日数',
        },
        {
            name : 'work_end_date_plan',
            label : '稼働終了予定日'
        },
        {
            name : 'sale_order_date',
            label : '受注日'
        },
        {
            name : 'sale_order_amount',
            label : '受注金額'
        },
        {
            name : 'sale_order_end_d',
            label : '受注終了予定日'
        },
        {
            name : 'buyup_order_date',
            label : '発注日'
        },
        {
            name : 'buyup_order_amount',
            label: '発注金額'
        },
        {
            name : 'buyup_order_end_d',
            label: '発注終了予定日'
        },
        {
            name : 'work_settlement',
            label: '精算From'
        },
        {
            name : 'work_settlement_to',
            label: '精算To'
        },
        {
            name : 'work_payment_site',
            label: '支払サイト'
        },
        {
            name : 'work_o_adj_hours_low',
            label : '乙方精算From'
        },
        {
            name : 'work_o_adj_hours_high',
            label : '乙方精算To'
        },
        {
            name : 'work_o_payment_site',
            label : '乙方支払サイト'
        },
        {
            name : 'work-flb_shared_cmt',
            label: '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'bill_create_date',
            label : '請求日'
        },
        {
            name : 'bill_no',
            label : '請求番号',
            class: "longText",
            options:{
                display:true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"160px"}}>
                                    {value}
                        </div>
                    );
                    },
            }
        },
        {
            name : 'rate' ,
            label :  '税率'
        },
        {
            name : 'sale_travel_expense' ,
            label: '請求交通費'
        },
        {
            name : 'bill_amount',
            label: '請求金額'
        },
        {
            name : 'bill_status',
            label: '請求ステータス'
        },
        {
            name : 'bill_memo' ,
            label:  '請求備考',
            class: 'longText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'buyup_bill_no' ,
            label : '乙方請求番号'
        },
        {
            name : 'buyup_travel_expense' ,
            label: '乙方交通費'
        },
        {
            name :  'buyup_bill_amount' ,
            label: '乙方請求金額'
        },
        {
            name : 'buyup_status' ,
            label :  '乙方請求チェック'
        },
        {
            name : 'buyup_bill_memo' ,
            label: '乙方請求備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[20] != "" && tableMeta.rowData[6] != "自社" && 
                       (tableMeta.rowData[9] != "派遣" && tableMeta.rowData[9] != "その他")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createPurchaseOrder(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                  
                    }
                } 
                },
        },
        {
            name: "accounting_id",
            label: "注文書承認",
            options: {
                filter: true,
                display : isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[11] == "0" || tableMeta.rowData[7] == 0){
                        if(tableMeta.rowData[19] != "" && tableMeta.rowData[5] != "自社" && tableMeta.rowData[13] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[7] == "1" || tableMeta.rowData[7] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[7] == "2" || tableMeta.rowData[7] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[7] == "3" || tableMeta.rowData[7] == 3){
                        return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[7] == "4" || tableMeta.rowData[7] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user!=5){
                        if(tableMeta.rowData[20] != "" && tableMeta.rowData[14] > 0 && 
                        (tableMeta.rowData[8] == "" || tableMeta.rowData[8] == "0" || tableMeta.rowData[8] == "2")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createBill(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                            // return(<Link to={`/management/download/svg/${tableMeta.rowData[0]}`}>作成</Link>);

                        }else{
                            return ("")
                        }
                    }
                    // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //     createBill(`${tableMeta.rowData[0]}`);
                    //     }}>作成
                    // </button>);
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書承認",
            options: {
                filter: true, 
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[8] == "" || tableMeta.rowData[8] == "0"){
                        if(tableMeta.rowData[16] != "" && tableMeta.rowData[30] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'create');
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[8] == "1"){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[8] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[8] == 3){
                        return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}> 申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[8] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                       
                    },
                },
        }

     ];


    const companyOrderInfoColumns = [
        {
            name: "accounting_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        updateAccounting(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name :  'accounting_id' ,
            label : '経理ID',
        },
        {
            name : 'work_id',
            label : '稼働ID'
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person_full_name',
            label : '要員名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                        </div>
                        </Link>);
                },
            },
        },
        {
            name : 'person_belong_type' ,
            label :  'Person Belong Type',
            "options":{
                "display":false,
             }
        },
        {
            name :  'seller_contruct',
            label: '発注契約',
            "options":{
                "display":false,
             }
        },
        {
            name : 'admit_status' ,
            label :  'Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'buyup_order_no',
            label: '発注番号',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_admit_status' ,
            label :  'Bill Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_no',
            label : '請求番号',
            class: "longText",
            options:{
                display:false,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"160px"}}>
                                    {value}
                        </div>
                    );
                    },
            }
        },
        {
            name : 'work_month',
            label : '対応月'
        },
        {
            name : 'person_company_company',
            label : '所属会社名',
            class: "longText",
            options:{
                display:true,
                setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/procurement/partners/list/2?id=${tableMeta.rowData[44]}&type=${tableMeta.rowData[45]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                    </Link>);
                    },
                }
        },
        {
            name : 'person_company_officer_name',
            label : '所属事務担当者',
            options:{
                display:true,
            }
        },
        {
            name : 'person_company_officer_mailadress',
            label : '所属事務連絡先メール',
            options:{
                display:true,
            }
        },
        {
            name : 'person_person_cost',
            label : 'コスト',
            options:{
                display:true,
            }
        },
        {
            name :  'case_customer_invoice_no' ,
            label : 'インボイス番号',
            options:{
                display:true,
            }
        },
        {
            name : 'case_customer_company',
            label : '取引先',
            class: 'longText',
            options:{
            display : true,
            setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
            customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[46]}&type=${tableMeta.rowData[47]}`}  target="_blank">
                     <div className='longText' title={value} style={{color:"blue"}}>
                            {value}
                    </div></Link>
                  );
                },
            }
        },
        {
            name : 'case_customer_officer_name',
            label : '取引先事務担当',
            options:{
                display: true,
            }
        },
        {
            name : 'case_customer_officer_mailadress',
            label : '取引先事務担当メール',
            options:{
                display: true,
            }
        },
        {
            name : 'bill_from_date',
            label : '当月稼働開始日'
        },
        {
            name : 'bill_to_date',
            label : '当月稼働終了日'
        },
        {
            name : 'work_place',
            label : '稼働場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                    );
                    },
                }
        },
        {
            name : 'work_hours',
            label : '稼働時間'
        },
        {
            name : 'worked_days',
            label : '出勤日数',
        },
        {
            name : 'holidaywork_days',
            label : '休日日数',
        },
        {
            name : 'substitute_holiday',
            label : '振替日数',
        },
        {
            name : 'days_absence',
            label : '欠勤日数',
        },
        {
            name : 'work_end_date_plan',
            label : '稼働終了予定日'
        },
        {
            name : 'sale_order_date',
            label : '受注日'
        },
        {
            name : 'sale_order_amount',
            label : '受注金額'
        },
        {
            name : 'sale_order_end_d',
            label : '受注終了予定日'
        },
        {
            name : 'buyup_order_date',
            label : '発注日'
        },
        {
            name : 'buyup_order_amount',
            label: '発注金額'
        },
        {
            name : 'buyup_order_end_d',
            label: '発注終了予定日'
        },
        {
            name : 'work_settlement',
            label: '精算From'
        },
        {
            name : 'work_settlement_to',
            label: '精算To'
        },
        {
            name : 'work_payment_site',
            label: '支払サイト'
        },
        {
            name : 'work_o_adj_hours_low',
            label : '乙方精算From'
        },
        {
            name : 'work_o_adj_hours_high',
            label : '乙方精算To'
        },
        {
            name : 'work_o_payment_site',
            label : '乙方支払サイト'
        },
        {
            name : 'work-flb_shared_cmt',
            label: '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'bill_status',
            label: '請求ステータス'
        },
        {
            name : 'buyup_status' ,
            label :  '乙方請求チェック'
        },
        {
            name: "accounting_id",
            label: "注文書作成",
            options: {
                filter: true,
                display:isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role!=5){
                    if(tableMeta.rowData[29] != "" && tableMeta.rowData[5] != "自社" && 
                       (tableMeta.rowData[6] != "派遣" && tableMeta.rowData[6] != "その他")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createPurchaseOrder(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[7] == "0" || tableMeta.rowData[7] == 0){
                        if(tableMeta.rowData[32] != "" && tableMeta.rowData[6] != "自社" && tableMeta.rowData[8] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[7] == "1" || tableMeta.rowData[7] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[7] == "2" || tableMeta.rowData[7] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[7] == "3" || tableMeta.rowData[7] == 3){
                        return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[7] == "4" || tableMeta.rowData[7] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                      
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[29] != "" && tableMeta.rowData[23] > 0 && 
                       (tableMeta.rowData[9] == "" || tableMeta.rowData[9] == "0" || tableMeta.rowData[9] == "2")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createBill(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                }
                    // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //     createBill(`${tableMeta.rowData[0]}`);
                    //     }}>作成
                    // </button>);
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role != 5){
                    if(tableMeta.rowData[9] == "" || tableMeta.rowData[9] == "0"){
                        if(tableMeta.rowData[29] != "" && tableMeta.rowData[10] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'create');
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[9] == "1"){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[9] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[9] == 3){
                        return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}> 申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[9] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                      
                    },
                },
        },
        {
            name : 'person_company_id',
            label : '所属会社 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'person_company_partner_type',
            label : '所属会社パートナタープ',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_id',
            label : '取引先 ID',
            options:{
                display: false,
            }
        },
        {
            name : 'case_customer_partner_type',
            label : '取引先 パートナタープ',
            options:{
                display: false,
            }
        },
    ];
    const orderInfoColumns = [
            {
                name: "accounting_id",
                label: "編集",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            updateAccounting(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                        },
                    },
            
            },
            {
                name :  'accounting_id' ,
                label : '経理ID',
            },
            {
                name : 'work_id',
                label : '稼働ID'
            },
            {
                name: "person-encode",
                label: "Encode",
                "options":{
                    "display":false,
                }
            },
            {
                name : 'person_full_name',
                label : '要員名',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                            </div>
                        </Link>);
                    },
                },
            },
            {
                name : 'person_belong_type' ,
                label :  'Person Belong Type',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'admit_status' ,
                label :  'Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'bill_admit_status' ,
                label :  'Bill Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name :  'seller_contruct',
                label: '発注契約',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'bill_no',
                label : '請求番号',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'buyup_order_no',
                label: '発注番号',
                "options":{
                    "display":false,
                 }
            },

            {
                name : 'work_month',
                label : '対応月'
            },
            {
                name : 'bill_from_date',
                label : '当月稼働開始日'
            },
            {
                name : 'bill_to_date',
                label : '当月稼働終了日'
            },
            {
                name : 'work_place',
                label : '稼働場所',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                        },
                    }
            },
            {
                name : 'work_hours',
                label : '稼働時間'
            },
            {
                name : 'worked_days',
                label : '出勤日数',
            },
            {
                name : 'holidaywork_days',
                label : '休日日数',
            },
            {
                name : 'substitute_holiday',
                label : '振替日数',
            },
            {
                name : 'days_absence',
                label : '欠勤日数',
            },
            {
                name : 'work_end_date_plan',
                label : '稼働終了予定日'
            },
            {
                name : 'sale_order_date',
                label : '受注日'
            },
            {
                name : 'sale_order_amount',
                label : '受注金額'
            },
            {
                name : 'sale_order_end_d',
                label : '受注終了予定日'
            },
            {
                name : 'buyup_order_date',
                label : '発注日'
            },
            {
                name : 'buyup_order_amount',
                label: '発注金額'
            },
            {
                name : 'buyup_order_end_d',
                label: '発注終了予定日'
            },
            {
                name : 'work_settlement',
                label: '精算From'
            },
            {
                name : 'work_settlement_to',
                label: '精算To'
            },
            {
                name : 'work_payment_site',
                label: '支払サイト'
            },
            {
                name : 'work_o_adj_hours_low',
                label : '乙方精算From'
            },
            {
                name : 'work_o_adj_hours_high',
                label : '乙方精算To'
            },
            {
                name : 'work_o_payment_site',
                label : '乙方支払サイト'
            },
            {
                name : 'work-flb_shared_cmt',
                label: '社内共有メモ',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name : 'bill_status',
                label: '請求ステータス'
            },
            {
                name : 'buyup_status' ,
                label :  '乙方請求チェック'
            },
            {
                name: "accounting_id",
                label: "注文書作成",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[21] != "" && tableMeta.rowData[5] != "自社" && 
                           (tableMeta.rowData[8] != "派遣" && tableMeta.rowData[8] != "その他")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createPurchaseOrder(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                           }else{
                            return ("")
                           }
                    }
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "注文書承認",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[6] == "0" || tableMeta.rowData[6] == 0){
                            if(tableMeta.rowData[24] != "" && tableMeta.rowData[5] != "自社" && tableMeta.rowData[10] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[6] == "1" || tableMeta.rowData[6] == 1){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[6] == "2" || tableMeta.rowData[6] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[6] == "3" || tableMeta.rowData[6] == 3){
                            return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[6] == "4" || tableMeta.rowData[6] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }
                    }                     
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "請求書作成",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role!=5){
                        if(tableMeta.rowData[21] != "" && tableMeta.rowData[15] > 0 && 
                           (tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0" || tableMeta.rowData[7] == "2")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createBill(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                           }else{
                            return ("")
                           }
                        }
                        // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        //     createBill(`${tableMeta.rowData[0]}`);
                        //     }}>作成
                        // </button>);
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "請求書承認",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0"){
                            if(tableMeta.rowData[21] != "" && tableMeta.rowData[9] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitBill(`${tableMeta.rowData[0]}`, 'create');
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[7] == "1" || tableMeta.rowData[7] == 1){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[7] == "2" || tableMeta.rowData[7] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[7] == "3" || tableMeta.rowData[7] == 3){
                            return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}> 申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[7] == "4" || tableMeta.rowData[7] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }
                    }                  
                        },
                    },
            }
         ];
    const billInfoColumn = [
        {
            name: "accounting_id",
            label: "編集",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        updateAccounting(`${tableMeta.rowData[0]}`);
                        }}>編集
                    </button>);
                    },
                },
           
        },
        {
            name :  'accounting_id' ,
            label : '経理ID',
        },
        {
            name : 'work_id',
            label : '稼働ID'
        },
        {
            name: "person-encode",
            label: "Encode",
            "options":{
                "display":false,
             }
        },
        {
            name : 'person_full_name',
            label : '要員名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                        <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                        </div>
                        </Link>);
                },
            },
        },
        {
            name : 'person_belong_type' ,
            label :  'Person Belong Type',
            "options":{
                "display":false,
             }
        },
        {
            name : 'admit_status' ,
            label :  'Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'bill_admit_status' ,
            label :  'Bill Admit Status',
            "options":{
                "display":false,
             }
        },
        {
            name : 'work_month',
            label : '対応月'
        },
        {
            name : 'bill_from_date',
            label : '当月稼働開始日'
        },
        {
            name : 'bill_to_date',
            label : '当月稼働終了日'
        },
        {
            name : 'work_place',
            label : '稼働場所',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                         <div className='longText' title={value}>
                                {value}
                        </div>
                    );
                    },
                }
        },
        {
            name : 'work_hours',
            label : '稼働時間'
        },
        {
            name : 'worked_days',
            label : '出勤日数',
        },
        {
            name : 'holidaywork_days',
            label : '休日日数',
        },
        {
            name : 'substitute_holiday',
            label : '振替日数',
        },
        {
            name : 'days_absence',
            label : '欠勤日数',
        },
        {
            name : 'work_end_date_plan',
            label : '稼働終了予定日'
        },
        {
            name : 'sale_order_date',
            label : '受注日'
        },
        {
            name : 'sale_order_no',
            label : '受注番号'
        },
        {
            name : 'sale_order_nm',
            label : '受注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "300px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'buyer_contruct',
            label : '受注契約'
        },
        {
            name : 'work_contract_price_type',
            label : '稼働単金種別'
        },
        {
            name : 'sale_price_hour',
            label : '受注時間単金'
        },
        {
            name : 'sale_price_excess',
            label : '受注超過単金'
        },
        {
            name : 'sale_price_deduct',
            label: '受注控除単金'
        },
        {
            name : 'sale_price_month',
            label: '受注月標準単金'
        },
        {
            name : 'sale_work_rate' ,
            label: '受注稼働率'
        },
        {
            name : 'sale_order_amount',
            label : '受注金額'
        },
        {
            name : 'sale_order_end_d',
            label : '受注終了予定日'
        },
        {
            name : 'buyup_order_date',
            label : '発注日'
        },
        {
            name : 'buyup_order_no',
            label: '発注番号'
        },
        {
            name : 'buyup_order_nm',
            label : '発注案件名',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "150px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                    );
                    },
                },
        },
        {
            name :  'seller_contruct',
            label: '発注契約'
        },
        {
            name : 'buyup_price_hour',
            label: '発注時間単金'
        },
        {
            name : 'buyup_price_excess',
            label: '発注超過単金'
        },
        {
            name : 'buyup_price_deduct',
            label: '発注控除単金'
        },
        {
            name : 'buyup_price_month',
            label: '発注月標準単金'
        },
        {
            name :'buyup_work_rate' ,
            label:  '発注稼働率'
        },
        {
            name : 'buyup_order_amount',
            label: '発注金額'
        },
        {
            name : 'buyup_order_end_d',
            label: '発注終了予定日'
        },
        {
            name : 'work_settlement',
            label: '精算From'
        },
        {
            name : 'work_settlement_to',
            label: '精算To'
        },
        {
            name : 'work_payment_site',
            label: '支払サイト'
        },
        {
            name : 'work_o_adj_hours_low',
            label : '乙方精算From'
        },
        {
            name : 'work_o_adj_hours_high',
            label : '乙方精算To'
        },
        {
            name : 'work_o_payment_site',
            label : '乙方支払サイト'
        },
        {
            name : 'work-flb_shared_cmt',
            label: '社内共有メモ',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name : 'bill_create_date',
            label : '請求日'
        },
        {
            name : 'bill_no',
            label : '請求番号'
        },
        {
            name : 'rate' ,
            label :  '税率'
        },
        {
            name : 'sale_travel_expense' ,
            label: '請求交通費'
        },
        {
            name : 'bill_amount',
            label: '請求金額'
        },
        {
            name : 'bill_status',
            label: '請求ステータス'
        },
        {
            name : 'bill_memo' ,
            label:  '請求備考',
            class: 'longText',
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name : 'buyup_bill_no' ,
            label : '乙方請求番号'
        },
        {
            name : 'buyup_travel_expense' ,
            label: '乙方交通費'
        },
        {
            name :  'buyup_bill_amount' ,
            label: '乙方請求金額'
        },
        {
            name : 'buyup_status' ,
            label :  '乙方請求チェック'
        },
        {
            name : 'buyup_bill_memo' ,
            label: '乙方請求備考',
            class: "longText",
            options: {
                filter: true, 
                setCellProps: () => ({ style: { maxWidth: "100px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                                    {value}
                        </div>
                        );
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[18] != "" && tableMeta.rowData[5] != "自社" && 
                       (tableMeta.rowData[33] != "派遣" && tableMeta.rowData[33] != "その他")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createPurchaseOrder(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                    }
                    },
                },
        },
        {
            name: "accounting_id",
            label: "注文書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){ 
                    if(tableMeta.rowData[6] == "0" || tableMeta.rowData[6] == 0){
                        if(tableMeta.rowData[30] != "" && tableMeta.rowData[29] != "自社" && tableMeta.rowData[31] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[6] == "1" || tableMeta.rowData[6] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[6] == "2" || tableMeta.rowData[6] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[6] == "3" || tableMeta.rowData[6] == 3){
                        return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[6] == "4" || tableMeta.rowData[6] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                       
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書作成",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[18] != "" && tableMeta.rowData[12] > 0 && 
                       (tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0" || tableMeta.rowData[7] == "2")){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            createBill(`${tableMeta.rowData[0]}`);
                            }}>作成
                        </button>);
                       }else{
                        return ("")
                       }
                }
                    // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                    //     createBill(`${tableMeta.rowData[0]}`);
                    //     }}>作成
                    // </button>);
                    },
                },
        },
        {
            name: "accounting_id",
            label: "請求書承認",
            options: {
                filter: true,
                display: isDisplay(),
                setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                if(login_user.role !=5){
                    if(tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0"){
                        if(tableMeta.rowData[18] != "" && tableMeta.rowData[49] != ""){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'create');
                                }}>承認申請
                            </button>);
                        }else{
                            return (<span>承認前</span>);
                        }
                    }else if(tableMeta.rowData[7] == "1" || tableMeta.rowData[7] == 1){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button>);
                    }else if(tableMeta.rowData[7] == "2" || tableMeta.rowData[7] == 2){
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                            }}>再申請
                        </button>);
                    }else if(tableMeta.rowData[7] == "3" || tableMeta.rowData[7] == 3){
                        return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}> 申請取消
                        </button></div>);
                    }else if(tableMeta.rowData[7] == "4" || tableMeta.rowData[7] == 4){
                        return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                            }}>申請取消
                        </button></div>);
                    }
                }                      
                    },
                },
        }
     ];
    const companyInfoColumn = [
            {
                name: "accounting_id",
                label: "編集",
                options: {
                    filter: true, 
                    // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                            updateAccounting(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                        },
                    },
            
            },
            {
                name :  'accounting_id' ,
                label : '経理ID',
            },
            {
                name : 'work_id',
                label : '稼働ID'
            },
            {
                name: "person-encode",
                label: "Encode",
                "options":{
                    "display":false,
                }
            },
            {
                name : 'person_full_name',
                label : '要員名',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/persons/${tableMeta.rowData[3]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                            </div>
                            </Link>);
                    },
                },
            },
            {
                name : 'person_belong_type' ,
                label :  'Person Belong Type',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'admit_status' ,
                label :  'Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'bill_admit_status' ,
                label :  'Bill Admit Status',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'bill_no',
                label : '請求番号',
                "options":{
                    "display":false,
                 }
            },
            {
                name : 'work_month',
                label : '対応月'
            },
            {
                name : 'person_company_company',
                label : '所属会社名',
                class: "longText",
                options:{
                    display:true,
                    setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(<Link to={`/procurement/partners/list/2?id=${tableMeta.rowData[59]}&type=${tableMeta.rowData[60]}`}  target="_blank">
                            <div className='longText' title={value} style={{color:"blue"}}>
                                        {value}
                            </div>
                        </Link>);
                        },
                    }
            },
            {
                name : 'person_company_officer_name',
                label : '所属事務担当者',
                options:{
                    display:true,
                }
            },
            {
                name : 'person_company_officer_mailadress',
                label : '所属事務連絡先メール',
                options:{
                    display:true,
                }
            },
            {
                name : 'person_person_cost',
                label : 'コスト',
                options:{
                    display:true,
                }
            },
            {
                name :  'case_customer_invoice_no' ,
                label : 'インボイス番号',
                options:{
                    display:true,
                }
            },
            {
                name : 'case_customer_company',
                label : '取引先',
                class: 'longText',
                options:{
                display : true,
                setCellProps: () => ({ style: { minWidth: "170px" ,maxWidth: "200px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(<Link to={`/sale/partners/list/1?id=${tableMeta.rowData[61]}&type=${tableMeta.rowData[62]}`}  target="_blank">
                         <div className='longText' title={value} style={{color:"blue"}}>
                                {value}
                        </div></Link>
                      );
                    },
                }
            },
            {
                name : 'case_customer_officer_name',
                label : '取引先事務担当',
                options:{
                    display: true,
                }
            },
            {
                name : 'case_customer_officer_mailadress',
                label : '取引先事務担当メール',
                options:{
                    display: true,
                }
            },
            {
                name : 'bill_from_date',
                label : '当月稼働開始日'
            },
            {
                name : 'bill_to_date',
                label : '当月稼働終了日'
            },
            {
                name : 'work_place',
                label : '稼働場所',
                class: "longText",
                options: {
                    filter: true, 
                    // setCellProps: () => ({ style: { maxWidth: "100px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                    {value}
                            </div>
                        );
                        },
                    }
            },
            {
                name : 'work_hours',
                label : '稼働時間'
            },
            {
                name : 'worked_days',
                label : '出勤日数',
            },
            {
                name : 'holidaywork_days',
                label : '休日日数',
            },
            {
                name : 'substitute_holiday',
                label : '振替日数',
            },
            {
                name : 'days_absence',
                label : '欠勤日数',
            },
            { 
                name : 'work_end_date_plan',
                label : '稼働終了予定日'
            },
            {
                name : 'sale_order_date',
                label : '受注日'
            },
            {
                name : 'sale_order_no',
                label : '受注番号'
            },
            {
                name : 'sale_order_nm',
                label : '受注案件名',
                class: "longText",
                options: {
                    filter: true, 
                    // setCellProps: () => ({ style: { maxWidth: "300px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name : 'buyer_contruct',
                label : '受注契約'
            },
            {
                name : 'work_contract_price_type',
                label : '稼働単金種別'
            },
            {
                name : 'sale_price_hour',
                label : '受注時間単金'
            },
            {
                name : 'sale_price_excess',
                label : '受注超過単金'
            },
            {
                name : 'sale_price_deduct',
                label: '受注控除単金'
            },
            {
                name : 'sale_price_month',
                label: '受注月標準単金'
            },
            {
                name : 'sale_work_rate' ,
                label: '受注稼働率'
            },
            {
                name : 'sale_order_amount',
                label : '受注金額'
            },
            {
                name : 'sale_order_end_d',
                label : '受注終了予定日'
            },
            {
                name : 'buyup_order_date',
                label : '発注日'
            },
            {
                name : 'buyup_order_no',
                label: '発注番号'
            },
            {
                name : 'buyup_order_nm',
                label : '発注案件名',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "150px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                        {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name :  'seller_contruct',
                label: '発注契約'
            },
            {
                name : 'buyup_price_hour',
                label: '発注時間単金'
            },
            {
                name : 'buyup_price_excess',
                label: '発注超過単金'
            },
            {
                name : 'buyup_price_deduct',
                label: '発注控除単金'
            },
            {
                name : 'buyup_price_month',
                label: '発注月標準単金'
            },
            {
                name :'buyup_work_rate' ,
                label:  '発注稼働率'
            },
            {
                name : 'buyup_order_amount',
                label: '発注金額'
            },
            {
                name : 'buyup_order_end_d',
                label: '発注終了予定日'
            },
            {
                name : 'work_settlement',
                label: '精算From'
            },
            {
                name : 'work_settlement_to',
                label: '精算To'
            },
            {
                name : 'work_payment_site',
                label: '支払サイト'
            },
            {
                name : 'work_o_adj_hours_low',
                label : '乙方精算From'
            },
            {
                name : 'work_o_adj_hours_high',
                label : '乙方精算To'
            },
            {
                name : 'work_o_payment_site',
                label : '乙方支払サイト'
            },
            {
                name : 'work-flb_shared_cmt',
                label: '社内共有メモ',
                class: "longText",
                options: {
                    filter: true, 
                    setCellProps: () => ({ style: { maxWidth: "100px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                            <div className='longText' title={value}>
                                {value}
                            </div>
                        );
                        },
                    },
            },
            {
                name : 'bill_status',
                label: '請求ステータス'
            },
            {
                name : 'buyup_status' ,
                label :  '乙方請求チェック'
            },
            {
                name: "accounting_id",
                label: "注文書作成",
                options: {
                    filter: true,
                    display:isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[27] != "" && tableMeta.rowData[5] != "自社" && 
                           (tableMeta.rowData[42] != "派遣" && tableMeta.rowData[42] != "その他")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createPurchaseOrder(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                           }else{
                            return ("")
                           }
                    } 
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "注文書承認",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[6] == "0" || tableMeta.rowData[6] == 0){
                            if(tableMeta.rowData[39] != "" && tableMeta.rowData[38] != "自社" && tableMeta.rowData[40] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitPurchaseOrder(`${tableMeta.rowData[0]}`, "create");
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[6] == "1" || tableMeta.rowData[6] == 1){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, "cancel");
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[6] == "2" || tableMeta.rowData[6] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[6] == "3" || tableMeta.rowData[6] == 3){
                            return(<div><span>申請済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[6] == "4" || tableMeta.rowData[6] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitPurchaseOrder(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }
                    }                     
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "請求書作成",
                options: {
                    filter: true, 
                    display:isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[27] != "" && tableMeta.rowData[21] > 0 && 
                           (tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0" || tableMeta.rowData[7] == "2")){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                createBill(`${tableMeta.rowData[0]}`);
                                }}>作成
                            </button>);
                           }else{
                            return ("")
                           }
                        }
                        // return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                        //     createBill(`${tableMeta.rowData[0]}`);
                        //     }}>作成
                        // </button>);
                        },
                    },
            },
            {
                name: "accounting_id",
                label: "請求書承認",
                options: {
                    filter: true,
                    display: isDisplay(),
                    setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    if(login_user.role !=5){
                        if(tableMeta.rowData[7] == "" || tableMeta.rowData[7] == "0"){
                            if(tableMeta.rowData[27] != "" && tableMeta.rowData[12] != ""){
                                return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                    admitBill(`${tableMeta.rowData[0]}`, 'create');
                                    }}>承認申請
                                </button>);
                            }else{
                                return (<span>承認前</span>);
                            }
                        }else if(tableMeta.rowData[7] == "1"){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button>);
                        }else if(tableMeta.rowData[7] == 2){
                            return(<button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'recreate');
                                }}>再申請
                            </button>);
                        }else if(tableMeta.rowData[7] == 3){
                            return(<div><span>承認済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}> 申請取消
                            </button></div>);
                        }else if(tableMeta.rowData[7] == 4){
                            return(<div><span>送付済</span><button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                admitBill(`${tableMeta.rowData[0]}`, 'cancel');
                                }}>申請取消
                            </button></div>);
                        }
                    }                     
                        },
                    },
            },
            {
                name : 'person_company_id',
                label : '所属会社 ID',
                options:{
                    display: false,
                }
            },
            {
                name : 'person_company_partner_type',
                label : '所属会社パートナタープ',
                options:{
                    display: false,
                }
            },
            {
                name : 'case_customer_id',
                label : '取引先 ID',
                options:{
                    display: false,
                }
            },
            {
                name : 'case_customer_partner_type',
                label : '取引先 パートナタープ',
                options:{
                    display: false,
                }
            },
         ];
 

   
  




         const getMuiTheme = () =>
    
    
         createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                      backgroundColor: "rgb(193,193,193)",
                      padding: '0px'
                  }
                }
        },
        // MuiTableCell: {
        //     head: {
        //         backgroundColor: "red !important"
        //     }
        // }

        MuiTableCell: {
            styleOverrides:{
                head: {
                        color : 'white',
                        backgroundColor: "red !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                  backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          }
        }
      })

    let [state , setState] = useState<IState>({
        procedures  : [],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        totalRows : 0,
        orderStatusList : [],
        purchaseStatusList : [],
        filterBillStatusList : [],
        supplier_list : [],
        affiliation_company_list : [],
        procedure_model : {
            accounting_id: "",
            work_id : "",
            case_id : "",
            bill_from_date :"",
            bill_to_date : "",
            work_place : "",
            work_hours : "",
            work_end_date_plan : "",
            sale_order_date : "",
            sale_order_no : "",
            sale_order_nm : "",
            buyer_contruct : "",
            sale_price_hour : 0,
            sale_price_excess : "",
            sale_price_deduct : "",
            sale_price_month : "",
            sale_work_rate : "",
            sale_order_amount : "",
            sale_order_end_d : "",
            buyup_order_date : "",
            buyup_order_no : "",
            buyup_order_nm : "",
            seller_contruct : "",
            buyup_price_hour : "",
            buyup_price_excess : "",
            buyup_price_deduct : "",
            buyup_price_month : "",
            buyup_work_rate : "",
            buyup_order_amount : "",
            buyup_order_end_d : "",
            work_payment_site: "",
            bill_create_date : "",
            bill_no : "",
            rate : "",
            sale_travel_expense : "",
            bill_amount : "",
            bill_status : "",
            bill_memo : "",
            buyup_bill_no : "",
            buyup_travel_expense : "",
            buyup_bill_amount : "",
            buyup_status : "",
            buyup_bill_memo : ""
        },
        disable_procedure_model : {
            person_full_name : "",
            work_month : "",
            person_company_company : "",
            person_company_officer_name : "",
            person_company_officer_mailadress :"",
            case_customer_invoice_no : "",
            person_person_cost : "",
            case_customer_company : "",
            case_customer_officer_name : "",
            case_customer_officer_mailadress : "",
            work_contract_price_type : "",
            work_settlement : "",
            work_settlement_to : "",
            work_payment_site: "",
            work_o_adj_hours_low : "",
            work_o_adj_hours_high : "",
            work_o_payment_site : "",
            work_flb_shared_cmt : ""
        },
        loading :false,
    });

    const [target_months, setTargetMonths] = useState("");
    const [order_status, setOrderStatus] = useState("");  
    const [purchase_status, setPurchaseStatus] = useState("1");
    const [filter_bill_status, setFilterBillStatus] = useState("1");
    const [share_memo_filter, setShareMemoFilter] = useState("");
    const [belongs_filter, setBelongsFilter] = useState("");
    const [supplier_filter, setSupplierFilter] = useState("");
    const [affiliation_company_filter, setAffiliationCompanyFilter] = useState("");
    const [proce_count , setProceCount] = useState("");
    const [search, setSearch] = useState("");
    const result : string[] = [];
    const [companyInfoChecked, setCompanyInfoChecked] = useState<boolean>(false);
    const [billInfoChecked, setBillInfoChecked] = useState<boolean>(false);
    const [orderInfoChecked, setOrderInfoChecked] = useState<boolean>(false);
    const defaultCol : any[] | null = [];
    const [columnVisible, setColumnVisible] = React.useState(defaultCol);
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
  
    const [checked, isChecked] = useState(false);
    const [selectedTargetMonth, setSelectedTargetMonth] = useState(null);
    const [order_proce, setOrderProce] = useState([]); 
    const [purchase_proce, setPurchaseProce] = useState([]); 
    const [bill_proce, setBillProce] = useState([]); 
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    const [selectedBillFromDate, setSelectedBillFromDate] = useState(null);
    const [selectedBillToDate, setSelectedBillToDate] = useState(null);
    const [selectedSaleOrderDate, setSelectedSaleOrderDate] = useState(null);
    const [selectedSaleOrderEndDate, setSelectedSaleOrderEndDate] = useState(null);
    const [selectedBillCreateDate, setSelectedBillCreateDate] = useState(null);
    const [selectedWorkEndDatePlan, setSelectedWorkEndDatePlan] = useState(null);
    const [selectedBuyUpOrderDate, setSelectedBuyUpOrderDate] = useState(null);
    const [selectedBuyupOrderEndDate, setSelectedBuyupOrderEndDate] = useState(null);
    
    const [expect_contract_type, setExpectContractType] = useState([]);
    const [payment_site_list, setPaymentSite] = useState([]);
    const [o_payment_site_list, setOPaymentSite] = useState([]);
    const [bill_status_list, setBillStatus] = useState([]);
    const [buyup_status_list, setBuyupStatus] = useState([]);
    var now = new Date();
    const [monthflg, setMonthflg] = useState((now.getMonth() + 1).toString());
    const [yearflg, setYearFlg] = useState("1");
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(734);
    
    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Users Gained ",
            data: Data.map((data) => data.userGain)
          }
        ]
      });

  
    const getData = async (target_months:any,order_status:any,purchase_status:any,filter_bill_status:any,share_memo_filter:any,belongs_filter:any, supplier_filter:any, 
        affiliation_company_filter:any, perPage:number, page:number, search_keywords:any, sort:any, column:string, order:string,proce_count:any, monthflg:any, yearflg:any) => {
            setState({
                ...state,
                loading :true,
            })
            ProcedureService.list(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page, search_keywords, sort, column, order,proce_count, monthflg, yearflg).then((response)=>{
            setState({
                ...state,
                procedures:response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                column : response.data.column,
                order : response.data.order,
                totalRows : response.data.total,
                orderStatusList : response.data.select_options.order_status,
                purchaseStatusList : response.data.select_options.purchase_status,
                filterBillStatusList : response.data.select_options.filter_bill_status,
                supplier_list : response.data.suppliers,
                affiliation_company_list : response.data.affiliation_company,
                loading :false,
            })
            setOrderProce(response.data.order_proce);
            setPurchaseProce(response.data.purchase_proce);
            setBillProce(response.data.bill_proce);
            setExpectContractType(response.data.select_options.expect_contract_type);
            setPaymentSite(response.data.select_options.payment_site);
            setOPaymentSite(response.data.select_options.o_payment_site);
            setBillStatus(response.data.select_options.bill_status);
            setBuyupStatus(response.data.select_options.buyup_status);

            let flonet_data = response.data.chart_data.chartYflonet;
            let floboard_data = response.data.chart_data.chartYfloboard;
            let xAxis = response.data.chart_data.chartXaxis;


            setMonthflg(response.data.chart_data.monthFlg);
            setYearFlg(response.data.chart_data.yearFlg);

            var flonet_chart_data:any[] = [];
			for(var i in flonet_data){
                flonet_chart_data.push(flonet_data[i]);
            }

			var floboard_chart_data:any[] = [];
			for(var j in floboard_data){
                floboard_chart_data.push(floboard_data[j]);
            }

			var x_axis:any[] = [];
			for(var k in xAxis)
			x_axis.push(xAxis[k]);

            setChartData({
                labels: x_axis,
                datasets: [
                  {
                    label: '売上:Flonet',
                    data:   flonet_chart_data,
                  },
                  {
                    label: '売上:FloBoard',
                    data:   floboard_chart_data,
                  },
                ],
              });
              if(Math.floor(response.data.total/response.data.per_page) >0){
                console.log("Response1111 " +response.data.per_page);
                setShowNextColor("blue");
                }else{
                    console.log("Response2222 " +response.data.per_page);
                    setShowNextColor("grey");
                }
                if(0 == response.data.current_page-1){
                    console.log("Response3333 " +response.data.per_page);
                    setShowPrevColor("grey");
                }
                if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                    setShowNextColor("grey");
                }
        }).catch((error) => {
            console.log(error);
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                errorMessage:error.message,
                loading :false,
            })
        });
    }
    let {procedures, perPage, page, column, order, totalRows, orderStatusList, purchaseStatusList, filterBillStatusList, supplier_list, affiliation_company_list, procedure_model, disable_procedure_model, loading} = state;

    const selectAll = (e:any) => {
        if(checked == false){
            isChecked(true);
            let ids : any[] = [];
            // works.forEach((element, index) => {
            //     ids.push(element["case_id"]);
            //     setCheckedRows(ids);
            //   });
            $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
        }else{
            isChecked(false);
            setCheckedRows([]);
            $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
        }

        // .addClass("list_check_all");
        console.log(checkedRows);
      };

    useEffect(()=>{
        setState({...state, loading: true});
        setColumnVisible(defaultColumns);
        getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
        
    }, [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page+1, search, sort, column, order,proce_count, monthflg, yearflg)
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg)
    };
    


    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "companyInfo":
                setCompanyInfoChecked(e.target.checked);
                if(e.target.checked === true){
                    if(billInfoChecked  && orderInfoChecked){
                        setColumnVisible(allColumns);
                    }else if(billInfoChecked == true){
                        setColumnVisible(companyBillInfoColumns);
                    }else if(orderInfoChecked == true){
                        setColumnVisible(companyOrderInfoColumns);
                    }else{
                        setColumnVisible(companyInfoColumn);
                    }
                }else
                {
                    if(billInfoChecked && orderInfoChecked){
                        setColumnVisible(billOrderInfoColumns);
                    }else if(billInfoChecked){
                        setColumnVisible(billInfoColumn);
                    }else if(orderInfoChecked){
                        setColumnVisible(orderInfoColumns);
                    }else{
                        setColumnVisible(defaultColumns);
                    }
                }
                break;
            case "billInfo":
                setBillInfoChecked(e.target.checked);
                if(e.target.checked == true){
                    if(companyInfoChecked && orderInfoChecked){
                        setColumnVisible(allColumns);
                    }else if(companyInfoChecked){
                        setColumnVisible(companyBillInfoColumns);
                    }else if(orderInfoChecked){
                        setColumnVisible(billOrderInfoColumns);
                    }else{
                        setColumnVisible(billInfoColumn);
                    }
                }else
                {
                    if(companyInfoChecked && orderInfoChecked){
                        setColumnVisible(companyOrderInfoColumns);
                    }else if(companyInfoChecked){
                        setColumnVisible(companyInfoColumn);
                    }else if(orderInfoChecked){
                        setColumnVisible(orderInfoColumns);
                    }else{
                        setColumnVisible(defaultColumns);
                    }
                }
                break;
            case "orderInfo":
                setOrderInfoChecked(e.target.checked);
                if(e.target.checked == true){
                    if(companyInfoChecked && billInfoChecked){
                        setColumnVisible(allColumns);
                    }else if(companyInfoChecked){
                        setColumnVisible(companyOrderInfoColumns);
                    }else if(billInfoChecked){
                        setColumnVisible(billOrderInfoColumns);
                    }else{
                        setColumnVisible(orderInfoColumns);
                    }
                }else
                {
                    if(companyInfoChecked && billInfoChecked){
                        setColumnVisible(companyBillInfoColumns);
                    }else if(companyInfoChecked){
                        setColumnVisible(companyInfoColumn);
                    }else if(billInfoChecked){
                        setColumnVisible(billInfoColumn);
                    }else{
                        setColumnVisible(defaultColumns);
                    }
                }
                break;
            default:
                break;
        }
        
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "order_status":
                setOrderStatus(e.target.value);
                getData(target_months,e.target.value,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "purchase_status":
                setPurchaseStatus(e.target.value);
                getData(target_months,order_status,e.target.value,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "filter_bill_status":
                setFilterBillStatus(e.target.value);
                getData(target_months,order_status,purchase_status, e.target.value,share_memo_filter,belongs_filter, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "share_memo_filter":
                setShareMemoFilter(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status,e.target.value,belongs_filter, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "belongs_filter":
                setBelongsFilter(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status, share_memo_filter, e.target.value, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "supplier_filter":
                setSupplierFilter(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status, share_memo_filter, belongs_filter , e.target.value, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "affiliation_company_filter":
                setAffiliationCompanyFilter(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status, share_memo_filter, belongs_filter , supplier_filter, 
                    e.target.value, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                break;
            case "expect_start_month":
                setMonthflg(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status, share_memo_filter, belongs_filter , supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, e.target.value, yearflg);
                break;
            case "expect_end_date":
                setYearFlg(e.target.value);
                getData(target_months,order_status,purchase_status, filter_bill_status, share_memo_filter, belongs_filter , supplier_filter, 
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, e.target.value);
                break;
            case "sort":
                setSort(e.target.value);
                getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
                    affiliation_company_filter, perPage, page, search, e.target.value, column, order,proce_count, monthflg, yearflg)
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })

                break;
            default:
                break;
        }
     };

     let handleDelete = (rowData:any, rowState:any) => {
        let rows : any[] = [];
        rowData.data.forEach((element:any, index:any) => {
            rows.push(element.dataIndex);
        })
        let ids : any[] = [];
        // works.forEach((element, index) => {
        //   if (rows.includes(index)) {
        //     ids.push(element["case_id"]);
        //   }
        // });
        // CaseListService.delete(ids).then((response:any)=>{
        //     if(response.data.success == false){
        //         alert(response.data.info.message);
        //         window.location.reload();
        //     }
        // }).catch((error:any) => {
        //     setState({
        //         ...state,
        //         errorMessage:error.message
        //     })
        // })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        console.log(temp);
        // temp.page = this.state.page;
        getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page, search,"", column, order,proce_count, monthflg, yearflg)
      };

      const handleDateChange = function(date:any, column_name:any){
        // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate());
        let selected_data = (date!=null)?date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()):"";
        let selected_yearmonth = (date!=null)?date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0"):"";
        // let selected_yearmonth = date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0");
       
            switch (column_name) {
                case "target_months":
                    setSelectedTargetMonth(date);
                    (date!= null)?setTargetMonths(date.getFullYear() +"-"+ (date.getMonth() + 1).toString().padStart(2, "0")): setTargetMonths("");
                    getData(selected_yearmonth,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
                        affiliation_company_filter, perPage, page, search,sort, column, order,proce_count, monthflg, yearflg)
                    break;
                case "bill_from_date":
                    setSelectedBillFromDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            bill_from_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                    break;
                case "bill_to_date":
                    setSelectedBillToDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            bill_to_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "work_end_date_plan":
                    setSelectedWorkEndDatePlan(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            work_end_date_plan : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "sale_order_date":
                    setSelectedSaleOrderDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            sale_order_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "sale_order_end_d":
                    setSelectedSaleOrderEndDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            sale_order_end_d : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "buyup_order_date":
                    setSelectedBuyUpOrderDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            buyup_order_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "buyup_order_end_d":
                    setSelectedBuyupOrderEndDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            buyup_order_end_d : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                case "bill_create_date":
                    setSelectedBillCreateDate(date);
                    setState({
                        ...state,
                        procedure_model : {
                            ...state.procedure_model,
                            bill_create_date : selected_data // input name 'username' set value 'event target value'
                        }
                    });
                break;
                // case "accept_proc_date":
                //     setSelectedAcceptProcDate(date);
                //     setState({
                //         ...state,
                //         procedure_model : {
                //             ...state.procedure_model,
                //             accept_proc_date : date // input name 'username' set value 'event target value'
                //         }
                //     });
                //     break;
                // case "security_edu_date":
                //     setSelectedSecurityEduDate(date);
                //     setState({
                //         ...state,
                //         procedure_model : {
                //             ...state.procedure_model,
                //             security_edu_date : date // input name 'username' set value 'event target value'
                //         }
                //     });
                //     break;
                // case "entrance_date":
                //     setSelectedEntranceDate(date);
                //     setState({
                //         ...state,
                //         procedure_model : {
                //             ...state.procedure_model,
                //             entrance_date : date // input name 'username' set value 'event target value'
                //         }
                //     });
                //     break;
                // case "hearing_date":
                //     setSelectedHearingDate(date);
                //     setState({
                //         ...state,
                //         procedure_model : {
                //             ...state.procedure_model,
                //             hearing_date : date // input name 'username' set value 'event target value'
                //         }
                //     });
                //     break;
                // case "exit_date":
                //     setSelectedExitDate(date);
                //     setState({
                //         ...state,
                //         procedure_model : {
                //             ...state.procedure_model,
                //             exit_date : date // input name 'username' set value 'event target value'
                //         }
                //     });
                //     break;
                    
                default:
                    break;
            }
    
        }
    
   
    let updateAccounting = (accounting_id:any) => {
        ProcedureService.edit(accounting_id).then((data)=>{
            console.log(data);
            if(data.data.status == "error"){
                alert(data.data.msg);
            }else{
                setState({
                    ...state,
                    procedure_model : {
                        accounting_id: data.data.procedure.accounting_id,
                        work_id :data.data.procedure.work_id,
                        case_id : data.data.procedure.case_id,
                        bill_from_date : data.data.procedure.bill_from_date,
                        bill_to_date : data.data.procedure.bill_to_date,
                        work_place : data.data.procedure.work_place,
                        work_hours : data.data.procedure.work_hours,
                        work_end_date_plan : data.data.procedure.work_end_date_plan,
                        sale_order_date : data.data.procedure.sale_order_date,
                        sale_order_no : data.data.procedure.sale_order_no,
                        sale_order_nm : data.data.procedure.sale_order_nm,
                        buyer_contruct : data.data.procedure.buyer_contruct,
                        sale_price_hour : data.data.procedure.sale_price_hour,
                        sale_price_excess : data.data.procedure.sale_price_excess,
                        sale_price_deduct : data.data.procedure.sale_price_deduct,
                        sale_price_month : data.data.procedure.sale_price_month,
                        sale_work_rate : data.data.procedure.sale_work_rate,
                        sale_order_amount : data.data.procedure.sale_order_amount,
                        sale_order_end_d : data.data.procedure.sale_order_end_d,
                        buyup_order_date : data.data.procedure.buyup_order_date,
                        buyup_order_no : data.data.procedure.buyup_order_no,
                        buyup_order_nm : data.data.procedure.buyup_order_nm,
                        seller_contruct : data.data.procedure.seller_contruct,
                        buyup_price_hour : data.data.procedure.buyup_price_hour,
                        buyup_price_excess : data.data.procedure.buyup_price_excess,
                        buyup_price_deduct : data.data.procedure.buyup_price_deduct,
                        buyup_price_month : data.data.procedure.buyup_price_month,
                        buyup_work_rate : data.data.procedure.buyup_work_rate,
                        buyup_order_amount : data.data.procedure.buyup_order_amount,
                        buyup_order_end_d : data.data.procedure.buyup_order_end_d,
                        work_payment_site: data.data.procedure.work_payment_site,
                        bill_create_date : data.data.procedure.bill_create_date,
                        bill_no : data.data.procedure.bill_no,
                        rate : data.data.procedure.rate,
                        sale_travel_expense : data.data.procedure.sale_travel_expense,
                        bill_amount : data.data.procedure.bill_amount,
                        bill_status : data.data.procedure.bill_status,
                        bill_memo : data.data.procedure.bill_memo,
                        buyup_bill_no : data.data.procedure.buyup_bill_no,
                        buyup_travel_expense : data.data.procedure.buyup_travel_expense,
                        buyup_bill_amount : data.data.procedure.buyup_bill_amount,
                        buyup_status : data.data.procedure.buyup_status,
                        buyup_bill_memo : data.data.procedure.buyup_bill_memo
                    },
                    disable_procedure_model : {
                        person_full_name :  data.data.procedure.person_full_name,
                        work_month :  data.data.procedure.work_month,
                        person_company_company :  data.data.procedure.person_company_company,
                        person_company_officer_name :  data.data.procedure.person_company_officer_name,
                        person_company_officer_mailadress :  data.data.procedure.person_company_officer_mailadress,
                        case_customer_invoice_no :  data.data.procedure.case_customer_invoice_no,
                        person_person_cost :  data.data.procedure.person_person_cost,
                        case_customer_company :  data.data.procedure.case_customer_company,
                        case_customer_officer_name :  data.data.procedure.case_customer_officer_name,
                        case_customer_officer_mailadress :  data.data.procedure.case_customer_officer_mailadress,
                        work_contract_price_type :  data.data.procedure.work_contract_price_type,
                        work_settlement :  data.data.procedure.work_settlement,
                        work_settlement_to :  data.data.procedure.work_settlement_to,
                        work_payment_site :  data.data.procedure.work_payment_site,
                        work_o_adj_hours_low :  data.data.procedure.work_o_adj_hours_low,
                        work_o_adj_hours_high :  data.data.procedure.work_o_adj_hours_high,
                        work_o_payment_site :  data.data.procedure.work_o_payment_site,
                        work_flb_shared_cmt :  data.data.procedure.work_flb_shared_cmt
                    }
                   })
                  
                   setOpen(true);
           }
         
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }
    let downloadURL = process.env.REACT_APP_DOWNLOADURL;

    const createPurchaseOrder = (accounting_id:any) => {
        // const file = new Blob(["Hello World"], { type: 'text/plain;charset=utf-8' });
        // FileSaver.saveAs(file);

       let data = {}
       data["id"] = accounting_id;
       ProcedureService.createPurchaseOrder(data).then((response:any)=>{
        console.log(response);
            if(response.data.data.success == "error"){
                alert(response.data.data.msg);
            }else{ 
                // let filename =  response.data.data.data.filename;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;

                console.log(store_filename);
                console.log(template);
                const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                ResourceService.apiUpload(file, 'accounting_path/', store_filename).then((res)=>{
                    window.open(`/management/download/svg/${accounting_id}?type=1`, '_blank');
                });

                // let file = "accounting_path/"+response.data.data.data.filename;
                // window.open(ResourceService.download(file));

                // let purchase_order =  response.data.data.data;
                // let filename =  response.data.data.data.filename;
                // let store_filename  = response.data.data.data.store_filename;
                // let template = purchase_order.template;
                // template.replace("%destination%", purchase_order.destination);
                // template.replace("%purchase_no%", purchase_order.purchase_no);
                // template.replace("%created_date%", purchase_order.created_date);
                // template.replace("%business_nm%", purchase_order.business_nm);
                // template.replace("%work_description%", purchase_order.work_description);
                // template.replace("%bill_from_date%", purchase_order.bill_from_date);
                // template.replace("%bill_to_date%", purchase_order.bill_to_date);
                // template.replace("%person_nm%", purchase_order.person_nm);
                // template.replace("%hours_low%", purchase_order.hours_low);
                // template.replace("%hours_high%", purchase_order.hours_high);
                // template.replace("%biko%", purchase_order.biko);
                // template.replace("%unit_money%", purchase_order.unit_money);
                // template.replace("%effort_unit%", purchase_order.effort_unit);
                // template.replace("%effort_unit_x%", purchase_order.effort_unit_x);
                // template.replace("%effort_val%", purchase_order.effort_val);
                // template.replace("%deductible_unit_price%", purchase_order.deductible_unit_price);
                // template.replace("%excess_unit_price%", purchase_order.excess_unit_price);
                // template.replace("%amount%", purchase_order.amount);
                // template.replace("%consumption_tax%", purchase_order.consumption_tax);
                // template.replace("%total%", purchase_order.total);
                // template.replace("%buyup_bill_memo%", purchase_order.buyup_bill_memo);
                // template.replace("%day_of_pay%", purchase_order.day_of_pay);
                // template.replace("%work_id%", purchase_order.work_id);
                // template.replace("%contract_price_type%", purchase_order.contract_price_type);
                // template.replace("%purchase_order_file_type%", purchase_order.purchase_order_file_type);
                //const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                //FileSaver.saveAs(file , filename);  
                //ResourceService.apiUpload(file, 'accounting_path/', store_filename); 
            }
        }).catch((error:any) => {
            console.log(error);
            // if(error.response.status == 403){
            //     alert(error.response.data.message);
            // }
            setState({
                ...state,
                errorMessage:error.message
            })
        })
    }

    const admitPurchaseOrder = (accounting_id:any, status:any) => {
        let data = {}
        data["id"] = accounting_id;
        data["process"] = status;
        console.log(data);
       ProcedureService.admitPurchaseOrder(data).then((response:any)=>{
         console.log(response.data);
            if(response.data.success){
                if(response.data.data.status == "error"){
                    alert(response.data.data.msg);
                }else{
                    // if(response.data.data.process == "create" || response.data.data.process == "recreate"){
                    //     let purchase_order =  response.data.data;
                    //     let template = purchase_order.template;
                    //     let filename =  response.data.data.data.attachment_file;
                    //     const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                    //     ResourceService.apiUpload(file, 'accounting_path/', filename); 

                    //     ProcedureService.sendPurchaseOrderMail(response.data.data).then((res:any) => {
                    //         console.log(res);
                    //     });
                    // }
                    window.location.reload();
                }
            }
        }).catch((error:any) => {
            if(error.code = "ERR_NETWORK"){
                window.location.reload();
            }
            
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                errorMessage:error.message
            })
        })
    }

    const createBill = (accounting_id:any) => {
        let data = {}
        data["id"] = accounting_id;
        ProcedureService.createBill(data).then((response:any)=>{
            // window.location.href = `/management/download/svg`;
            // window.open(ResourceService.download(file));
            if(response.data.data.status == "error"){
                alert(response.data.data.msg);
            }else{     
                // let file = "accounting_path/"+response.data.data.data.filename;
                // window.open(ResourceService.download(file));

                let filename =  response.data.data.data.filename;
                let store_filename  = response.data.data.data.store_filename;
                let template = response.data.data.data.template;
                const file = new Blob([`${template}`], {type: "image/svg+xml;utf8"});
                // FileSaver.saveAs(file , filename);  
                ResourceService.apiUpload(file, 'accounting_path/', store_filename).then((res)=>{                    
                    window.open(`/management/download/svg/${accounting_id}`, '_blank');
                });
               

            }

        //  if(response.data.data.success == "error"){
        //      alert(response.data.data.msg);
        //  }else{
        //      ProcedureService.dlBillFile(accounting_id).then((response:any)=>{
        //         // console.log(response.data);
        //          // window.open(response.request.responseURL);
        //          window.open(`${downloadURL}public/accounting_path/${response.data.file}`);
        //      })
        //  }
             
         }).catch((error:any) => {
            
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
             setState({
                 ...state,
                 errorMessage:error.message
             })
         })
    }

    const admitBill = (accounting_id:any, status:any) => {
        let data = {}
        data["id"] = accounting_id;
        data["process"] = status;

        
        


        ProcedureService.admitBill(data).then((response:any)=>{
            
            console.log("response start");
            console.log(response);
            console.log("response end");

            if(response.data.data.status == "error"){
                alert(response.data.data.msg);
            }else{
                window.location.reload();
            }
             
         }).catch((error:any) => {
            console.log(error);
            if(error.code = "ERR_NETWORK"){
                window.location.reload();
            }
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
             setState({
                 ...state,
                 errorMessage:error.message
             })
         })
    }


    const options = {
        filterType: 'checkbox' as any,
        responsive: "scrollMaxHeight" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        selectableRows: true as any,
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //     console.log(rows); //  <-------- can't get only selected rows
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {        
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                procedures.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });

                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "経理情報情報" +date;
                }

                let headers  : any[]= [];
                // if(partnerchecked == true){
                // }
                if (companyInfoChecked){
                    headers =  [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'person_company_company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person_company_officer_name',
                            label : '所属事務担当者'
                        },
                        {
                            key : 'person_company_officer_mailadress',
                            label : '所属事務連絡先メール'
                        },
                        {
                            key : 'person_person_cost',
                            label : 'コスト'
                        },
                        {
                            key :  'case_customer_invoice_no' ,
                            label : 'インボイス番号'
                        },
                        {
                            key : 'case_customer_company',
                            label : '取引先'
                        },
                        {
                            key : 'case_customer_officer_name',
                            label : '取引先事務担当'
                        },
                        {
                            key : 'case_customer_officer_mailadress',
                            label : '取引先事務担当メール'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_no',
                            label : '受注番号'
                        },
                        {
                            key : 'sale_order_nm',
                            label : '受注案件名'
                        },
                        {
                            key : 'buyer_contruct',
                            label : '受注契約'
                        },
                        {
                            key : 'work_contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'sale_price_hour',
                            label : '受注時間単金'
                        },
                        {
                            key : 'sale_price_excess',
                            label : '受注超過単金'
                        },
                        {
                            key : 'sale_price_deduct',
                            label: '受注控除単金'
                        },
                        {
                            key : 'sale_price_month',
                            label: '受注月標準単金'
                        },
                        {
                            key : 'sale_work_rate' ,
                            label: '受注稼働率'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_no',
                            label: '発注番号'
                        },
                        {
                            key : 'buyup_order_nm',
                            label : '発注案件名'
                        },
                        {
                            key :  'seller_contruct',
                            label: '発注契約'
                        },
                        {
                            key : 'buyup_price_hour',
                            label: '発注時間単金'
                        },
                        {
                            key : 'buyup_price_excess',
                            label: '発注超過単金'
                        },
                        {
                            key : 'buyup_price_deduct',
                            label: '発注控除単金'
                        },
                        {
                            key : 'buyup_price_month',
                            label: '発注月標準単金'
                        },
                        {
                            key :'buyup_work_rate' ,
                            label:  '発注稼働率'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        }
                    ];
                }else if(billInfoChecked){
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_no',
                            label : '受注番号'
                        },
                        {
                            key : 'sale_order_nm',
                            label : '受注案件名'
                        },
                        {
                            key : 'buyer_contruct',
                            label : '受注契約'
                        },
                        {
                            key : 'work_contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'sale_price_hour',
                            label : '受注時間単金'
                        },
                        {
                            key : 'sale_price_excess',
                            label : '受注超過単金'
                        },
                        {
                            key : 'sale_price_deduct',
                            label: '受注控除単金'
                        },
                        {
                            key : 'sale_price_month',
                            label: '受注月標準単金'
                        },
                        {
                            key : 'sale_work_rate' ,
                            label: '受注稼働率'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_no',
                            label: '発注番号'
                        },
                        {
                            key : 'buyup_order_nm',
                            label : '発注案件名'
                        },
                        {
                            key :  'seller_contruct',
                            label: '発注契約'
                        },
                        {
                            key : 'buyup_price_hour',
                            label: '発注時間単金'
                        },
                        {
                            key : 'buyup_price_excess',
                            label: '発注超過単金'
                        },
                        {
                            key : 'buyup_price_deduct',
                            label: '発注控除単金'
                        },
                        {
                            key : 'buyup_price_month',
                            label: '発注月標準単金'
                        },
                        {
                            key :'buyup_work_rate' ,
                            label:  '発注稼働率'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_create_date',
                            label : '請求日'
                        },
                        {
                            key : 'bill_no',
                            label : '請求番号'
                        },
                        {
                            key : 'rate' ,
                            label :  '税率'
                        },
                        {
                            key : 'sale_travel_expense' ,
                            label: '請求交通費'
                        },
                        {
                            key : 'bill_amount',
                            label: '請求金額'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'bill_memo' ,
                            label:  '請求備考'
                        },
                        {
                            key : 'buyup_bill_no' ,
                            label : '乙方請求番号'
                        },
                        {
                            key : 'buyup_travel_expense' ,
                            label: '乙方交通費'
                        },
                        {
                            key :  'buyup_bill_amount' ,
                            label: '乙方請求金額'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        },
                        {
                            key : 'buyup_bill_memo' ,
                            label: '乙方請求備考'
                        }
                     ];
                }else if (orderInfoChecked) {
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名',
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        }
                       ];
                }else if(companyInfoChecked && billInfoChecked){
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'person_company_company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person_company_officer_name',
                            label : '所属事務担当者'
                        },
                        {
                            key : 'person_company_officer_mailadress',
                            label : '所属事務連絡先メール'
                        },
                        {
                            key : 'person_person_cost',
                            label : 'コスト'
                        },
                        {
                            key :  'case_customer_invoice_no' ,
                            label : 'インボイス番号'
                        },
                        {
                            key : 'case_customer_company',
                            label : '取引先'
                        },
                        {
                            key : 'case_customer_officer_name',
                            label : '取引先事務担当'
                        },
                        {
                            key : 'case_customer_officer_mailadress',
                            label : '取引先事務担当メール'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_no',
                            label : '受注番号'
                        },
                        {
                            key : 'sale_order_nm',
                            label : '受注案件名'
                        },
                        {
                            key : 'buyer_contruct',
                            label : '受注契約'
                        },
                        {
                            key : 'work_contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'sale_price_hour',
                            label : '受注時間単金'
                        },
                        {
                            key : 'sale_price_excess',
                            label : '受注超過単金'
                        },
                        {
                            key : 'sale_price_deduct',
                            label: '受注控除単金'
                        },
                        {
                            key : 'sale_price_month',
                            label: '受注月標準単金'
                        },
                        {
                            key : 'sale_work_rate' ,
                            label: '受注稼働率'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_no',
                            label: '発注番号'
                        },
                        {
                            key : 'buyup_order_nm',
                            label : '発注案件名'
                        },
                        {
                            key :  'seller_contruct',
                            label: '発注契約'
                        },
                        {
                            key : 'buyup_price_hour',
                            label: '発注時間単金'
                        },
                        {
                            key : 'buyup_price_excess',
                            label: '発注超過単金'
                        },
                        {
                            key : 'buyup_price_deduct',
                            label: '発注控除単金'
                        },
                        {
                            key : 'buyup_price_month',
                            label: '発注月標準単金'
                        },
                        {
                            key :'buyup_work_rate' ,
                            label:  '発注稼働率'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label : '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label : '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_create_date',
                            label : '請求日'
                        },
                        {
                            key : 'bill_no',
                            label : '請求番号'
                        },
                        {
                            key : 'rate' ,
                            label :  '税率'
                        },
                        {
                            key : 'sale_travel_expense' ,
                            label: '請求交通費'
                        },
                        {
                            key : 'bill_amount',
                            label: '請求金額'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'bill_memo' ,
                            label:  '請求備考'
                        },
                        {
                            key : 'buyup_bill_no' ,
                            label : '乙方請求番号'
                        },
                        {
                            key : 'buyup_travel_expense' ,
                            label: '乙方交通費'
                        },
                        {
                            key :  'buyup_bill_amount' ,
                            label: '乙方請求金額'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        },
                        {
                            key : 'buyup_bill_memo' ,
                            label: '乙方請求備考'
                        }
                    ];
                }
                else if(companyInfoChecked && orderInfoChecked){
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'person_company_company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person_company_officer_name',
                            label : '所属事務担当者'
                        },
                        {
                            key : 'person_company_officer_mailadress',
                            label : '所属事務連絡先メール'
                        },
                        {
                            key : 'person_person_cost',
                            label : 'コスト'
                        },
                        {
                            key :  'case_customer_invoice_no' ,
                            label : 'インボイス番号'
                        },
                        {
                            key : 'case_customer_company',
                            label : '取引先'
                        },
                        {
                            key : 'case_customer_officer_name',
                            label : '取引先事務担当'
                        },
                        {
                            key : 'case_customer_officer_mailadress',
                            label : '取引先事務担当メール'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        },
                    ];
                }else if(billInfoChecked && orderInfoChecked){
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_create_date',
                            label : '請求日'
                        },
                        {
                            key : 'bill_no',
                            label : '請求番号'
                        },
                        {
                            key : 'rate' ,
                            label :  '税率'
                        },
                        {
                            key : 'sale_travel_expense' ,
                            label: '請求交通費'
                        },
                        {
                            key : 'bill_amount',
                            label: '請求金額'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'bill_memo' ,
                            label:  '請求備考'
                        },
                        {
                            key : 'buyup_bill_no' ,
                            label : '乙方請求番号'
                        },
                        {
                            key : 'buyup_travel_expense' ,
                            label: '乙方交通費'
                        },
                        {
                            key :  'buyup_bill_amount' ,
                            label: '乙方請求金額'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        },
                        {
                            key : 'buyup_bill_memo' ,
                            label: '乙方請求備考'
                        }
                    ];
                }
                else if (companyInfoChecked && billInfoChecked && orderInfoChecked){
                    headers = [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID'
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'person_company_company',
                            label : '所属会社名'
                        },
                        {
                            key : 'person_company_officer_name',
                            label : '所属事務担当者'
                        },
                        {
                            key : 'person_company_officer_mailadress',
                            label : '所属事務連絡先メール'
                        },
                        {
                            key : 'person_person_cost',
                            label : 'コスト'
                        },
                        {
                            key :  'case_customer_invoice_no' ,
                            label : 'インボイス番号'
                        },
                        {
                            key : 'case_customer_company',
                            label : '取引先'
                        },
                        {
                            key : 'case_customer_officer_name',
                            label : '取引先事務担当'
                        },
                        {
                            key : 'case_customer_officer_mailadress',
                            label : '取引先事務担当メール'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_amount',
                            label : '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label : '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label : '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label : '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_create_date',
                            label : '請求日'
                        },
                        {
                            key : 'bill_no',
                            label : '請求番号'
                        },
                        {
                            key : 'rate' ,
                            label :  '税率'
                        },
                        {
                            key : 'sale_travel_expense' ,
                            label: '請求交通費'
                        },
                        {
                            key : 'bill_amount',
                            label: '請求金額'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'bill_memo' ,
                            label:  '請求備考'
                        },
                        {
                            key : 'buyup_bill_no' ,
                            label : '乙方請求番号'
                        },
                        {
                            key : 'buyup_travel_expense' ,
                            label: '乙方交通費'
                        },
                        {
                            key :  'buyup_bill_amount' ,
                            label: '乙方請求金額'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        },
                        {
                            key : 'buyup_bill_memo',
                            label: '乙方請求備考'
                        },
                    ];
                }
                else{
                    headers =  [
                        {
                            key :  'accounting_id' ,
                            label : '経理ID',
                        },
                        {
                            key : 'work_id',
                            label : '稼働ID'
                        },
                        {
                            key : 'person_full_name',
                            label : '要員名'
                        },
                        {
                            key : 'work_month',
                            label : '対応月'
                        },
                        {
                            key : 'bill_from_date',
                            label : '当月稼働開始日'
                        },
                        {
                            key : 'bill_to_date',
                            label : '当月稼働終了日'
                        },
                        {
                            key : 'work_place',
                            label : '稼働場所'
                        },
                        {
                            key : 'work_hours',
                            label : '稼働時間'
                        },
                        {
                            key : 'work_end_date_plan',
                            label : '稼働終了予定日'
                        },
                        {
                            key : 'sale_order_date',
                            label : '受注日'
                        },
                        {
                            key : 'sale_order_no',
                            label : '受注番号'
                        },
                        {
                            key : 'sale_order_nm',
                            label : '受注案件名'
                        },
                        {
                            key : 'buyer_contruct',
                            label : '受注契約'
                        },
                        {
                            key : 'work_contract_price_type',
                            label : '稼働単金種別'
                        },
                        {
                            key : 'sale_price_hour',
                            label : '受注時間単金'
                        },
                        {
                            key : 'sale_price_excess',
                            label : '受注超過単金'
                        },
                        {
                            key : 'sale_price_deduct',
                            label: '受注控除単金'
                        },
                        {
                            key : 'sale_price_month',
                            label: '受注月標準単金'
                        },
                        {
                            key : 'sale_work_rate' ,
                            label: '受注稼働率'
                        },
                        {
                            key : 'sale_order_amount',
                            label : '受注金額'
                        },
                        {
                            key : 'sale_order_end_d',
                            label : '受注終了予定日'
                        },
                        {
                            key : 'buyup_order_date',
                            label : '発注日'
                        },
                        {
                            key : 'buyup_order_no',
                            label: '発注番号'
                        },
                        {
                            key : 'buyup_order_nm',
                            label : '発注案件名'
                        },
                        {
                            key :  'seller_contruct',
                            label: '発注契約'
                        },
                        {
                            key : 'buyup_price_hour',
                            label: '発注時間単金'
                        },
                        {
                            key : 'buyup_price_excess',
                            label: '発注超過単金'
                        },
                        {
                            key : 'buyup_price_deduct',
                            label: '発注控除単金'
                        },
                        {
                            key : 'buyup_price_month',
                            label: '発注月標準単金'
                        },
                        {
                            key :'buyup_work_rate' ,
                            label:  '発注稼働率'
                        },
                        {
                            key : 'buyup_order_amount',
                            label: '発注金額'
                        },
                        {
                            key : 'buyup_order_end_d',
                            label: '発注終了予定日'
                        },
                        {
                            key : 'work_settlement',
                            label: '精算From'
                        },
                        {
                            key : 'work_settlement_to',
                            label: '精算To'
                        },
                        {
                            key : 'work_payment_site',
                            label: '支払サイト'
                        },
                        {
                            key : 'work_o_adj_hours_low',
                            label : '乙方精算From'
                        },
                        {
                            key : 'work_o_adj_hours_high',
                            label : '乙方精算To'
                        },
                        {
                            key : 'work_o_payment_site',
                            label : '乙方支払サイト'
                        },
                        {
                            key : 'work-flb_shared_cmt',
                            label: '社内共有メモ'
                        },
                        {
                            key : 'bill_status',
                            label: '請求ステータス'
                        },
                        {
                            key : 'buyup_status' ,
                            label :  '乙方請求チェック'
                        }
                        ];
                   
                }
            var now = new Date();
            if(login_user.role == 6 || login_user.role == 9){
                return (
                    <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
                );
            }
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            console.log("selected");
            console.log(rowsSelected);
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            console.log(currentPage);
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            console.log("No of Row" + numberOfRows);
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        // onRowClick: (rowData:any, rowState:any) => {
        //     // console.log("start");
        //    goToDetail(rowData, rowState);
        //     // console.log("end");
        // },
        onCellClick : (colData:any, cellMeta:any) =>{
            console.log(colData);
            console.log(cellMeta);
            // goToDetail(colData, cellMeta);

        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
                        affiliation_company_filter, perPage, page, e.target.value, sort, column, order,proce_count, monthflg, yearflg);
                    break;
            default:
                break;
        }
        
    };  

    const proceCount = function(value:any) {
        getData(target_months,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter, 
            affiliation_company_filter, perPage, page, search, sort, column, order,value, monthflg, yearflg);
    }

    const onSelectProcedure = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            procedure_model : {
                ...state.procedure_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    let updateInput = (e:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            procedure_model : {
                ...state.procedure_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });      
    };

    let updateTextArea = (e:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            procedure_model : {
                ...state.procedure_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
            ProcedureService.update(state.procedure_model, state.procedure_model.accounting_id).then((response) => {
                getData(selectedTargetMonth,order_status,purchase_status,filter_bill_status,share_memo_filter,belongs_filter, supplier_filter,
                    affiliation_company_filter, perPage, page, search, sort, column, order,proce_count, monthflg, yearflg);
                handleClose();
                window.location.href = "/management/procedures/list";
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
    };

    const deleteProcedure = () => {
        if(selectedRows.length == 0){
            alert("削除したいをチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            procedures.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["accounting_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            ProcedureService.deleteList(data).then((data)=>{
                window.location.reload();
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
       
    }

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }

        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     

    }

    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    const nextMonthData = () =>{
        if(selectedRows.length > 0){
            let ans = window.confirm("選んでいたデータを翌月分を作成しますか？※今月ではないデータからは作成できません。");
            if(ans){
                let ids : any[] = [];
                procedures.forEach((element, index) => {            
                    if (selectedRows.length > 0 && selectedRows.includes(index)) {
                        ids.push(element["accounting_id"]);
                    }
                });
                let data = {};
                data["ids"] = ids;
                ProcedureService.copy(data).then((data)=>{
                    window.location.reload();
                }).catch((error) => {
                    if(error.response.status == 403){
                        alert(error.response.data.message);
                    }
                });
            }else{
                return false;
            }

        }else{
            let ans = window.confirm("全てのデータの翌月分を作成しますか？");
            if(ans){
                let data = null;
                ProcedureService.copy(data).then((data)=>{
                    window.location.reload();
                }).catch((error) => {
                    if(error.response.status == 403){
                        alert(error.response.data.message);
                    }
                });
            }else{
                return false;
            }

        }
    }
    const monthList = [1,2,3,4,5,6,7,8,9,10,11,12];

    let location = useLocation();
    const personDetail = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.location.href = `/persons/${encode}`;
    }
    const caseDetail = function (encode:any) {
        localStorage.setItem('rootRoute', location.pathname);
        localStorage.setItem('previousRoute', location.pathname);
        window.location.href = `/cases/${encode}`;
    }

    const edit_modal_box = (
        <div>
               <div className="row">
                    <div className="col-6">
                        <h3>受発注手続き編集</h3>
                    </div>
                    <div className="col-6">
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </div>            
                </div>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form create-form" onSubmit={store}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="accounting_id" value={procedure_model["accounting_id"]} />
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group ">
                                <label htmlFor="exampleInputEmail1"  style={{fontSize:"13px",fontWeight:"600"}}> 稼働ID:</label> <br/>
                                <span style={{fontSize:"15px",fontWeight:"unset"}}>{procedure_model["work_id"]} </span> 
                            </div>       
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required" style={{fontSize:"13px",fontWeight:"600"}}>要員名:</label> 
                                <input type="text" className="form-control" name="person_full_name"  value={disable_procedure_model['person_full_name']}  disabled />                                      
                            </div>          
                        </div>
                    </div>                  
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>対応月:</label> 
                                <input type="text" className="form-control" name="work_month"  value={disable_procedure_model['work_month']}  disabled />                                     
                            </div>
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required" style={{fontSize:"13px",fontWeight:"600"}}>所属会社名:</label> 
                                <input type="text" className="form-control" name="person_company_company"  value={disable_procedure_model['person_company_company']}  disabled />
                            </div>           
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>所属事務担当者:</label> 
                                <input type="text" className="form-control" name="person_company_officer_name"  value={disable_procedure_model['person_company_officer_name']}  disabled />
                            </div>  
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>所属連絡メールアドレス:</label> 
                            <input type="text" className="form-control" name="person_company_officer_mailadress"  value={disable_procedure_model['person_company_officer_mailadress']}  disabled/>       
                            </div>     
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>インボイス番号:</label> 
                                <input type="text" className="form-control" name="case_customer_invoice_no"  value={disable_procedure_model['case_customer_invoice_no']} disabled/>
                            </div>        
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="contract_price_type" style={{fontSize:"13px",fontWeight:"600"}}>コスト:</label>
                                <input type="text" className="form-control" name="person_person_cost"  value={disable_procedure_model['person_person_cost']}  disabled />                      
                            </div>         
                        </div>
                    </div>
                   <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>取引先:</label> 
                                <input type="text" className="form-control" name="case_customer_company"  value={disable_procedure_model['case_customer_company']} disabled />                      
                            </div>        
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>取引先事務担当者:</label> 
                                <input type="text" className="form-control" name="case_customer_officer_name"  value={disable_procedure_model['case_customer_officer_name']}  disabled/>
                            </div>             
                        </div>
                   </div>
                    <div className="row">
                        <div className="col-6 input-box">
                            <div className="form-group">
                            <label htmlFor="exampleInputEmail1" style={{fontSize:"13px",fontWeight:"600"}}>取引先事務担当者メール:</label> 
                                <input type="text" className="form-control" name="case_customer_officer_mailadress"  value={disable_procedure_model['case_customer_officer_mailadress']}  disabled/>
                            </div>         
                        </div>
                        <div className="col-6 input-box">
                            <div className="form-group">
                                <span id='text' className="input-required">当月稼働開始日</span>
                                <DatePicker 
                                    value={(procedure_model["bill_from_date"] != null)?procedure_model["bill_from_date"]: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedBillFromDate} 
                                    onChange={date =>
                                        handleDateChange(date, "bill_from_date")
                                    }
                                    name="bill_from_date"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    selectsStart
                                    startDate={selectedBillFromDate}
                                    endDate={selectedBillToDate}
                                    disabled={(login_user.role==5)?true:false}
                                    />   
                            </div>        
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text' className="input-required">当月稼働終了日</span>
                            <DatePicker 
                                    value={(procedure_model["bill_to_date"] != null)?procedure_model["bill_to_date"]: ""}
                                    dateFormat="yyyy/MM/dd"
                                    locale="ja"
                                    selected={selectedBillToDate} 
                                    onChange={date =>
                                        handleDateChange(date, "bill_to_date")
                                    }
                                    name="bill_to_date"
                                    isClearable
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    disabled = {(login_user.role==5)?true:((procedure_model["bill_from_date"] == null || procedure_model["bill_from_date"] == "")? true:false)}
                                    selectsEnd
                                    startDate={selectedBillFromDate}
                                    endDate={selectedBillToDate}
                                    minDate={selectedBillFromDate}
                                    />
                        </div>
                        <div className="col-6 input-box">
                            <span id='text' className="input-required">勤務場所</span>
                            <input type="text" name="work_place" id="work_place" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={procedure_model["work_place"]} onChange={updateInput}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text' className="input-required">稼働時間</span>
                            <input type="text" name="work_hours" id="work_hours" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["work_hours"]} onChange={updateInput}/>
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>出勤日数</span>
                            <input type="text" name="worked_days" id="worked_days" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["worked_days"]} onChange={updateInput}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>休日出勤</span>
                            <input type="text" name="holidaywork_days" id="holidaywork_days" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["holidaywork_days"]} onChange={updateInput}/>
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>振替休暇</span>
                            <input type="text" name="substitute_holiday" id="substitute_holiday" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["substitute_holiday"]} onChange={updateInput}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>欠勤日数</span>
                            <input type="text" name="days_absence" id="days_absence" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["days_absence"]} onChange={updateInput}/>
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>稼働終了予定日</span>
                            <DatePicker 
                                value={(procedure_model["work_end_date_plan"] != null)?procedure_model["work_end_date_plan"]: ""}
                                dateFormat="yyyy/MM/dd"
                                locale="ja"
                                selected={selectedWorkEndDatePlan} 
                                onChange={date =>
                                    handleDateChange(date, "work_end_date_plan")
                                }
                                name="work_end_date_plan"
                                isClearable
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                disabled ={(login_user.role==5)?true:false}
                                />
                        </div>
                    </div>
                    {
                        (() => {
                            if(login_user.role !=5) {
                            return(
                                <React.Fragment>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">受注日</span>
                                            <DatePicker 
                                            value={(procedure_model["sale_order_date"] != null)?procedure_model["sale_order_date"]: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedSaleOrderDate} 
                                            onChange={date =>
                                                handleDateChange(date, "sale_order_date")
                                            }
                                            name="sale_order_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>受注番号</span>
                                            <input type="text" name="sale_order_no" id="sale_order_no" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_order_no"]} onChange={updateInput}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">受注案件名</span>
                                            <input type="text" name="sale_order_nm" id="sale_order_nm" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_order_nm"]} onChange={updateInput}/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>受注契約</span>
                                            <select name="buyer_contruct" className="input-box form-control" value={(procedure_model["buyer_contruct"] != "")?procedure_model["buyer_contruct"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example">
                                                {expect_contract_type.map(buyer_contruct => {
                                                        if (buyer_contruct !== null){
                                                            return(<option value={buyer_contruct}> {buyer_contruct}</option>)
                                                        }
                                                    })
                                                }
                                            </select>  
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">稼働単金種別</span>
                                            <select name="work_contract_price_type" className="input-box form-control" value={(procedure_model["work_contract_price_type"] != "")?procedure_model["work_contract_price_type"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example" disabled>
                                                <option value="0"> 月額単金</option>
                                                <option value="1"> 時間単価</option>                                       
                                            </select> 
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>受注時間単金</span>
                                            <input type="number"  name="sale_price_hour" id="sale_price_hour" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_price_hour"]} 
                                            onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">受注超過単金</span>
                                            <input type="number" name="sale_price_excess" id="sale_price_excess" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_price_excess"]} 
                                            onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>受注控除単金</span>
                                            <input type="number" name="sale_price_deduct" id="sale_price_deduct" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_price_deduct"]} 
                                            onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>受注月標準単金</span> 
                                            <input type="number" name="sale_price_month" id="sale_price_month" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_price_month"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>受注稼働率</span>
                                            <input type="text" name="sale_work_rate" id="sale_work_rate" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_work_rate"]} onChange={updateInput}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>受注金額</span>
                                            <input type="number" name="sale_order_amount" id="sale_order_amount" className="form-control" aria-label="firstname" 
                                            aria-describedby="basic-addon2" value={procedure_model["sale_order_amount"]} onChange={updateInput} 
                                            onKeyPress={inputNumber} maxLength={8} />
                                        </div>    
                                        <div className="col-6 input-box">
                                            <span id='text'>受注終了予定日</span>
                                            <DatePicker 
                                            value={(procedure_model["sale_order_end_d"] != null)?procedure_model["sale_order_end_d"]: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedSaleOrderEndDate} 
                                            onChange={date =>
                                                handleDateChange(date, "sale_order_end_d")
                                            }
                                            name="sale_order_end_d"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                        </div>         
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">発注日</span>
                                            <DatePicker 
                                            value={(procedure_model["buyup_order_date"] != null)?procedure_model["buyup_order_date"]: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedBuyUpOrderDate}
                                            onChange={date =>
                                                handleDateChange(date, "buyup_order_date")
                                            }
                                            name="buyup_order_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                        </div>    
                                        <div className="col-6 input-box">
                                            <span id='text'>発注番号</span>
                                            <input type="text" name="buyup_order_no" id="buyup_order_no" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_order_no"]} onChange={updateInput}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">発注案件名</span>
                                            <input type="text" name="buyup_order_nm" id="buyup_order_nm" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_order_nm"]} onChange={updateInput}/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>発注契約</span>
                                            <select name="seller_contruct" className="input-box form-control" value={(procedure_model["seller_contruct"] != "")?procedure_model["seller_contruct"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example">
                                                {expect_contract_type.map(seller_contract => {
                                                        if (seller_contract !== null){
                                                            return(<option value={seller_contract}> {seller_contract}</option>)
                                                        }
                                                    })
                                                }
                                            </select>  
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>発注時間単金</span>
                                            <input type="number" name="buyup_price_hour" id="buyup_price_hour" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_price_hour"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text' className="input-required">発注超過単金</span>
                                            <input type="number" name="buyup_price_excess" id="buyup_price_excess" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_price_excess"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>発注控除単金</span>
                                            <input type="number" name="buyup_price_deduct" id="buyup_price_deduct" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_price_deduct"]} 
                                            onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>発注月標準単金</span> 
                                            <input type="number" name="buyup_price_month" id="buyup_price_month" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_price_month"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>発注稼働率</span>
                                            <input type="text" name="buyup_work_rate" id="buyup_work_rate" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_work_rate"]} onChange={updateInput}/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>発注金額</span>
                                            <input type="number" name="buyup_order_amount" id="buyup_order_amount" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_order_amount"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            }
                        })()
                    }
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>精算From</span> 
                            <input type="text" name="work_settlement" id="work_settlement" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={disable_procedure_model["work_settlement"]} onChange={updateInput} disabled/>
                          
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>精算To</span>
                            <input type="text" name="work_settlement_to" id="work_settlement_to" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={disable_procedure_model["work_settlement_to"]} onChange={updateInput} disabled/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>支払サイト</span> 
                            <select name="work_payment_site" className="input-box form-control" value={(disable_procedure_model["work_payment_site"] != "")?disable_procedure_model["work_payment_site"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example" disabled>
                                {o_payment_site_list.map(payment_site => {
                                        if (payment_site !== null){
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        }
                                    })
                                }
                            </select> 
                        </div>
                        <div className="col-6 input-box">
                            <span id='text'>乙方精算From</span>
                            <input type="text" name="work_o_adj_hours_low" id="work_o_adj_hours_low" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["work_o_adj_hours_low"]} onChange={updateInput} disabled/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6 input-box">
                            <span id='text'>乙方精算To</span>
                            <input type="text" name="work_o_adj_hours_high" id="work_o_adj_hours_high" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["work_o_adj_hours_high"]} onChange={updateInput} disabled/>
                        </div> 
                        <div className="col-6 input-box">
                            <span id='text'>乙方支払サイト</span>
                            <select name="work_o_payment_site" className="input-box form-control" value={(disable_procedure_model["work_o_payment_site"] != "")?disable_procedure_model["work_o_payment_site"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example" disabled>
                                {o_payment_site_list.map(payment_site => {
                                        if (payment_site !== null){
                                            return(<option value={payment_site}> {payment_site}</option>)
                                        }
                                    })
                                }
                            </select> 
                        </div>
                    </div>
                    {
                        (() => {
                            if(login_user.role !=5) {
                            return(
                                <React.Fragment>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>社内共有メモ</span>
                                            <input type="text" name="work-flb_shared_cmt" id="work-flb_shared_cmt" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={disable_procedure_model["work_flb_shared_cmt"]} onChange={updateInput} disabled/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>請求日</span>
                                            <DatePicker 
                                                    value={(procedure_model["bill_create_date"] != null)?procedure_model["bill_create_date"]: ""}
                                                    dateFormat="yyyy/MM/dd"
                                                    locale="ja"
                                                    selected={selectedBillCreateDate} 
                                                    onChange={date =>
                                                        handleDateChange(date, "bill_create_date")
                                                    }
                                                    name="bill_create_date"
                                                    isClearable
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>請求番号</span>
                                            <input type="text" name="bill_no" id="bill_no" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["bill_no"]} onChange={updateInput}/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>税率</span>
                                            <input type="text" name="rate" id="rate" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["rate"]} onChange={updateInput}/>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 input-box">
                                            <span id='text'>請求交通費</span>
                                            <input type="number" name="sale_travel_expense" id="sale_travel_expense" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["sale_travel_expense"]}
                                             onChange={updateInput} onKeyPress={inputNumber} maxLength={8}/>
                                        </div>
                                        <div className="col-6 input-box">
                                            <span id='text'>請求金額</span>
                                            <input type="number" name="bill_amount" id="bill_amount" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["bill_amount"]} 
                                            onChange={updateInput} onKeyPress={inputNumber} maxLength={8}/>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            }
                        })()
                    }
                   
                        {
                        (() => {
                            if(login_user.role !=5) {
                            return(
                            <React.Fragment>
                                <div className="row mt-3">
                                    <div className="col-6 input-box">
                                        <span id='text'>請求ステータス</span>
                                        <select name="bill_status" className="input-box form-control" value={(procedure_model["bill_status"] != "")?procedure_model["bill_status"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example">
                                            {bill_status_list.map(bill_status => {
                                                    if (bill_status !== null){
                                                        return(<option value={bill_status[0]}> {bill_status[1]}</option>)
                                                    }
                                                })
                                            }
                                        </select> 
                                    </div>
                                    <div className="col-6 input-box">
                                        <span id='text'>発注終了予定日</span>
                                            <DatePicker 
                                            value={(procedure_model["buyup_order_end_d"] != null)?procedure_model["buyup_order_end_d"]: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedBuyupOrderEndDate} 
                                            onChange={date =>
                                                handleDateChange(date, "buyup_order_end_d")
                                            }
                                            name="buyup_order_end_d"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 input-box">
                                        <span id='text'>請求備考</span>
                                        <textarea className="form-control" rows={3} name = "bill_memo" id= "bill_memo" aria-label="With textarea" value={procedure_model["bill_memo"]} onChange={updateTextArea}></textarea>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 input-box">
                                        <span id='text'>乙方請求番号</span>
                                        <input type="text" name="buyup_bill_no" id="buyup_bill_no" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_bill_no"]} onChange={updateInput}/>
                                    </div>
                                    <div className="col-6 input-box">
                                        <span id='text'>乙方交通費</span>
                                        <input type="number" name="buyup_travel_expense" id="buyup_travel_expense" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_travel_expense"]} 
                                        onChange={updateInput} onKeyPress={inputNumber} maxLength={8}/>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 input-box">
                                        <span id='text'>乙方請求金額</span>
                                        <input type="number" name="buyup_bill_amount" id="buyup_bill_amount" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={procedure_model["buyup_bill_amount"]} 
                                        onChange={updateInput} onKeyPress={inputNumber} maxLength={8}/>
                                    </div>
                                    <div className="col-6 input-box">
                                        <span id='text'>乙方請求チェック</span>
                                        <select name="buyup_status" className="input-box form-control" value={(procedure_model["buyup_status"] != "")?procedure_model["buyup_status"]:""} onChange={onSelectProcedure} aria-label=".form-select-sm example">
                                            {buyup_status_list.map(buyup_status => {
                                                    if (buyup_status !== null){
                                                        return(<option value={buyup_status[0]}> {buyup_status[1]}</option>)
                                                    }
                                                })
                                            }
                                        </select>  

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 input-box">
                                        <span id='text'>乙方請求備考</span>
                                        <textarea className="form-control" rows={3} name = "buyup_bill_memo" id= "buyup_bill_memo" aria-label="With textarea" value={procedure_model["buyup_bill_memo"]} onChange={updateTextArea}></textarea>
                                    </div>
                                </div>
                            </React.Fragment>
                            )
                            }
                        })()
                        }
                        
                    <div className="row">
                        <div className="form-group mt-5 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(procedure_model['accounting_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </div>
                </form>
        </div>
    )
    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(稼働ID、要員名、所属会社名、取引先名、対応月(yyyy/mm)、受注契約、稼働場所、社内共有メモを検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <Row>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={8}>
                                <h3 className='header-left-design'>受発注手続き<span className="attention">（注意事項）</span></h3>
                            </Col>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                <div className="mt-3 text-center">
                                    {( loading   == true) ? <Loader /> :<> </>}
                                </div>
                            </Col>
                        </Row> 
                        <Row className="p-2">
                           <Col xxl={4} xl={4} md={4} sm={4} xs={12}>
                                <div className="table-responsive procedurelist-table">
                                    <table className="table-bordered">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>未完了（件数）</th>
                                                <th>本月完了（件数）</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>受注手続き</td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(1);
                                                        }}>{order_proce["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(2);
                                                        }}>{order_proce["finished"]}
                                                    </button>
                                                </td>                                               
                                            </tr>
                                            <tr>
                                                <td>発注手続き</td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(3);
                                                        }}>{purchase_proce["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(4);
                                                        }}>{purchase_proce["finished"]}
                                                    </button>
                                                </td>    
                                            </tr>
                                            <tr>
                                                <td>請求手続き</td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(5);
                                                        }}>{bill_proce["unfinished"]}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn_standard procedure_count ml_20 btn-sm" onClick={() => {
                                                        proceCount(6);
                                                        }}>{bill_proce["finished"]}
                                                    </button>
                                                </td>    
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                           </Col>
                           <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                              <Row>
                                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                    <label htmlFor="sort" className="control-label">対象月</label>
                                    <select className="filter" name="expect_start_month"  value={monthflg} aria-label=".form-select-sm example" onChange={onSelect}>
                                            {monthList.map(month => {
                                                return(<option value={month}> {month}月</option>)
                                            })
                                        }
                                    </select>  
                                    <label htmlFor="sort" className="control-label">期間</label>
                                    <select className="filter" name="expect_end_date" value={yearflg} aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="1">直近1年間</option>
                                        <option value="2">直近2年間</option>
                                        <option value="3">直近3年間</option>
                                    </select>                   
                                </Col>
                              </Row>
                              <Row>
                                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                        <LineChart chartData={chartData} />
                                    </Col>
                                </Row>
                           </Col>
                        </Row>
                       
                        <Row className="p-2">
                            <Col  xxl={12} xl={12} md={12} sm={12} xs={12} className="check-list">
                                <label htmlFor="sort" className="control-label">受注状況</label>
                                <select className="filter" name="order_status" aria-label=".form-select-sm example" onChange={onSelect} value={order_status}>
                                        {orderStatusList.map(order_status => {
                                            return(<option value={order_status[0]}> {order_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">発注状況</label>
                                <select className="filter" name="purchase_status" aria-label=".form-select-sm example" onChange={onSelect} value={purchase_status}>
                                        {purchaseStatusList.map(purchase_status => {
                                            return(<option value={purchase_status[0]}> {purchase_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">請求状況</label>
                                <select className="filter" name="filter_bill_status" aria-label=".form-select-sm example" onChange={onSelect} value={filter_bill_status}>
                                        {filterBillStatusList.map(filter_bill_status => {
                                            return(<option value={filter_bill_status[0]}> {filter_bill_status[1]}</option>)
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">稼働</label>
                                <select className="filter" name="share_memo_filter" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">選択なし</option>
                                    <option value="FloBoard">FloBoard</option>
                                    <option value="FloNet">FloNet</option>              
                                </select>
                                <label htmlFor="sort" className="control-label">所属</label>
                                <select className="filter" name="belongs_filter" aria-label=".form-select-sm example" onChange={onSelect}>
                                    <option value="">選択なし</option>
                                    <option value="社員">社員</option>
                                    <option value="個人">個人</option>     
                                    <option value="他社">他社</option>                                                                   
                                </select>
                                <label htmlFor="sort" className="control-label">対象月</label>
                                <DatePicker
                                    value={(selectedTargetMonth != null)?selectedTargetMonth: ""}
                                    // value={(target_months != null)?target_months: ""}
                                    dateFormat="yyyy-MM"
                                    locale="ja"
                                    selected={selectedTargetMonth} 
                                    onChange={date =>
                                        handleDateChange(date, "target_months")
                                    }
                                    name="target_months"
                                    isClearable
                                    showMonthYearPicker
                                    // peekNextMonth
                                    // showMonthDropdown
                                    // showYearDropdown
                                    // dropdownMode="select"
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    />
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="check-list">                             
                                <label htmlFor="sort" className="control-label">取引先</label>
                                <select className="filter" name="supplier_filter" aria-label=".form-select-sm example" onChange={onSelect} value={supplier_filter}>
                                    <option value="">選択なし</option>
                                        {Object.keys(supplier_list).map((key, value) => {
                                            if (value !== null){
                                                return(<option value={[key]}> {supplier_list[key]}</option>)
                                            }
                                        })
                                    }
                                </select>
                                <label htmlFor="sort" className="control-label">所属会社</label>
                                <select className="filter" name="affiliation_company_filter" aria-label=".form-select-sm example" onChange={onSelect} value={affiliation_company_filter}>
                                    <option value="">選択なし</option>
                                    {Object.keys(affiliation_company_list).map((key, value) => {
                                            if (value !== null){
                                                return(<option value={[key]}> {affiliation_company_list[key]}</option>)
                                            }
                                        })
                                    }
                                </select>
                                {
                                    (() => {
                                        if(login_user.role !=5) {
                                        return(
                                        <React.Fragment>
                                            <input className="ml_20" type="checkbox" value="" id="companyInfo" name ="companyInfo" checked={companyInfoChecked} onChange={handleChange} /> 
                                            <label htmlFor="companyInfo" className="control-label">所属・取引先連絡先表示</label>
                                            <input className="ml_20" type="checkbox" value="" id="billInfo" name ="billInfo" checked={billInfoChecked} onChange={handleChange} /> 
                                            <label htmlFor="billInfo" className="control-label">請求情報表示 </label>
                                            <input className="ml_20" type="checkbox" value="" id="orderInfo" name ="orderInfo" checked={orderInfoChecked} onChange={handleChange} /> 
                                            <label htmlFor="orderInfo" className="control-label">受発注情報非表示 </label>
                                            <button type="button" className="btn_danger ml_20 btn-sm" onClick={nextMonthData}>翌月稼働テーブル作成
                                            </button>
                                            <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteProcedure}>削除
                                             </button>
                                        </React.Fragment>
                                        )
                                        }
                                    })()
                                }
                                {/* <button type="button" className="btn_danger ml_20 btn-sm" onClick={nextMonthData}>翌月稼働テーブル作成
                                </button> */}
                           
                            </Col>
                        </Row>
                        
                        <Row className="p-2">
                            <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                            </Col>
                            <Col xxl={8} xl={8} md={8} sm={8} xs={6} className="filter_select">
                                <div className="float-right">
                                    <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>                                   
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                            <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                            </button>
                                            <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                                <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                    </button> 
                                </div>
                            </Col>
                        </Row>
                                <div className='datatable procedure-freeze-header procedure-freeze-cell procedure-frozen-columns xs-hidden'>
                                    {/* <ThemeProvider> */}
                                        <MUIDataTable
                                            title={''}
                                            data={procedures}
                                            columns={columnVisible}
                                            options = {options}
                                        />
                                    {/* </ThemeProvider> */}
                                </div>
                                <div className='datatable mobile pc-hidden'>
                                    {/* <ThemeProvider> */}
                                        <MUIDataTable
                                            title={''}
                                            data={procedures}
                                            columns={columnVisible}
                                            options = {options}
                                        />
                                    {/* </ThemeProvider> */}
                                </div>
                </Container>

         
                <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={PCModalStyle}>
             {edit_modal_box}
            </Box>
        </Modal>  

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
             {edit_modal_box}
            </Box>
        </Modal>                
        
        </React.Fragment>
    );
};
export default ProcedureList;