import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

class PersonService {
    list(filter_list:any,status:any, belong_type:string, info_type:string, creator:any,perPage:number, page:number,search_keywords:any, skill_list:any, sort:any, column:any, order:any, search_skill:any){
        // let dataURL:string = API_URL + `/test?assign_status=${assign_status}&proposable_flg=${proposable_flg}&belong_type=${belong_type}&info_type=${info_type}&creator=${creator}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}`;
        let dataURL:string = API_URL + `/person/list?filter_list=${filter_list}&status=${status}&belong_type=${belong_type}&info_type=${info_type}&creator=${creator}&list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}&search_skill=${search_skill}`;
        console.log(dataURL);
        if(skill_list.length > 0){
            for (let i = 0; i < skill_list.length; i++) {
                dataURL += `&skill_list[]=${skill_list[i]}`;
           } 
        }
        return axios.get(dataURL,header);
    }

    index(filter_list:any, status:any, skill_list:[],search:any,search_skill:any,sort:any){
        let dataURL:string = API_URL + `/persons/info?filter_list=${filter_list}&status=${status}&search_keywords=${search}&search_skill=${search_skill}&sort=${sort}`;
        if(skill_list.length > 0){
            for (let i = 0; i < skill_list.length; i++) {
                dataURL += `&skill_list[]=${skill_list[i]}`;
           } 
        }
        return axios.get(dataURL, header);
    }

    resource(filename:any){
        let dataURL:string = API_URL + `/resource?filename=photo_path/1592643957.png`;
        return axios.get(dataURL, header);
    }

    create() {
        let dataURL:string = API_URL + "/persons/create1";
        return axios.get(dataURL, header);
    }

    store(data:any) {
        let dataURL:string = API_URL + "/persons/store1";
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
        // return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` ,'server_url' : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} });
    }

    show(encode:any){
        let dataURL:string = API_URL + "/persons/show/"+encode;
        return axios.get(dataURL, header);
    }

    edit(id:any){
        let dataURL:string = API_URL + "/persons/"+id+"/edit";
        return axios.get(dataURL, header);
    }

    update(data:any ,id:any) {
        console.log(data);
        let dataURL:string = API_URL + "/person/update1/"+id;
        return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' } });
        // return axios.post(dataURL,data, { headers: { 'Authorization': `Bearer ${token}` ,'server_url' : `${SERVER_URL}`, 'Content-Type': 'multipart/form-data' } });
    }
    
    delete(ids:any) {
        let dataURL:string = API_URL + "/person/list/destroy?ids[]="+ids;
        return axios.delete(dataURL, header);
    }

    destroy(id:any){
        let dataURL:string = API_URL + "/persons/delete/"+id;
        console.log(dataURL);
        return axios.get(dataURL, header);
    }

    personlist(data:any) {
        console.log(data);
        let dataURL:string = API_URL + "/personlist";
        return axios.post(dataURL,data, header);
    }

    createByPerson(person_id:any) {
        console.log(person_id);
        let dataURL:string = API_URL + "/assignment/create-by-person/"+person_id;
        return axios.get(dataURL, header);
    }
    assignmentByPerson(case_ids:any, person_id:any) {
        let dataURL:string = API_URL +"/assignment/store";
        let data = {case_ids:case_ids,person_id:person_id,create_type:  "person_cases"};
        // data.cases_ids = case_ids;
        // data.person_id = person_id;
        // data.case_type = "person_cases";
        return axios.post(dataURL,data, header);
    }

    getBulkMailData(data:any){
        let dataURL:string = API_URL +"/person/list/getData";
        return axios.post(dataURL,data, header);
    }

    bulkMailsend(data:any){
        let dataURL:string =  API_URL +"/person/bulk/email/send1";
        return axios.post(dataURL,data, header);
    }

    customerMailsend(data:any){
        let dataURL:string =  API_URL +"/person/email/customer/send1";
        return axios.post(dataURL,data, header);
    }

    oneClickMailSend(data:any){
        let dataURL:string =  API_URL +"/person/one/click/mail/send1";
        return axios.post(dataURL,data, header);
    }

    contactMailSend(data:any){
        let dataURL:string = API_URL +"/person/email/send1";
        return axios.post(dataURL,data,header);
    }

    copy(id:any){
        let dataURL:string = API_URL +"/persons/copy/" +id;
        return axios.get(dataURL,header);
    }

    jishaSearch(fields:any,value:any,flg:any){
        let data = {};
        data["fields"] = fields;
        data["value"] = value;
        data["flg"] = flg;
        let dataURL:string = API_URL +"/persons/jisha/search1";
        return axios.post(dataURL,data, header);
    }

    deleteList(data:any) {
        const token = localStorage.getItem('token');
        let dataURL:string = API_URL +'/persons/list/destroy1';
        return axios.post(dataURL,data, header);
    }
}

export default new PersonService();