import axios from "axios";
import { myAxios } from "../lib/utils";
myAxios(axios);
const RESOURCE_URL = process.env.REACT_APP_RESOURCE_URL;
const RESOURCE_DOWNLOAD_URL = process.env.REACT_APP_RESOURCE_DOWNLOAD_URL;
const RESOURCE_API_UPLOAD_URL = process.env.REACT_APP_RESOURCE_API_UPLOAD_URL;
const RESOURCE_API_FILE_INFO_URL = process.env.REACT_APP_RESOURCE_API_FILE_INFO_URL;
const RESOURCE_API_FILE_BASE64_URL = process.env.REACT_APP_RESOURCE_API_FILE_BASE64_URL;
const token = localStorage.getItem("token");
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class ResourceService {

    download(filePath:string, fileName:string = '') :string {
        return `${RESOURCE_DOWNLOAD_URL}/${filePath}?fileName=${fileName}`;
    }

    url(filePath:string):string {
        return `${RESOURCE_URL}/${filePath}`;
    }

    apiFileInfo(filePath:string) {
        return axios.post(
            RESOURCE_API_FILE_INFO_URL!,
            {
                filePath: filePath
            },
            { headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} }
        );
    }

    apiFileBase64(filePath:string) {
        return axios.post(
            RESOURCE_API_FILE_BASE64_URL!,
            {
                filePath: filePath,
                fileBase64: "1"
            },
            { headers: { 'Authorization': `Bearer ${token}` ,'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} }
        );
    }
    apiUpload(file:any, savePath:string = '', fileName:string)  {
        return axios.post(
            RESOURCE_API_UPLOAD_URL!,
            {
                file: file,
                fileName: fileName,
                savePath: savePath
            }, 
            { 
                headers: { 'Authorization': `Bearer ${token}` , 'Content-Type': 'multipart/form-data' , "Accept": "application/json","type": "formData"} 
            }).catch((err)=>{
                console.log(err);
            });
    }

}
export default new ResourceService();