import Dropdown from 'react-bootstrap/Dropdown';
import AuthService from "../../services/AuthService";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import React, {useState , useEffect} from 'react';
import {UserModel} from "../../models/IUser";
import UserService from "../../services/UserService";
import ResourceService from "../../services/ResourceService";
import ResourceImg from '../../components/common/ResourceImg';
import $ from 'jquery';
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";

interface IState{
  user_model : UserModel;
}

interface IProps{}

let DropDownList:React.FC<IProps> = () =>{

// const DropDownList = () => {
 
  const logout =  function(e:any){
    AuthService.logout();
    setTimeout(()=>{
        window.location.href = "/";
    }, 1000*5);
  }
  const userStr = localStorage.getItem("user"); 
  let username  = ""; // retrieve stored user information from local storage
  if (userStr){
    const user = JSON.parse(userStr);
    username = user.name_jp;
  } 

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false); 
  let [show_profile, setShowProfile] = useState("none");
  let [drop_profile, setDropProfile] = useState("block");
  let [photo_name, setPhotoName] = useState("");

//   const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 800,
//     height: 700,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };
  const login_user =   JSON.parse(localStorage.getItem("user") || '{}');

  let [state, setState] = useState<IState>({
    user_model : {
        user_id : login_user.user_id,
        name_jp : login_user.name_jp,
        kana_name : login_user.kana_name,
        email : login_user.email,
        photo : login_user.photo,
        password : login_user.password,
        tel_no : login_user.tel_no,
        privilege_grp_cd : login_user.privilege_grp_cd,
        company : login_user.company,
        dept_name : login_user.dept_name,
        position : login_user.position,
        respond_area : login_user.respond_area,
        role : login_user.role,
        admission_date : login_user.admission_date,
        withdraw_date : login_user.withdraw_date,
        withdraw_reason : login_user.withdraw_reason,
        memo : login_user.reason
    }
});
let { user_model } = state;

  const showProfile = function(){
    UserService.showUser(login_user.user_id).then((response) => {  
      setState({
        ...state,
        user_model : {
          user_id : response.data.user_id,
          name_jp : response.data.name_jp,
          kana_name : response.data.kana_name,
          email : response.data.email,
          photo : response.data.photo,
          password : response.data.password,
          tel_no : response.data.tel_no,
          privilege_grp_cd : response.data.privilege_grp_cd,
          company : response.data.company,
          dept_name : response.data.dept_name,
          position : response.data.position,
          respond_area : response.data.respond_area,
          role : response.data.role,
          admission_date : response.data.admission_date,
          withdraw_date : response.data.withdraw_date,
          withdraw_reason : response.data.withdraw_reason,
          memo : response.data.reason
      }
    });  

    if(response.data.photo != null){
      setShowProfile("block");
      setDropProfile("none");
    }
    setOpen(true);
    }).catch((error) => {
      console.log(error);

    })
   
     
  }

  const storeProfile = function (e:any) {
    e.preventDefault();

    UserService.updateProfile(state.user_model , photo_name).then((response) => {  
    //   localStorage.getItem("user")
      if(response.data.success){
        alert("プロファイル内容を更新しました。");
        localStorage.setItem("user", JSON.stringify(response.data.info));
        setOpen(false);
      }
    }).catch((error) => {
      if(error.response.status == 403){
        alert(error.response.data.message);
        }
        if(error.response.data){
            showErrorInfo(error.response.data);
        }
    })
  }

  const upload_profile = function (e:any) {
    e.preventDefault();

  }

  const profileExplorer = function (e:any) {
    e.preventDefault();
    $('#selectprofile').click();
  }
 const changeProfile = function(e:any) {
  let file_name = e.target.files[0].name;
    profile_upload(e.target.files[0],file_name);
    e.target.value = null;
};
 const profile_upload = function(file_obj:any, name:any) {
    ResourceService.apiUpload(file_obj, 'profiles/', name).then((res:any) => {
    // let name = res.info[0];
    // let file_type = res.info[1];
    // let txt = `<img src=${ResourceService.url('HD1.jpg')} alt=''/>`;
    // <img src={(persons_arr['photo_path'] != null && persons_arr['photo_path'] != '')?ResourceService.url(`${persons_arr['photo_path']}`):'../images/businessman.jpg'} className="img-circle" alt="icons" />

    // let txt = "<img src='"+profile_route+"/"+name+"' id='upload_img_0' alt='"+name+"' style='width:10%;margin:10px'>";
    // $(".img-content").show().empty().html(txt);
    // $(".uploaded_profile").val(name);
    // $(".drop_profile").hide();
    setShowProfile("block");
    setDropProfile("none");
    setPhotoName(name);
    localStorage.setItem('profile_image', name);
    $(".img-content img").attr("src" , ResourceService.url(`profiles/${name}`))
  });
  // if(file_obj.size > 1000000) {
  //    alert("Please upload file less than 1MB. Thanks!!");
  //  }else{
  //      if(file_obj != undefined) {
  //         var form_data = new FormData();   
  //         form_data.append("profile", file_obj);    
  //         let url = "<?php echo route('api.user.profileupload'); ?>";
  //         return storeToDb(url, form_data, function(result){
  //             let name = result.info[0];
  //             let file_type = result.info[1];
  //             let txt = "<img src='"+profile_route+"/"+name+"' id='upload_img_0' alt='"+name+"' style='width:10%;margin:10px'>";
  //             $(".img-content").show().empty().html(txt);
  //             $(".uploaded_profile").val(name);
  //             $(".drop_profile").hide();
  //         });
  //     }
  //  }
}

  let editProfile = function(e:any) {
    e.preventDefault();
    setShowProfile("none");
    setDropProfile("block");
  }

  let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
    setState({
        ...state,
        user_model : {
            ...state.user_model,
            [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
        }
    });   
};

const profile_modal = (
    <div>
         <div className="row">
            <div className="col-6">
                <h3>プロファイル変更</h3>
            </div>
            <div className="col-6">
                    <button type="button" className="modal_close" onClick={handleClose}>X</button>
            </div>            
        </div>
        <form className="form">
            <input type="hidden" name="user_id" value={user_model["user_id"]} />
                <div className="row">
                <div className="col-12 input-box">
                    <input type="hidden" name="photo" value={user_model['photo']} className="uploaded_profile" />
                        <div className="img-content show_profile" style={{display: show_profile }}>
                            <button className="edit_btn" onClick={editProfile}><i className="fas fa-edit"></i></button>
                            <ResourceImg remoteSrc={(localStorage.getItem('profile_image') != '')?`profiles/${localStorage.getItem('profile_image')}`: ''} src={'images/businessman.jpg'}  className="img-circle" alt="icons"  />
                        </div>
                        
                        <div id="drop_file_zone" className="drop_profile" style={{display: drop_profile }} data-id="1" onDrop={upload_profile}>
                            <div id="drag_upload_file">
                                <p>顔写真をドラッグしてください。</p>
                                <p>または</p>
                                <p><input type="button" value="顔写真を選択してください。" onClick={profileExplorer} /></p>
                                <input type="file" name="profile" id="selectprofile"  onChange={changeProfile} />
                            </div>
                        </div>
                </div>
                </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group ">
                        <label htmlFor="exampleInputEmail1">アカウントID　（メールアドレス）:</label> 
                        <input type="text" className="form-control" name="email"  value={user_model['email']}  onChange={updateInput} />
                    </div>       
                </div>
            </div>                  
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">漢字氏名:</label> 
                        <input type="text" className="form-control" name="name_jp"  value={user_model['name_jp']}  onChange={updateInput} />
                    </div>          
                </div>                      
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">カタカナ名:</label> 
                        <input type="text" className="form-control" name="kana_name"  value={user_model['kana_name']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">パスワード:</label> 
                        <input type="password"  placeholder="******" className="form-control" name="password"  value={user_model['password']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">電話番号:</label> 
                        <input type="text" className="form-control" name="tel_no"  value={user_model['tel_no']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">会社名:</label> 
                        <input type="text" className="form-control" name="company"  value={user_model['company']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">部門名:</label> 
                        <input type="text" className="form-control" name="dept_name"  value={user_model['dept_name']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">担当エリア:</label> 
                        <input type="text" className="form-control" name="respond_area"  value={user_model['respond_area']}  onChange={updateInput} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="form-group mt-5 text-center">
                    <button onClick={storeProfile} className="button submit-btn btn_standard">更新</button>
                    <button type="button" className="modal_cancel" onClick={handleClose}>取消</button>

                </div>
            </div>
        </form>
                
    </div>
)
  return (
    <div className='personal-info'>
    <div className=''>
        <Dropdown>
            <Dropdown.Toggle>
                <a className="nav-link d-flex align-items-center" href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false">
                        <span className="p-2">  {username}</span>
                        <ResourceImg remoteSrc={(localStorage.getItem('profile_image') != '')?`profiles/${localStorage.getItem('profile_image')}`:''} src={'images/businessman.jpg'} className="rounded-circle" height="30" width="30" alt="icons"  />
                        
                </a>
            </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={showProfile}>
                        プロフィール変更
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={logout}>
                        ログアウト
                    </Dropdown.Item>
                </Dropdown.Menu>
        </Dropdown>
      
    </div>

    <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{display: { xs: 'none', sm: 'block' }}} // Ensure the modal is on top
        >                                
            <Box sx={PCModalStyle}>
                {profile_modal}
            </Box>
        </Modal>  

         <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ display: { xs: 'block', sm: 'none' }}} // Ensure the modal is on top
        >                                
            <Box sx={MobileModalStyle}>
                {profile_modal}
            </Box>
        </Modal>   
    </div>
  );
};

export default DropDownList;