import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
       } 
};
class PartnerService {
    list(par_type:any, partner_type:any, perPage:number, page:number,search_keywords:any, sort:any, column:string, order:string,url_partner_id:any){
        let dataURL:string = API_URL + `/partners/list/${par_type}?list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}&id=${url_partner_id}`;

        if(partner_type != null && partner_type != 0){
            dataURL += `&partner_type=${partner_type}`;
        }
        return axios.get(dataURL, header);
    }

   search(fields:any,value:any,flg:any){
        let data = {};
        data["fields"] = fields;
        data["value"] = value;
        data["flg"] = flg;
        let dataURL:string = API_URL +`/partner/search1`;
        return axios.post(dataURL,data, header);
    }

    showPartner(partner_id:any ,type:any){
        let dataURL:string = API_URL +`/partners/detail/${partner_id}`;
        return axios.get(dataURL, header);
    }

    update(data:any ,type:any) {
        let dataURL:string = API_URL + `/partners/update1/${type}`;
        return axios.put(dataURL,data, header);
    }

    store(data:any ,type:any) {
        let dataURL:string = API_URL + `/partners/store1/${type}`;
        return axios.post(dataURL,data, header);
    }

    delete(data:any, type:any){
        let dataURL:string = API_URL + `/partner/list/destroy1/${type}`;
        return axios.post(dataURL,data, header);
    }

}

export default new PartnerService();