import React, {useEffect, useState} from 'react';
import ProspectService from "../../services/ProspectService";
import PartnerService from "../../services/PartnerService";
import {CaseListService} from "../../services/CaseService";

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import {showErrorInfo} from "../../components/common/Helpers";

import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DetailTopbar from "../../components/common/DetailTopbar";
import {ProspectModel} from "../../models/Prospect";
import DatePicker from "react-datepicker";
import {useParams, Link} from "react-router-dom";
import $ from 'jquery';

interface URLParam {
    id : string;
}

interface IState{
    prospect : ProspectModel;
    staff_list : [];
}
interface IProps{}
let ProspectCreate:React.FC<IProps> = () => {
    let {id} = useParams<URLParam | any>();
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');

    let [state , setState] = useState<IState>({
        prospect : {
            partner_id: "",
            partner_type : "1",
            prospect_staff_id : login_user.user_id,
            company : "",
            department : "",
            familyname : "",
            firstname : "",
            position : "",
            contact_email : "",
            contact_tel : "",
            mobilenumber : "",
            fax : "",
            prospectdatasource : "",
            industry : "",
            url : "",
            employeenumb : "",
            information : "",
            mail_flg : "0",
            prospect_status : "0",
            startday : "",
            evaluation : "",
            upgradeflg : "0",
            social_id_social_login : "",
            casemail : "",
            annual_sales : "",
            personmail : "",
            post : "",
            address : "",
            remarks : ""
        },
        staff_list : []
    });

    const [startDate, setStartDate] = useState(new Date());
   

    useEffect(()=>{
        if(id != undefined){
            ProspectService.edit(id).then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    prospect:response.data.prospect,
                    staff_list:response.data.staff_list,
                })

            }).catch((error) => {
                console.log(error);
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }else{
            ProspectService.create().then((response)=>{
                console.log(response);
                setState({
                    ...state,
                    staff_list:response.data.staff_list,
                })
            }).catch((error) => {
                console.log(error);
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            })
        }
       

    }, [id]);
    let {prospect ,staff_list} = state;



    const store = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();
        if(id != undefined){
            ProspectService.update(state.prospect, id).then((response) => {
                // window.history.replaceState(null, "", "/prospect/list");
                window.location.href = "/prospect/list";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }else{
            ProspectService.store(state.prospect).then((response) => {
                window.location.href = "/prospect/list";
            }).catch((error) => {
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }

            })
        }
    };
  
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            prospect : {
                ...state.prospect,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            staff_list : staff_list
        });        
    };

    let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            prospect : {
                ...state.prospect,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            staff_list : staff_list
        });
    }
    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            prospect : {
                ...state.prospect,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            },
            staff_list : staff_list
        });
     };
    
    
 
    const [selectedStartDate, setSelectedStartDate] = useState(null);

    const handleDateChange = function(date:any, column_name:any){
    let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()) : "";
    // let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate());
        switch (column_name) {
            case "startday":
                setSelectedStartDate(date);
                setState({
                    prospect : {
                        ...state.prospect,
                       startday : date // input name 'username' set value 'event target value'
                    },
                    staff_list : staff_list
                });
                break;
            default:
                break;
        }

    }

  console.log("ID" + id);
    return(
        <React.Fragment>
            <DetailTopbar />
            <div className="container">
                <div className = "row">
                        <h3 className='header-left-design'>見込み客情報{(id !== undefined) ?"編集":"登録"}</h3>
                </div>
                <div className="row anken-detail">
                <form className="form create-form " onSubmit={store}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">パートナーID</span>
                                    <input type="text" name="partner_id" id="partner_id" className="form-control" aria-label="partner_id" aria-describedby="basic-addon2" value={state.prospect.partner_id} onChange={updateInput}/>
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">パートナー種別</span>
                                    <select name="partner_type" className="input-box form-control" value={state.prospect.partner_type} onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value="0"> 未設定</option>
                                        <option value="1"> 甲</option>
                                        <option value="2"> 乙</option>
                                        <option value="3"> 両方</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 input-box col-xs-12">
                                    <span id='text' className="input-required">見込み客の担当者ID</span>
                                    <select name="prospect_staff_id" className="input-box form-control" value={(state.prospect.prospect_staff_id != "")?state.prospect.prospect_staff_id:""} onChange={onSelect} aria-label=".form-select-sm example">
                                        <option value="" hidden> 選択なし</option>
                                        {staff_list.map(staff => {
                                                if (staff !== null){
                                                    return(<option value={staff['user_id']}> {staff['name_jp']}</option>)
                                                }
                                            })
                                        }
                                    </select> 
                                </div>
                                <div className="col-md-6 input-box col-xs-12">
                                <span id='text' className="input-required">会社名</span>
                                    <input type="text" name="company" id="company" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.prospect.company} onChange={updateInput}/>
                                </div>
                            </div>
    
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text' >部門名</span>
                                    <input type="text" name="department" id="department" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.prospect.department} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>職位</span>
                                    <input type="text" name="position" id="position" className="form-control" aria-label="title" aria-describedby="basic-addon2" value={state.prospect.position} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text' className="input-required">姓</span>
                                    <input type="text" name="familyname" id="familyname" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.familyname} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text' className="input-required">名</span>
                                    <input type="text" name="firstname" id="firstname" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.firstname} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text' className="input-required">メール</span>
                                    <input type="text" name="contact_email" id="contact_email" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.contact_email} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>電話番号</span>
                                    <input type="text" name="contact_tel" id="contact_tel" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.contact_tel} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>携帯電話</span>
                                    <input type="text" name="mobilenumber" id="mobilenumber" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.mobilenumber} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>FAX</span>
                                    <input type="text" name="fax" id="fax" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.fax} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>見込み客のデータ元</span>
                                    <input type="text" name="prospectdatasource" id="prospectdatasource" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.prospectdatasource} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>業界</span>
                                    <input type="text" name="industry" id="industry" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.industry} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>ホームページ</span>
                                    <input type="text" name="url" id="url" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.url} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>従業員数</span>
                                    <input type="text" name="employeenumb" id="employeenumb" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.employeenumb} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>年間売上</span>
                                    <input type="text" name="annual_sales" id="annual_sales" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.annual_sales} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>メール送信フラグ</span>
                                    <select name="mail_flg" id="mail_flg" className="input-box form-control" aria-label="form-select-sm example" value={state.prospect.mail_flg} onChange={onSelect}>
                                        <option value="0"> 設定なし</option>
                                        <option value="1"> 案件送付可</option>
                                        <option value="2"> 要員送付可</option>
                                        <option value="3"> 両方送付可</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 input-box">
                                    <span id='text'>詳細情報（文字数800まで）</span>
                                    <textarea className="form-control" rows={3} name = "information" id= "information" aria-label="With textarea" value={state.prospect.information} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>見込み客ステータス</span>
                                    <select name="prospect_status" id="prospect_status" className="input-box form-control" aria-label="form-select-sm example" value={state.prospect.prospect_status} onChange={onSelect}>
                                        <option value="0"> 問い合わせ中</option>
                                        <option value="1"> 協業開始</option>
                                        <option value="2"> 協業中止</option>
                                        <option value="3"> 成約後</option>
                                        <option value="4"> 契約後</option>
                                    </select>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>接触開始日</span>
                                    <DatePicker 
                                            value={(state.prospect.startday != null)?state.prospect.startday: ""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedStartDate} 
                                            onChange={date =>
                                                handleDateChange(date, "startday")
                                            }
                                            name="startday"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            />
                                </div> 
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 col-xs-12 input-box">
                                    <span id='text'>評価</span>
                                    <input type="text" name="evaluation" id="evaluation" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.evaluation} onChange={updateInput}/>
                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <span id='text'>アップグレードフラグ</span>
                                    <select name="upgradeflg" id="upgradeflg" className="input-box form-control" aria-label="form-select-sm example" value={state.prospect.upgradeflg} onChange={onSelect}>
                                        <option value="0"> 設定なし</option>
                                        <option value="1"> 取引先のマスタに「甲方」または「甲方その他」のデータがあり</option>
                                        <option value="2"> 取引先のマスタに「乙方」または「乙方その他」のデータがあり</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>ソーシャルログイン</span>
                                    <input type="text" name="social_id_social_login" id="social_id_social_login" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.social_id_social_login} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>メールアドレス（案件提案先）</span>
                                    <input type="text" name="casemail" id="casemail" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.casemail} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                <span id='text'>メールアドレス（要員提案先）</span>
                                    <input type="text" name="personmail" id="personmail" className="form-control" aria-label="phone" aria-describedby="basic-addon2" value={state.prospect.personmail} onChange={updateInput}/>
                                </div>
                                <div className="col-6 input-box">
                                    <span id='text'>郵便番号</span>
                                    <input type="text" name="post" id="post" className="form-control" aria-label="firstname" aria-describedby="basic-addon2" value={state.prospect.post} onChange={updateInput}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6 input-box">
                                    <span id='text'>特記事項（文字数500まで）</span>
                                    <textarea className="form-control" rows={3} name = "remarks" id= "remarks" aria-label="With textarea" value={state.prospect.remarks} onChange={updateTextArea}></textarea>
                                </div>
                                <div className="col-6 input-box">
                                <span id='text'>住所</span>
                                    <textarea className="form-control" rows={3} name = "address" id= "address" aria-label="With textarea" value={state.prospect.address} onChange={updateTextArea}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <button className="btn btn_standard" id="case_create_submit" type="submit"> {(id !== undefined) ?"更新":"登録"}</button>
                            <Link to={`/prospect/list`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                            
                        </div>
                    </div>
                    </form>
                </div>
                
            </div>
        </React.Fragment>
    );
};
export default ProspectCreate;