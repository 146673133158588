import axios from "axios";

export const myAxios = (resetAxios: any) => {

    if(resetAxios?.interceptors) {
        axios.interceptors.request.use((config) => {
            if(config.headers){
                config.headers["RESOURCE_SESSION"] = localStorage.getItem("rs")??'';
            }
            return config; 
        },
        (error) => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            return response; 
        },
        (error) => {
            return Promise.reject(error);
        });
    }
};