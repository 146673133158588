import React ,{useState , useEffect} from "react";
import { Base } from "../../models/Person";
import PersonService from "../../services/PersonService";
import ResourceService from "../../services/ResourceService";
import {Link} from "react-router-dom";
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Navigate, useLocation} from "react-router-dom";
import ResourceImg from '../../components/common/ResourceImg';
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    persons :  Base[];
    total : number;
    person_count : number;
    otsu_not_public_count : number;
    otsu_public_count : number;
    errorMessage : string;
}
interface IProps{}

let Persons:React.FC<IProps> = () =>{
    let [state, setState] = useState<IState>({
        loading : false,
        persons :  [] as Base[],
        total : 0,
        person_count : 0,
        otsu_not_public_count : 0,
        otsu_public_count : 0,
        errorMessage : "",
    });

    const result : string[] = ['status'];
    const [filter_list, setFilterList] = useState(result);  
    const [status, setStatus] = useState("available");  
    const skill : string[] = [];
    const [skill_list, setSkillList] = useState(skill);
    const [searchskill, setSearchSkill] = useState(""); 
    const [search, setSearch] = useState("");  
    const [active, setActive] = useState("available");  
    const [filename, setFilename] = useState("");
    const [sort, setSort] = useState("0");
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');

    useEffect(()=>{
        setState({...state, loading: true});
        localStorage.removeItem("person_list_status");
        localStorage.removeItem("person_list_search");
        localStorage.removeItem("case_status");
        localStorage.removeItem("case_search");
        localStorage.removeItem("case_list_status");
        localStorage.removeItem("case_list_search");

        if(localStorage.getItem("person_status") != null && localStorage.getItem("person_search") != null){
            setActive(localStorage.getItem("person_status")!);
            setStatus(localStorage.getItem("person_status")!);
            setSearch(localStorage.getItem("person_search")!);
            alert(localStorage.getItem("person_search"));

            getData(filter_list, localStorage.getItem("person_status")!, skill_list,localStorage.getItem("person_search")!,searchskill,sort);
        }else if(localStorage.getItem("person_status") != null ){
            setActive(localStorage.getItem("person_status")!);
            setStatus(localStorage.getItem("person_status")!);
            getData(filter_list, localStorage.getItem("person_status")!, skill_list,search,searchskill,sort);
        }else if(localStorage.getItem("person_search") != null){
            setSearch(localStorage.getItem("person_search")!);  
            getData(filter_list, status, skill_list,localStorage.getItem("person_search")!,searchskill,sort);
        }else{
            getData(filter_list, status, skill_list,search,searchskill,sort);
        }


        if(localStorage.getItem("person_status") != null){
            setActive(localStorage.getItem("person_status")!);
            setStatus(localStorage.getItem("person_status")!);
            getData(filter_list, localStorage.getItem("person_status")!, skill_list,search,searchskill,sort);
        }else{
            getData(filter_list, status, skill_list,search,searchskill,sort);
        }

        //getImage(filename);
        // fetchImage();

    }, []);

    const getData = async (filter_list:any, status:any, skill_list:any,search:any,search_skill:any,sort:any) => {
        setState({...state,loading :true});
        PersonService.index(filter_list, status, skill_list,search,search_skill,sort).then((response)=>{

            setState({
                ...state,
                loading:false,
                persons:response.data.data,
                total : response.data.total,
                person_count : response.data.person_count,
                otsu_not_public_count : response.data.otsu_not_public_count,
                otsu_public_count : response.data.otsu_public_count,
            })
           
        }).catch((error) => {

            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }

    const getImage =async (filename:any) => {
        PersonService.resource(filename).then((response)=>{
            console.log(response);
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            console.log(error);
        });
    }
   
    let {loading, persons, total,person_count,otsu_not_public_count, otsu_public_count, errorMessage} = state;
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    localStorage.setItem("person_search", e.target.value);
                    getData(filter_list, status, skill_list,e.target.value,searchskill,sort);
                break;
            case "search_skill":
                    setSearchSkill(e.target.value);
                    getData(filter_list, status, skill_list,search,e.target.value,sort);
                break;
            default:
                break;
        }
    };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "sort":
                setSort(e.target.value);
                getData(filter_list, status,skill_list,search,searchskill,e.target.value);
                break;
            default:
                break;
        }
     };

    
    let status_list :any[] = [];
    if(login_user.role ==3){
        status_list = [['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
           ['wait_result','結果待ち中']];
    }else if(login_user.role ==8){
        status_list = [['available','営業可'], ['not_start','未着手'],['proposaling', '提案中'],['interview','面談設定中'],
           ['wait_result','結果待ち中']];
    }else
    {
        status_list = [['all', 'すべて'] , ['unavailable','営業不可'], ['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
           ['wait_result','結果待ち中']];
    }
    // const status_list = [['all', 'すべて'] , ['unavailable','営業不可'], ['available','営業可'], ['not_start','未着手'],['assigning','割り当て中'],['proposaling', '提案中'],['interview','面談設定中'],
    //   ['wait_result','結果待ち中']];
    
    let status_list1 :any[] = [];
    if(login_user.role !=7 && login_user.role!=8){
        status_list1 = [['not_public','非公開']];
    }

    const status_list2 = [['now_available','即日稼働可'],['next_month_available','翌月稼働可'],['next_two_month_available','翌々月稼働可'],['release','退場予定'],['finish_release','退場済み']];
    
    let status_list3 :any[] = [];
    if(login_user.role == 4 || login_user.role == 9){
        status_list3 = [['unit_price_set','希望単金設定待ち']];
    }
    const handleStatus = function(status:any){
        //console.log("status "+ status);
        setActive(status);
        setStatus(status);
        localStorage.setItem("person_status", status);
        const filter_status = filter_list.filter((filter) => {
            return filter == "status";
        });
        if(filter_status.length == 0){
            filter_list.push("status");
        }
        getData(filter_list, status, skill_list,search,searchskill,sort);
    }

    const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        const filter_skill = filter_list.filter((filter) => {
            return filter == "skill_list";
        });
        if(filter_skill.length == 0){
            filter_list.push("skill_list");
        }

        if(isChecked === true){
            skill_list.push(value);
            //console.log("start");
            //console.log(skill_list);
            //console.log("end");
            setSkillList(skill_list);
            getData(filter_list, status, skill_list,search,searchskill,sort);
        }
        else{            
            // setSkillList(skill_list.filter((skill) => {
            //     return skill !== value;
            // }));
            const skill = skill_list.filter(function(skill){
                return skill != value; 
             });
             setSkillList(skill);
            getData(filter_list, status, skill,search,searchskill, sort);
        }
    }
    let downloadURL = process.env.REACT_APP_DOWNLOADURL;
    let imageUrl = "";
   
    let location = useLocation();
    const personDetail = function (ass_case:any, encode:any) {
        if(ass_case != null){
            localStorage.setItem('previousRoute', location.pathname);
            localStorage.setItem('rootRoute', location.pathname);
            window.location.href = `/persons/${encode}`;
        }
    }

    const goToAssignment = function (encode:any) {
        localStorage.setItem('previousRoute', location.pathname);
        localStorage.setItem('rootRoute', location.pathname);
        window.location.href = `/persons/assignment/${encode}`;
    }

    // if (!otsu_public_count || !otsu_not_public_count) {
    //     return null;
    // }
  
   console.log(persons);
    return(
       <React.Fragment>
           <AppBar
            position="fixed"
            sx={{
                width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                ml: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
            >
            <Toolbar>
                <Typography variant="h6">
                <header className="top-baner">
                    <Row>
                        <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                            <div id="search-autocomplete" className="form-outline">
                                <input type="text" name="search" id="search" value={search} placeholder = "検索(要員ID、イニシャル、漢字氏名、カタカナ氏名、英語氏名、国籍、最寄駅、特記事項を検索)"
                                    className="form-control"  onChange={onChange}/>
                            </div>
                        </Col>
                        <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                            <DropDownList />
                        </Col>
                    </Row>
                </header>
                </Typography>
            </Toolbar>
            </AppBar>
            <Container>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                        <h3 className='header-left-design'>要員情報</h3>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="float-end">
                        <div className="mt-4">
                            {
                            (() => {
                                if(login_user.role ==3 || login_user.role == 4|| login_user.role == 7 ||login_user.role == 9) {
                                return(<Link to={`/persons/create`} className="btn_add">
                                要員追加
                                </Link>)
                                }
                            })()
                            }
                        </div>
                    </Col>
                </Row> 
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <ul className="status-menu responsive-list filter" data-filter="status">
                        {status_list.map(status => (
                            <li className= {"list-item " +((status[0] == active)?active:"")}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}
                            </a>
                            </li>
                        ))}
                        {status_list1.map(status => (
                            <li className={"list-item " +((status[0] == active)?active:"")}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}
                            </a>
                            </li>
                        ))}
                        
                        {
                            (() => {
                                if(login_user.role !=7 && login_user.role!=8) {
                                    return( <li className={"list-item " +((('otsu_not_public' == active)?active:"") + ((otsu_not_public_count > 0)? ' data-exist' : ' no-data'))}>
                                    <a onClick={() => handleStatus('otsu_not_public')}>
                                    乙方登録未公開
                                    </a>
                                </li>)
                                }
                            })()
                        }

                        {
                            (() => {
                                if(login_user.role !=7 && login_user.role!=8) {
                                    return(  <li className={"list-item " +((('otsu_public' == active)?active:"") + ((otsu_public_count > 0)? ' data-exist' : ' no-data'))}>
                                    <a onClick={() => handleStatus('otsu_public')}>
                                    乙方登録公開済
                                    </a>
                                </li>)
                                }
                            })()
                        }

                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <ul className="status-menu responsive-list filter" data-filter="status">
                        {status_list2.map(status => (
                            <li className={(status[0] == active)?active:""}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}
                            </a>
                            </li>
                        ))}
                        </ul>
                        <ul className="status-menu responsive-list filter" data-filter="status">
                            {status_list3.map(status => (
                            <li className={((status[0] == active)?active:"")+ ((person_count > 0)? ' data-exist' : ' no-data')}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}({person_count})
                            </a>
                            </li>
                        ))}
                            </ul>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <ul className="status-menu responsive-list filter" data-filter="status">
                            {status_list3.map(status => (
                            <li className={((status[0] == active)?active:"")+ ((person_count > 0)? ' data-exist' : ' no-data')}>
                            <a onClick={() => handleStatus(status[0])}>
                                {status[1]}({person_count})
                            </a>
                            </li>
                        ))}
                            </ul>
                    </Col>
                </Row> */}
                <Row className="p-2 g-0">
                    <Col  xxl={10} xl={12} md={12} sm={12} xs={12} className="check-list filter" id="filter-by-skill">
                        <input type="checkbox" id="java" name="java" value="java" onChange={e => handleSkill(e)} />
                        <label htmlFor="java"> JAVA</label>
                        <input type="checkbox" id="c" name="c" value="c" onChange={e => handleSkill(e)} />
                        <label htmlFor="c"> C/C++</label>
                        <input type="checkbox" id="asp" name="asp" value="asp" onChange={e => handleSkill(e)} />
                        <label htmlFor="asp"> ASP.NET/C#</label>
                        <input type="checkbox" id="vb" name="vb" value="vb" onChange={e => handleSkill(e)}/>
                        <label htmlFor="vb"> VB/VB.NET</label>
                        <input type="checkbox" id="php" name="php" value="php" onChange={e => handleSkill(e)}/>
                        <label htmlFor="php"> PHP</label>
                        <input type="checkbox" id="ruby_python" name="ruby" value="ruby_python" onChange={e => handleSkill(e)}/>
                        <label htmlFor="ruby_python"> Ruby/Python</label>
                        <input type="checkbox" id="sap" name="sap" value="sap"  onChange={e => handleSkill(e)}/>
                        <label htmlFor="sap"> SAP</label>
                        <input type="checkbox" id="plsql" name="plsql" value="plsql" onChange={e => handleSkill(e)} />
                        <label htmlFor="plsql"> PLSQL(PL/SQL)</label>
                        <input type="checkbox" id="other" name="other" value="infra" onChange={e => handleSkill(e)} />
                        <label htmlFor="other"> インフラ</label>
                        <input type="checkbox" id="handoutai" name="handoutai" value="handoutai" onChange={e => handleSkill(e)} />
                        <label htmlFor="handoutai"> 半導体</label>
                    </Col>
                    <Col  xxl={2} xl={12} md={12} sm={12} xs={12}>
                        <input type="text" placeholder="and スキルを検索" name="search_skill" id="search_skill" className="form_control" onChange={onChange} style={{borderRadius:"15px !important",width:"100%",height:"30px"}}/>
                    </Col>
                </Row>
                <Row className="p-2">
                    <Col  xxl={12} xl={12} md={12} sm={12} xs={12}>
                        <span className="search_result_num"> 検索件数 : {total}件</span>
                        <select name="sort" id="sort" className="filter" onChange={onSelect}>
                            <option value="0" selected>新しい順</option>
                            <option value="1">古い順</option>
                        </select>
                    </Col>
                </Row>
                <Row className="persons">
                {
                    persons.length > 0 && persons.map(person=> {
                        
                        if(person["priority"] == 1){
                            imageUrl = '/images/priority1.jpg';
                        }else if(person["priority"] ==2){
                            imageUrl = '/images/priority2.jpg';
                        }else if(person["priority"] ==3){
                            imageUrl = '/images/priority3.png';
                        }
                        return(
                                <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="mt-2 mb-2">
                                    <div className="card">
                                        <div className="card-body p-3">
                                            <Row className="profile">
                                                <Col xxl={9} xl={9} md={9} sm={9} xs={8}>
                                                    <Link to="" onClick={()=>personDetail(person, person.encode)} className="text-decoration-none">
                                                        <Row>
                                                            <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                                                <ResourceImg remoteSrc={person.photo_path} src={(person.gender == "男")?'images/businessman.jpg':'images/businesswoman.jpg'}  className="img-circle" alt="icons" />
                                                                {(person["proposable_flg"] == 0)?<span className="m-3 mt-2  mb-1 status_btn_1 float-end">営業不可</span>:<span className="m-3 mt-2  mb-1 status_btn_2 float-end" style={{}}>営業可</span>}<br />
                                                                {/* <span className="m-3 mt-2  mb-1 status_btn float-end">{(person.proposal_flg == 0)? "営業不可":"営業可"}</span><br /> */}
                                                            </Col>
                                                            <Col xxl={8} xl={8} md={8} sm={8} xs={8}>
                                                                <ul className="">
                                                                    <li><p className="h4">{person.InitialName.length > 20 ? person.InitialName.substring(0, 20)+"...": person.InitialName} {"(ID:"+ person.person_id+")"}</p></li>
                                                                    <li><span>年齢： {person.birthday}</span> </li>
                                                                    <li> <span>性別 : {person.gender} </span> </li>
                                                                    <li> <span>国籍 : {person.country} </span> </li>
                                                                    <li> <span>経験年数 : {person.prof_start_ym} </span> </li>
                                                                    <li> <span>日本語レベル : {person.japanese_level} </span> </li>
                                                                    <li> <span>英語レベル : {person.english_level} </span> </li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    </Link>
                                                </Col>
                                                
                                                <Col xxl={3} xl={3} md={3} sm={3} xs={4}>
                                                    {
                                                        (() => {
                                                            if(login_user.role !=8) {
                                                            return(<Link to="" onClick={()=>goToAssignment(person.encode)} className="text-decoration-none text-success fw-bold toriate-btn blue assign_btn btn_standard">
                                                            割リ当て
                                                            </Link>)
                                                            }
                                                        })()
                                                    }
                                                    <Link to="" onClick={()=>personDetail(person, person.encode)} className="text-decoration-none">
                                                    <span className="btn btn-xs assign_count mb-3 ml-3 mt-1 float-end">{person.assignments_count}</span>
                                                        {(person["priority"] == 0)?"":<img src={imageUrl} style={{float:"right",width:"65%", height:"unset"}} alt="Priority123" />}
                                                    </Link>
                                                </Col>
                                            </Row>
                                            <Link to="" onClick={()=>personDetail(person, person.encode)} className="text-decoration-none">  
                                            <Row className="m-0 ">
                                                <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                                    {  person.skill !== null && Object.keys(person.skill).map(key => {
                                                                if (person.skill[key] !== null && (person.skill[key] != "Other" && person.skill[key] != "other")){
                                                                    if(person.skill[key] === "C" && person.skill[key].length == 1){
                                                                        person.skill[key] = person.skill[key].replace("C", "C/C++");
                                                                    }
                                                                    if(person.skill[key] === "handoutai"){
                                                                        person.skill[key] = person.skill[key].replace("handoutai", "半導体");
                                                                    }
                                                                    return(<span className="btn m-1 btn-skill">{person.skill[key]} </span>)
                                                                }
                                                                })
                                                            }
                                                </Col>
                                            </Row>     
                                            </Link>                                    
                                        </div>
                                    </div>

                                   
                                    
                                </Col>
                             ) 
                        })
                    }
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Persons;