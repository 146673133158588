import React ,{useState , useEffect} from "react";
import PersonService from "../../services/PersonService";
import ResourceService from "../../services/ResourceService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";

import DetailTopbar from "../../components/common/DetailTopbar";
import {useParams, Link} from "react-router-dom";
import {AssignmentByCaseModel} from "../../models/Assignment";
import DatePicker from "react-datepicker";
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface URLParam {
    id : string;
}

interface IState{
    loading :boolean;
    person_arr : [];
    cases_arr : [];
    errorMessage : string;
}

interface IProps{}
let AssignmentByPerson:React.FC<IProps> = () => {
    let {encode} = useParams<URLParam | any>();
    let [state, setState] = useState<IState>({
        loading : false,
        person_arr :  [],
        cases_arr : [],
        errorMessage : "",
    });

    useEffect(() => {
        console.log("Hello");
        console.log("ID" + encode);
        if(encode){
            setState({
                ...state,
                loading : true
            })
            PersonService.createByPerson(encode).then((response)=>{
                console.log(response.data);
                setState({
                    ...state,
                    loading : false,
                    person_arr : response.data.person,
                    cases_arr : response.data.cases
                })
            }).catch((error)=>{
                setState({
                    ...state,
                    loading : false,
                    errorMessage : error.message
                })
            });
        }
    }, [encode]);

  let {loading, person_arr, cases_arr, errorMessage} = state;
  console.log(person_arr);

  let [data , setData] = useState([]);
  const [selectedInterviewDate, setSelectedInterviewDate] = useState(null);
  const [selectedProposalDate, setSelectedProposalDate] = useState(null);
  const [selectedWorkStartDate, setSelectedWorkStartDate] = useState(null);
  const [person_list, setPersonList] = useState([]);
  const [person_field, setPersonFieldList] = useState([]);
  const [person_list_display, setPersonListDisplay] = useState("none");
  const [person_search_result , setSearchResult] = useState("");
  const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  let cases : string[] = [];
  const [case_list, setCaseList] = useState(cases);
  
    
    const handleCases = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        
        if(isChecked === true){
           case_list.push(value);
           setCaseList(case_list);
           console.log(case_list);
        }else{
            const cases = case_list.filter(function(cases){
                return cases != value; 
             });
             setCaseList(cases);
        }
    }

    let store = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        PersonService.assignmentByPerson(case_list, person_arr["person_id"]).then((response) => {
           window.location.href = "/persons/"+person_arr["encode"];
        })
    };


     let downloadURL = process.env.REACT_APP_DOWNLOADURL;

    return(
        <React.Fragment>
          <DetailTopbar />
            <Container>
            <div className="book-mark-container detail">
                <Row>
                    <Col xs={12} className="pc-hidden">
                        <div className="mt-3 text-center">
                            {( loading   == true) ? <Loader /> :<> </>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={12}>
                        <h3 className='header-left-design'>割リ当て登録 (要員ID : {person_arr['person_id']})</h3>
                    </Col>
                    <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                        <div className="card">
                            <div className="card-body">
                                <h4>基本情報 </h4>
                                <table className="new-table">
                                <tbody>
                                    <tr>
                                        <th>要員ID</th>
                                        <td>{person_arr['person_id']}</td>
                                    </tr>
                                    <tr>
                                        <th>要員ステータス</th>
                                        <td>{person_arr['assign_status']}</td>
                                    </tr>
                             {
                                (() => {
                                    if(login_user.role != 8){
                                        return(
                                        <tr>
                                            <th>漢字氏名</th>
                                            <td>{person_arr['full_name']}</td>
                                        </tr>
                                        )}
                                })()
                            }
                             {
                                (() => {
                                    if(login_user.role != 8){
                                        return(
                                    <tr>
                                        <th>カタカナ氏名</th>
                                        <td>{person_arr['kana_name']}</td>
                                    </tr>
                                       )}
                                    })()
                                }
                                 {
                                (() => {
                                    if(login_user.role != 8){
                                        return(
                                    <tr>
                                        <th>英語氏名</th>
                                        <td>{person_arr['alphabet_name']}</td>
                                    </tr>
                                       )}
                                    })()
                                }
                                    <tr>
                                        <th>ジョブタイトル</th>
                                        <td>{person_arr['jobtitle']}</td>
                                    </tr>
                                    <tr>
                                        <th>イニシャル</th>
                                        <td>{person_arr['InitialName']}</td>
                                    </tr>
                                    <tr>
                                        <th>性別</th>
                                        <td>{person_arr['gender']}</td>
                                    </tr>
                                    <tr>
                                        <th>国籍</th>
                                        <td>{person_arr['country']}</td>
                                    </tr>
                                    <tr>
                                        <th>年齢</th>
                                        <td>{person_arr['birthday']}歳</td>
                                    </tr>
                                    <tr>
                                        <th>最寄り駅</th>
                                        <td>{person_arr['nearest_station']}</td>
                                    </tr>
                                    <tr>
                                        <th>来日年数</th>
                                        <td>{person_arr['arrival_in_jp_ym']}年</td>
                                    </tr>
                                    <tr>
                                        <th>経験年数</th>
                                        <td>{person_arr['prof_start_ym']}年</td>
                                    </tr>
                                    <tr>
                                        <th>日本語レベル</th>
                                        <td>{person_arr['japanese_level']}</td>
                                    </tr>
                                    <tr>
                                        <th>英語レベル</th>
                                        <td>{person_arr['english_level']}</td>
                                    </tr>
                                    <tr>
                                        <th>スキル</th>
                                        <td>{person_arr['skill']}</td>
                                    </tr>
                                    <tr>
                                        <th>対応フェーズ</th>
                                        <td>{person_arr['response_phase']}</td>
                                    </tr>

                                    <tr>
                                        <th> 特記事項</th>
                                        <td>{person_arr['remarks']}</td>
                                    </tr>
                                   
                                    {login_user.role !== 8? 
                                        <tr key="belong_type">
                                            <th>所属種別 </th>
                                            <td>{person_arr['belong_type']}</td>
                                        </tr>                                        
                                    :
                                      ""
                                    }
                                    
                                    {login_user.role !== 8? 
                                        <tr key="emp_id">
                                            <th> 社員ID（自社の場合のみ）： </th>
                                            <td>{person_arr['emp_id']}</td>
                                        </tr>                                       
                                    :
                                      ""
                                    }
                                    <tr>
                                        <th>新規稼働可能日</th>
                                        <td>{person_arr['workstart_date']}</td>
                                    </tr>
                            {
                                (() => {
                                    if([1,2,3,4,6,8,9].includes(login_user.role)){
                                        return(
                                    <tr>
                                        <th>希望単価</th>
                                        <td>{person_arr['proposal_price']}</td>
                                    </tr>
                                     )}
                                    })()
                                }
                                    <tr>
                                        <th> 面接記録・評価</th>
                                        <td>{person_arr['interview_remarks']}</td>
                                    </tr>
                                    <tr>
                                        <th>現場評価</th>
                                        <td>{person_arr['customer_remarks']}</td>
                                    </tr>
                                    {
                                        (() => {
                                            if(![5,7,8].includes(login_user.role)){
                                                return(
                                            <tr>
                                                <th>公開フラグ</th>
                                                <td>{person_arr['publish_flg'] ==0 ? '不可' : '可'}</td>
                                            </tr>
                                            )}
                                            })()
                                    }
                                    <tr>
                                        <th>履歴</th>
                                        <td> <a href={(person_arr['resume_file_path'] != null && person_arr['resume_file_path'] != '')?ResourceService.download(`${person_arr['resume_file_path']}`):'#'} className="btn btn_standard">ダウンロード</a></td>
                                    </tr>
                                    <tr>
                                        <th>写真</th>
                                        <td> <a href={(person_arr['photo_path'] != null && person_arr['photo_path'] != '')?ResourceService.download(`${person_arr['photo_path']}`):'#'}  className="btn btn_standard">ダウンロード</a></td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                            <table className="new-table mt-3">
                                <tbody>
                                    <tr className="mt-3">
                                        <th></th>
                                        <td>
                                            <Link to={`/persons/${person_arr["person_id"]}/edit`}  className="assign-edit-btn">
                                                要員変更
                                                </Link>
                                            </td>
                                    </tr>
                                </tbody>
                            </table>      
                           
                            </div>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12}>
                        <div className="card">
                            <div className="card-body">
                                <form  onSubmit={store}>
                                    <input type="hidden" name="person_id" value={person_arr["person_id"]}/>
                                    <input type="hidden" name="create_type" value="person_cases" />
                                    <table className="new-table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>案件ID</th>
                                                <th>案件名</th>
                                                <th>作業場所</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            cases_arr.length > 0 && cases_arr.map(cases=> {
                                                return(
                                                    <tr key={cases['case_id']}>
                                                        <td><input type="checkbox" name="case_ids[]" onChange={e => handleCases(e)} value={cases['case_id']} /></td>
                                                        <td>{cases['case_id']}</td>
                                                        <td><Link to={`/cases/${cases['encode']}`} className="text-decoration-none">{cases['case_name']}</Link></td>
                                                        <td>{cases['work_place']}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>    
                                    </table>
                                    <div className="row mt-5">
                                        <div className="col-12 text-center">
                                            <Link to={`/persons/${person_arr["encode"]}`}  id="case_create_submit" className="text-decoration-none btn btn_cancel">キャンセル</Link>
                                            <button className="btn btn_standard" id="case_create_submit" type="submit">割り当て</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                  
			    </Row>
            
            </div>
            </Container>	
        </React.Fragment>
    );
};
export default AssignmentByPerson;