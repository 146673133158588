import React, {useEffect, useState} from 'react';
import {ICase} from "../../models/ICase";
import {CaseListService} from "../../services/CaseService";
import AuthService from "../../services/AuthService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Dropdown from 'react-bootstrap/Dropdown';
import './CaseList.css';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import {Link} from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {SendBulkMail} from "../../models/ICase";
import $ from 'jquery';
import { CSVLink, CSVDownload } from "react-csv";
import {useLocation} from "react-router-dom";
import Loader from "../../components/common/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";

interface IState{
    cases : ICase[];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    flb_sale_id : number;
    totalRows : number;
    saleList : [];
    hrList : [];
    recuritAreaList : [];
    send_mail_arr : SendBulkMail;
    isChecked : boolean;
    loading : boolean;
}
interface IProps{}
let CaseList:React.FC<IProps> = () => {
    const row : string[] = [];
    const [checkedRows, setCheckedRows] = useState(row);

    const defaultcolumns = [
            {
                name: "case_id",
                label: "案件ID",
                // options: {
                //     filter: true,
                //     sort: true,
                //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
                //         console.log("Index start 1233");
                //         console.log(Number(rowIndex/2));
                //         console.log("Index end 1233");
                //         return {
                //         style:(Number(rowIndex%2) ==0)? { 
                //                 minWidth: "50px", 
                //                 maxWidth: "500px" ,
                //                 // whiteSpace: "nowrap",
                //                 // position: "sticky",
                //                 // left: 30,
                //                 background: "rgb(242,242,242)",
                //                 // border : "1px solid rgb(242,242,242)",
                //                 // zIndex: 102,
                //                 // padding: "8px !important"
                //             }: { 
                //                 minWidth: "50px", 
                //                 maxWidth: "500px" ,
                //                 // whiteSpace: "nowrap",
                //                 // position: "sticky",
                //                 // left: 30,
                //                 background: "#fff",
                //                 // border : "1px solid #fff",
                //                 // padding: "8px !important"
                //             }
                //         };
                //     },
                    
                //     setCellHeaderProps: () => ({
                //         style: {
                //             minWidth: "50px", 
                //             maxWidth: "500px" ,
                //             // whiteSpace: "nowrap",
                //             // position: "sticky",
                //             // left: 30,
                //             background: "rgb(242,242,242)",
                //             // border : "1px solid rgb(242,242,242)",
                //             // zIndex: 102
                //         }
                //     }),

                // }
            },
            {
                name: "case_name",
                label: "案件名",
                class: "longText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: {maxWidth: "113px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                                    <div className='longText' title={value} style={{color:"blue"}}>
                                        {value}
                                    </div>
                                );
                        },
                    },

                // class: "longText",
                // options: {
                //     filter: true,
                //     sort: true,
                //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
                //         return {
                //         style:(Number(rowIndex%2) ==0)? { 
                //                 minWidth: "300px", 
                //                 maxWidth: "500px" ,
                //                 // whiteSpace: "nowrap",
                //                 // position: "sticky",
                //                 // left: 90,
                //                 background: "rgb(242,242,242)",
                //                 // border : "1px solid rgb(242,242,242)",
                //                 // zIndex: 101
                //             }: 
                //             { 
                //                 minWidth: "300px", 
                //                 maxWidth: "500px" ,
                //                 // whiteSpace: "nowrap",
                //                 // position: "sticky",
                //                 // left: 90,
                //                 background: "#fff",
                //                 // border : "1px solid #fff",
                //                 // zIndex: 101
                //             }
                //         };
                //     },
                    
                //     setCellHeaderProps: () => ({
                //         style: {
                //             minWidth: "10px", 
                //             // whiteSpace: "nowrap",
                //             // position: "sticky",
                //             // left: 90,
                //             background: "rgb(242,242,242)",
                //             // border : "1px solid rgb(242,242,242)",
                //             // zIndex: 102
                //         }
                //     }),
                //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {

                //         // value = (value !== null && value.length >= 25)? value.substring(0, 25)+"...": value;
                //         // <Tooltip title={value}>
                //         // <span>{value}</span>
                //         // </Tooltip>
                //         return(
                //             <div className='longText' title={value}>
                //                 {value}
                //             </div>
                //         );
                //     },
                // }       
            },
           {
            name: "start_date_plan",
            label: "開始年月日",
            class: "longText",
            options: {
                filter: true,
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                    // value = value.substring(0, value.indexOf(" "));
                    // return(value);
                    },
                },
           },
           {
               name: "case_overview",
               label: "案件概要",
               class: "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "220px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value.length >= 12)? value.substring(0, 12)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
           },
           {
               name: "required_skill",
               label: "要求スキル",
               class: "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "200px",marginLeft:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value.length >= 22)? value.substring(0, 22)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
                
                // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                //     value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
                //     return(value);
                //     },
            //    options: {
            //     filter: true,
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //         value = (value !== null)? value.split(","): value;
            //         return(
            //             value !== null && Object.keys(value).map(key => {
            //             if (key !== null && value[key] !== null && value[key] !== "")
            //                 return(<span className="btn m-1 btn_list_skill">{value[key].replace("[", "").replace("]", "").replaceAll("\"", "")} </span>)
            //             })
                    
            //         );

            //     },
            //     },
           },
           {
               name: "work_place",
               label: "作業場所",
               class: "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "13px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    //     value = (value !== null && value.length >= 2)? value.substring(0, 2)+"...": value;
                    // return(value);

                    },
                },
           },
           
           {
            name: "case_period_type",
            label: "期間",
           },
           {
               name: "recruit_num",
               label: "人数",
           },
           {
               name: "hr_name_jp",
               label: "HR担当",
               class: "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { minWidth: "3px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    //     value = (value !== null && value.length >= 2)? value.substring(0, 2)+"...": value;
                    // return(value);

                    },
                }
           },
           {
               name: "requested_date",
               label: "依頼日",
               class : "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "110px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // console.log(value);
                    // value = value.substring(0, value.indexOf(" "));
                    // return(value);
                    },
                },
           },
           {
            name: "priority",
            label: "優先順位",
            },
           {
               name: "update_date",
               label: "更新日",
               class : "longText",
               options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "110px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                }
            }, 
            }  

    ];
    const allcolumns = [
        {
            name: "case_id",
            label: "案件ID",
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         console.log("Index start 1233");
            //         console.log(Number(rowIndex/2));
            //         console.log("Index end 1233");
            //         return {
            //         //   className: clsx({
            //         //     [this.props.classes.NameCell]: value === 'Mel Brooks',
            //         //   }),
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 30,
            //                 background: "rgb(242,242,242)",
            //                 border : "1px solid rgb(242,242,242)",
            //                 zIndex: 102,
            //                  padding: "8px !important"
            //             }: { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 30,
            //                 background: "#fff",
            //                 border : "1px solid #fff",
            //                 zIndex: 102,
            //                  padding: "8px !important"
            //             }
            //         };
            //     },
                
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "50px", 
            //             maxWidth: "500px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 30,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),

            // }
        },
        {
            name: "case_name",
            label: "案件名",
            class: "longText",
                options: {
                    filter: true,
                    setCellProps: () => ({ style: {maxWidth: "113px"}}),
                    customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                        return(
                                    <div className='longText' title={value} style={{color:"blue"}}>
                                        {value}
                                    </div>
                                );
                        },
                    },
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         return {
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "rgb(242,242,242)",
            //                 border : "1px solid rgb(242,242,242)",
            //                 zIndex: 101
            //             }: 
            //             { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "#fff",
            //                 border : "1px solid #fff",
            //                 zIndex: 101
            //             }
            //         };
            //     },
                
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "10px", 
            //             // maxWidth: "100px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 90,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {

            //         // value = (value !== null && value.length >= 25)? value.substring(0, 25)+"...": value;
            //         // <Tooltip title={value}>
            //         // <span>{value}</span>
            //         // </Tooltip>
            //         return(
            //             <div className='longText' title={value}>
            //                 {value}
            //             </div>
            //         );
            //     },
            // }       
        },
        {
            name: "customer_id",
            label: "取引先ID",
        },
        {
            name: "customer-company",
            label: "取引先",
            class: "longText",
            options: {
                filter: true,
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                }
            }
        },
        {
            name: "start_date_plan",
            label: "開始年月日",
            class: "longText",
            options: {
                filter: true,
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                }
            }
        },
        {
            name: "case_overview",
            label: "案件概要",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "220px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 // value = (value !== null && value.length >= 12)? value.substring(0, 12)+"...": value;
                 // return(value);
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 },
             },
        },
        {
            name: "required_skill",
            label: "要求スキル",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "200px",marginLeft:"100px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // value = (value !== null && value.length >= 20)? value.substring(0, 20)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
            //    options: {
            //     filter: true,
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
            //         value = (value !== null)? value.split(","): value;
            //         return(
            //             value !== null && Object.keys(value).map(key => {
            //             if (key !== null && value[key] !== null && value[key] !== "")
            //                 return(<span className="btn m-1 btn_list_skill">{value[key].replace("[", "").replace("]", "").replaceAll("\"", "")} </span>)
            //             })
                    
            //         );

            //     },
            //     },
        },
        {
            name: "work_place",
            label: "作業場所",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "13px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    //     value = (value !== null && value.length >= 2)? value.substring(0, 2)+"...": value;
                    // return(value);
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    },
                },
        },
        {
            name: "case_period_type",
            label: "期間",
        },
        {
            name: "recruit_num",
            label: "人数",
        },
        {
            name: "saler_name_jp",
            label: "営業担当者",
        },
        {
            name: "hr_name_jp",
            label: "HR担当者",
        },
        {
            name: "requested_date",
            label: "依頼日",
            class : "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: { maxWidth: "110px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value}>
                            {value}
                        </div>
                    );
                    // value = value.substring(0, value.indexOf(" "));
                    // return(value);
                    },
                },
        },
        {
            name: "priority",
            label: "優先順位",
        },
        {
            name: "update_date",
            label: "更新日",
            class : "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "110px" }}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
             }
         }, 
         }

    ];
    const addsalercolumns = [
        {
            name: "case_id",
            label: "案件ID",
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         console.log("Index start 1233");
            //         console.log(Number(rowIndex/2));
            //         console.log("Index end 1233");
            //         return {
            //         //   className: clsx({
            //         //     [this.props.classes.NameCell]: value === 'Mel Brooks',
            //         //   }),
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 46,
            //                 background: "rgb(242,242,242)",
            //                 // border : "1px solid rgb(242,242,242)",
            //                 zIndex: 102,
            //                 padding: "8px !important"
            //             }: { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 46,
            //                 background: "#fff",
            //                 // border : "1px solid #fff",
            //                 zIndex: 102,
            //                 padding: "8px !important"
            //             }
            //         };
            //     },
                
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "50px", 
            //             maxWidth: "500px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 46,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),

            // }
        },
        {
            name: "case_name",
            label: "案件名",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth: "113px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                                <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                                </div>
                            );
                    },
                },
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         return {
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "rgb(242,242,242)",
            //                 border : "1px solid rgb(242,242,242)",
            //                 zIndex: 101
            //             }: 
            //             { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "#fff",
            //                 border : "1px solid #fff",
            //                 zIndex: 101
            //             }
            //         };
            //     },
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "10px", 
            //             // maxWidth: "100px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 90,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {

            //         // value = (value !== null && value.length >= 25)? value.substring(0, 25)+"...": value;
            //         // <Tooltip title={value}>
            //         // <span>{value}</span>
            //         // </Tooltip>
            //         return(
            //             <div className='longText' title={value}>
            //                 {value}
            //             </div>
            //         );
            //     },
            // }       
        },
        {
            name: "start_date_plan",
            label: "開始年月日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                }
            }
            
        },
        {
            name: "case_period_type",
            label: "期間種別",
        },
        {
            name: "recruit_num",
            label: "募集人数",
        },
        {
            name: "case_overview",
            label: "案件概要",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "220px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 // value = (value !== null && value.length >= 12)? value.substring(0, 12)+"...": value;
                 // return(value);
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 },
             },
        },
        {
            name: "case_overview",
            label: "案件概要",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "220px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 // value = (value !== null && value.length >= 12)? value.substring(0, 12)+"...": value;
                 // return(value);
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 },
             },
        },
        // {
        //     name: "required_skill",
        //     label: "要求スキル",
        //     class: "longText",
        //     options: {
        //      filter: true,
        //      setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "500px",marginLeft:"100px"}}),
        //      customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //          // value = (value !== null && value.length >= 22)? value.substring(0, 22)+"...": value;
        //          // return(value);
        //          return(
        //              <div className='longText' title={value}>
        //                  {value}
        //              </div>
        //          );
        //          },
        //      },
             
        //      // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //      //     value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
        //      //     return(value);
        //      //     },
        //  //    options: {
        //  //     filter: true,
        //  //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        //  //         value = (value !== null)? value.split(","): value;
        //  //         return(
        //  //             value !== null && Object.keys(value).map(key => {
        //  //             if (key !== null && value[key] !== null && value[key] !== "")
        //  //                 return(<span className="btn m-1 btn_list_skill">{value[key].replace("[", "").replace("]", "").replaceAll("\"", "")} </span>)
        //  //             })
                 
        //  //         );

        //  //     },
        //  //     },
        // },
        {
            name: "work_place",
            label: "作業場所",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "13px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 //     value = (value !== null && value.length >= 2)? value.substring(0, 2)+"...": value;
                 // return(value);

                 },
             },
        },
        {
            name: "saler_name_jp",
            label: "営業担当者",
        },
        {
            name: "hr_name_jp",
            label: "HR担当者",
        },
        {
            name: "requested_date",
            label: "依頼日",
            class : "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "110px" }}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 // console.log(value);
                 // value = value.substring(0, value.indexOf(" "));
                 // return(value);
                 },
             }
        },
        {
            name: "priority",
            label: "優先順位",
        },
        {
            name: "update_date",
            label: "更新日",
            class : "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "110px" }}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
             }
         }, 
         }
    ];
    const addCustomerColumns = [
        {
            name: "case_id",
            label: "案件ID",
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         console.log("Index start 1233");
            //         console.log(Number(rowIndex/2));
            //         console.log("Index end 1233");
            //         return {
            //         //   className: clsx({
            //         //     [this.props.classes.NameCell]: value === 'Mel Brooks',
            //         //   }),
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 30,
            //                 background: "rgb(242,242,242)",
            //                 border : "1px solid rgb(242,242,242)",
            //                 zIndex: 102,
            //                 padding: "8px !important"
            //             }: { 
            //                 minWidth: "50px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 30,
            //                 background: "#fff",
            //                 border : "1px solid #fff",
            //                 zIndex: 102,
            //                 padding: "8px !important"
            //             }
            //         };
            //     },
                
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "50px", 
            //             maxWidth: "500px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 30,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),

            // }
        },
        {
            name: "case_name",
            label: "案件名",
            class: "longText",
            options: {
                filter: true,
                setCellProps: () => ({ style: {maxWidth: "113px"}}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                                <div className='longText' title={value} style={{color:"blue"}}>
                                    {value}
                                </div>
                            );
                    },
                },
            // class: "longText",
            // options: {
            //     filter: true,
            //     sort: true,
            //     setCellProps: (value:any, rowIndex:number, columnIndex:number) => {
            //         return {
            //         style:(Number(rowIndex%2) ==0)? { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "rgb(242,242,242)",
            //                 border : "1px solid rgb(242,242,242)",
            //                 zIndex: 101
            //             }: 
            //             { 
            //                 minWidth: "300px", 
            //                 maxWidth: "500px" ,
            //                 whiteSpace: "nowrap",
            //                 position: "sticky",
            //                 left: 90,
            //                 background: "#fff",
            //                 border : "1px solid #fff",
            //                 zIndex: 101
            //             }
            //         };
            //     },
                
            //     setCellHeaderProps: () => ({
            //         style: {
            //             minWidth: "10px", 
            //             // maxWidth: "100px" ,
            //             whiteSpace: "nowrap",
            //             position: "sticky",
            //             left: 90,
            //             background: "rgb(242,242,242)",
            //             border : "1px solid rgb(242,242,242)",
            //             zIndex: 102
            //         }
            //     }),
            //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {

            //         // value = (value !== null && value.length >= 25)? value.substring(0, 25)+"...": value;
            //         // <Tooltip title={value}>
            //         // <span>{value}</span>
            //         // </Tooltip>
            //         return(
            //             <div className='longText' title={value}>
            //                 {value}
            //             </div>
            //         );
            //     },
            // }       
        },
        {
            name: "customer_id",
            label: "取引先ID",
        },
        {
            name: "customer-company",
            label: "取引先",
            class: "longText",
            options: {
                filter: true,
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                }
            }
        },
        {
            name: "start_date_plan",
            label: "開始年月日",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                        <div className='longText' title={value} style={{width:"100px"}}>
                                {value}
                        </div>
                    );
                }
            }
            
        },
        {
         name: "case_period_type",
         label: "期間種別",
        },
        {
            name: "recruit_num",
            label: "募集人数",
        },
        {
            name: "case_overview",
            label: "案件概要",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "220px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 // value = (value !== null && value.length >= 12)? value.substring(0, 12)+"...": value;
                 // return(value);
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 },
             },
        },
        {
            name: "required_skill",
            label: "要求スキル",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "200px",marginLeft:"100px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 // value = (value !== null && value.length >= 22)? value.substring(0, 22)+"...": value;
                 // return(value);
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 },
             },
             
             // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
             //     value = (value !== null && value.length >= 10)? value.substring(0, 10)+"...": value;
             //     return(value);
             //     },
         //    options: {
         //     filter: true,
         //     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
         //         value = (value !== null)? value.split(","): value;
         //         return(
         //             value !== null && Object.keys(value).map(key => {
         //             if (key !== null && value[key] !== null && value[key] !== "")
         //                 return(<span className="btn m-1 btn_list_skill">{value[key].replace("[", "").replace("]", "").replaceAll("\"", "")} </span>)
         //             })
                 
         //         );

         //     },
         //     },
        },
        {
            name: "work_place",
            label: "作業場所",
            class: "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "13px"}}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 //     value = (value !== null && value.length >= 2)? value.substring(0, 2)+"...": value;
                 // return(value);

                 },
             },
        },
        {
            name: "hr_name_jp",
            label: "HR担当者",
        },
        {
            name: "requested_date",
            label: "依頼日",
            class : "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "110px" }}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
                 // console.log(value);
                 // value = value.substring(0, value.indexOf(" "));
                 // return(value);
                 },
             }
        },
        {
            name: "priority",
            label: "優先順位",
        },
        {
            name: "update_date",
            label: "更新日",
            class : "longText",
            options: {
             filter: true,
             setCellProps: () => ({ style: { maxWidth: "110px" }}),
             customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                 return(
                     <div className='longText' title={value}>
                         {value}
                     </div>
                 );
             }
         }, 
         }
       ];

      

    const getMuiTheme = () =>
    createTheme({
        components: {
        MUIDataTable: {
            styleOverrides:{
                responsiveScroll: {
                    maxHeight: 'none',
                  },
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    width: "100%",
                    marginTop: "5%",
                    // // marginLeft: "5%",
                    // // marginBottom: "20%"
                }
            }
        },  
        MUIDataTableHeadCell: {
                styleOverrides:{
                  root: {
                    //   backgroundColor: "rgb(193,193,193)",
                      padding: '5px'
                  }
                }
        },
        // MuiTableHead: {
                //     root: {
                //         padding: "0px",
                //         margin : "0px"
                //     }
                // },
        MuiTableCell: {
            styleOverrides:{
                head: {
                        // color : 'white',
                        backgroundColor: "rgb(193,193,193) !important"
                }
            }
        },
          MUIDataTableBodyCell: {
            styleOverrides:{
              root: {
                //   backgroundColor: "#f1f1f1",
                  width: "100%",
                  padding: '0px'
              }
            }
          },
          MUIDataTablePagination: {
            styleOverrides:{
                root: {
                    color: "red",
                  //   backgroundColor: "#f1f1f1",
                    width: "100%",
                    padding: '0px'
                }
              }
          },
        }
      })
  

    let [state , setState] = useState<IState>({
        cases : [] as ICase[],
        errorMessage : "",
        page : 0,
        perPage : 10,
        column : "",
        order : "",
        flb_sale_id:0,
        totalRows : 0,
        saleList : [],
        hrList : [],
        recuritAreaList : [],
        send_mail_arr : {
            case_ids : "",
            mail_to : "",
            mail_subject : "",
            mail_message : ""
        },
        isChecked: false,
        loading: false
    });

    const [sale, setFlbSale] = useState("0");
    const [hr, setFlbHr] = useState("0"); 
    const [recurit_area, setRecuritArea] = useState("-1"); 
    const [case_status, setCaseStatus] = useState("processing");
    const [customer, setCustomer] = useState("");
    const [salerchecked, setsalerChecked] = useState<boolean>(false);
    const [saler, setSaler] = useState("");
    const [search, setSearch] = useState("");
    const [active, setActive] = useState("processing"); 
    const [status, setStatus] = useState("processing");
    const result : string[] = [];
    const [filter_list, setFilterList] = useState(result); 
    const [customerchecked, setCustomerChecked] = useState<boolean>(false);
    const [columnVisible, setColumnVisible] = React.useState(defaultcolumns);
    const skill : string[] = [];
    const [skill_list, setSkillList] = useState(skill); 
    const [sort, setSort] = useState("0");
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [allCases, setAllCases] = useState<any[]>([]);

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    const [checked, isChecked] = useState(false);
    const [show_processing_status, showProcessingStatus] = useState<boolean>(false);
    const [processing_active, setProcessingActive] =   useState("");
    const [search_skill, setSearchSkill] = useState("");

    // const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     height: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };

      let status_list: any[] = [];
    if(login_user.role != 7){
        status_list = [['all', '全て'] , ['not_start','未着手'], ['processing',' 募集開始'],['not_assigning','割り当てなし'],['assigning', '割り当て中'],['not_proposaling','未提案'],
        ['proposaling','提案中'],['interview','面談設定中'],['wait_result','結果待ち'],['end','募集終了'],['working','稼働中'],['finished','稼働終了']];
    }else{
        status_list = [['not_start','未着手'], ['processing',' 募集開始'],['end','募集終了']];
    }
    
    let status_list1: any[] = [['now_available','即日開始'], ['next_month_available',' 翌月開始'],['next_two_month_available','翌々月開始']];

    if (login_user.role !=7 && login_user.role != 8){
        //status_list1 = [['release','公開'],['not_release','未公開']];
        status_list1.push(['release','公開']);
        status_list1.push(['not_release','未公開']);
    }
    let status_list2: any[] = [];
    if( [1,2,3,4,9].includes(login_user.role)){
        status_list2 = [['koukata_public','甲方登録公開'],['koukata_not_public','甲方登録非公開']];
   }

//    let processing_status_list: any[] = [['now_available','即日開始'], ['next_month_available',' 翌月開始'],['next_two_month_available','翌々月開始']];



    const getData = async (filter_list:any,sale:any,hr:any,case_status:string,perPage:number,customer:any,saler:any, page:number,search_keywords:any, skill_list:any, sort:any,
         column:string, order:string, recurit_area:string, search_skill:any) => {
        setState({...state,loading :true,})
        CaseListService.getAllCaseList(filter_list,sale,hr,case_status,perPage,customer,saler, page,search_keywords, skill_list, sort,
             column, order, recurit_area, search_skill).then((response)=>{
            setState({
                ...state,
                cases:response.data.data,
                page : response.data.current_page,
                perPage : response.data.per_page,
                column : response.data.column,
                order : response.data.order,
                totalRows : response.data.total,
                saleList : response.data.flb_sales_id,
                hrList : response.data.flb_hr_id,
                recuritAreaList : response.data.recurit_area,
                flb_sale_id : response.data.query_string_info.flb_sales_id,
                loading:false,
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page){
                setShowPrevColor("grey");
            }else{
                setShowPrevColor("blue");
            }
            if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
                setShowNextColor("grey");
            } 
            setAllCases(response.data.all_data);  
            // if(Math.floor(totalRows/perPage) >0){
            //     setShowNextColor("blue");
            // } 
            
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
               }
            setState({
                ...state,
                errorMessage:error.message,
                loading:false
            })
        })
    }
    let {cases, perPage, page, column, order, flb_sale_id,totalRows,saleList,hrList, recuritAreaList, send_mail_arr,loading , errorMessage} = state;

    const selectAll = (e:any) => {
        if(checked == false){
            isChecked(true);
            let ids : any[] = [];
            cases.forEach((element, index) => {
                ids.push(element["case_id"]);
                setCheckedRows(ids);
              });
            $('input[type=checkbox][name="list_check[]').prop('checked', true).trigger('change');
        }else{
            isChecked(false);
            setCheckedRows([]);
            $('input[type=checkbox][name="list_check[]').prop('checked', false).trigger('change');
        }

      };
    useEffect(()=>{
        setState({...state,
            send_mail_arr : {
                case_ids : "",
                mail_to: "",
                mail_subject : "",
                mail_message : "",
            },
            isChecked :false,
            loading: true
        });
        showProcessingStatus(true);
        localStorage.removeItem("person_status");
        localStorage.removeItem("person_list_status");
        localStorage.removeItem("case_status");
        if(localStorage.getItem("case_list_status") != null && localStorage.getItem("case_list_search") != null){
            bindStatusData();
            setSearch(localStorage.getItem("case_list_search")!);  
            getData(filter_list,sale,hr,localStorage.getItem("case_list_status")!, perPage,customer,saler, page, localStorage.getItem("case_list_search"), skill_list ,sort, column,order, recurit_area, search_skill);
        }else if(localStorage.getItem("case_list_status") != null){
            bindStatusData();
            getData(filter_list,sale,hr,localStorage.getItem("case_list_status")!, perPage,customer,saler, page, search, skill_list ,sort, column,order, recurit_area, search_skill);
        }else if(localStorage.getItem("case_list_search") != null){
            setSearch(localStorage.getItem("case_list_search")!);  
            getData(filter_list,sale,hr,case_status, perPage,customer,saler, page, localStorage.getItem("case_list_search")!, skill_list ,sort, column,order, recurit_area, search_skill);
        }else{
            getData(filter_list,sale,hr,case_status, perPage,customer,saler, page, search, skill_list ,sort, column,order, recurit_area,search_skill);
        }
    }, [perPage]);

    const bindStatusData = function () {
        setActive(localStorage.getItem("case_list_status")!);
        setStatus(localStorage.getItem("case_list_status")!);
        if(localStorage.getItem("case_list_status")! == "now_available" || 
           localStorage.getItem("case_list_status")! == "next_month_available" || 
           localStorage.getItem("case_list_status")! == "next_two_month_available"){
            setProcessingActive(localStorage.getItem("case_list_status")!);
            showProcessingStatus(true);
            setActive("processing");
        }else if(localStorage.getItem("case_list_status")! == "processing"){
            showProcessingStatus(true);
            setProcessingActive("");
        }else{
            setProcessingActive(localStorage.getItem("case_list_status")!);
            showProcessingStatus(false);
        }
    }


    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(filter_list,sale,hr,case_status, perPage,customer,saler, page+1, search, skill_list,sort,column,order, recurit_area, search_skill);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(filter_list,sale,hr,case_status, perPage,customer,saler, page, search, skill_list,sort,column,order, recurit_area, search_skill);
    };
    let emailSend = (event : React.FormEvent<HTMLFormElement>):void => { // click event type declare in React
        event.preventDefault();
        CaseListService.bulkMailsend(send_mail_arr).then((response) => {
            window.location.reload();
        //    window.location.href = "/cases";
        })

    };
    const handleCheckbox = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;

        if(isChecked === true){
            checkedRows.push(value);
            setCheckedRows(checkedRows);
            // getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill_list,sort);
        }
        else{            
            const rows = checkedRows.filter(function(row){
                return row !== value; 
             });
             setCheckedRows(rows);
            //  getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search,skill,sort);
        }
    }

    const handleChange  = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "customer":
                if(e.target.checked == true){
                    setCustomerChecked(e.target.checked)
                    if(salerchecked){
                        setColumnVisible(allcolumns);
                    }else{
                        setColumnVisible(addCustomerColumns);
                    }
                }else
                {
                    setCustomerChecked(e.target.checked);
                    if(salerchecked){
                        setColumnVisible(addsalercolumns);
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            case "saler":
                if(e.target.checked == true){
                    setsalerChecked(e.target.checked);
                    if(customerchecked){
                        setColumnVisible(allcolumns);
                    }else{
                        setColumnVisible(addsalercolumns);
                    }
                }else
                {
                    setsalerChecked(e.target.checked)
                    if(customerchecked){
                        setColumnVisible(addCustomerColumns);
                    }else{
                        setColumnVisible(defaultcolumns);
                    }
                }
                break;
            default:
                break;
        }
        
      };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "flb_sales_id":
                setFlbSale(e.target.value);
                getData(filter_list,e.target.value,hr,case_status, state.perPage,customer,saler,state.page, search, skill_list,sort,column,order, recurit_area, search_skill);
                break;
            case "flb_hr_id":
                setFlbHr(e.target.value);
                getData(filter_list,sale,e.target.value,case_status, state.perPage,customer,saler,state.page, search, skill_list,sort,column,order, recurit_area, search_skill);
                break;
            case "recurit_area":
                setRecuritArea(e.target.value);
                getData(filter_list,sale,hr,case_status, state.perPage,customer,saler,state.page, search, skill_list,sort,column,order, e.target.value, search_skill);
                break;
            case "customer":
                setCustomer(e.target.value);
                getData(filter_list,sale,hr,case_status, state.perPage,e.target.value,saler,state.page, search, skill_list,sort,column,order, recurit_area, search_skill);
                break;
            case "saler":
                setSaler(e.target.value);
                getData(filter_list,sale,hr,case_status, state.perPage,customer,e.target.value,state.page, search, skill_list,sort,column,order, recurit_area, search_skill);
                break;
            case "sort":
                setSort(e.target.value);
                getData(filter_list,sale,hr,case_status, state.perPage,customer,saler,state.page, search, skill_list,e.target.value,"","", recurit_area, search_skill);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })

                break;
            default:
                break;
        }
     };

     let handleDelete = (rowData:any, rowState:any) => {
        let rows : any[] = [];
        rowData.data.forEach((element:any, index:any) => {
            rows.push(element.dataIndex);
        })
        let ids : any[] = [];
        cases.forEach((element, index) => {
          if (rows.includes(index)) {
            ids.push(element["case_id"]);
          }
        });
        CaseListService.delete(ids).then((response:any)=>{
            if(response.data.success == false){
                alert(response.data.info.message);
                window.location.reload();
            }
        }).catch((error:any) => {
            setState({
                ...state,
                errorMessage:error.message
            })
        })
    }

    let handleSort = (column:any, order:any) => {
        let temp = {};
        temp["column"] = column;
        temp["order"] = order;
        // temp.page = this.state.page;
        getData(filter_list,sale,hr,case_status, state.perPage,customer,saler,state.page, search, skill_list,"",column,order, recurit_area, search_skill);
      };
      let nextPage = () => {
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            setShowNextColor("grey");
        }   
        // if(0 < current_page+1){
        //     alert("Blue");
        //     setShowPrevColor("blue");
        // }     

    }
    // console.log(current_page);
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        

        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 

        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    const sendMail = () => {

        if(selectedRows.length == 0){
            alert("要員（30名以内でお願いします）をチェックしてください。");
            return false;
        }
        let company = (login_user != null)?login_user.company:"";
        var mail_message = "いつもお世話になっております、"+company+"の営業でございます。\n\n" +
        "日頃、要員アサインのご協力を頂きまして、\n" + 
        "心から感謝を申し上げます。\n" + 
        "あらためまして、案件情報を送付させて頂きます。\n" + 
        "ご協力いただける方がいらっしゃいましたら、\n" + 
        "ご提案を頂けますよう、お願い申し上げます。\n" + 
        　"※情報のお取扱いには、十分ご注意願います。\n" + 
        　"※ご質問・ご提案は、当該メールに「全員に返信」でお願い致します。\n" + 
        　"※技術者様の「所属」及び「並行営業状況」を記載してご提案いただくと助かります。";
        var sList = "";

        let ids : any[] = [];
        cases.forEach((element, index) => {            
            if (selectedRows.length > 0 && selectedRows.includes(index)) {
                ids.push(element["case_id"]);
            }
        });

        let data = {};
        data["ids"] = ids;
        CaseListService.getBulkMailData(data).then((data)=>{
            data = data.data;
            for(var i=0; i < data.data.length; i++) {
                sList += "=================================\n";
                sList += data.data[i].appeal_cmnt;
                sList += "\n";
                sList += "案件ID ： " + data.data[i].case_id;
                sList += "\n";
                sList += "案件名 ： " + data.data[i].case_name;
                sList += "\n";
                sList += "要求内容 ： " + data.data[i].requisition;
                sList += "\n";
                sList += "要求スキル ： " + data.data[i].required_skill;
                sList += "\n";
                sList += "開始年月日 ： " + data.data[i].start_date_plan;
                sList += "\n";
                sList += "作業場所 ： " + data.data[i].work_place;
                sList += "\n";
                sList += "募集人数 ： " + data.data[i].recruit_num + "名";
                sList += "\n";
                if (data.data[i].settlement != null) {
                    sList += "精算 ： " + data.data[i].settlement;
                    sList += "\n";
                }
                sList += "面談回数 ： " + data.data[i].interview_num + "回";
                if (data.data[i].remarks != null) {
                    sList += "\n";
                    sList += "特記事項 ： " + data.data[i].remarks;
                }
                sList += "\n\n";
                
            }

            mail_message = mail_message + "\n\n" + sList;
            mail_message += "\n以上、よろしくお願い致します。";
            mail_message += "\n\nーーーーーーーーーーーーーーーーーーーーーーーーーー\n";
            mail_message += "株式会社 FloBoard 営業部\n";
            mail_message += "担当: (野本)080-2264-1391\n";
            mail_message += "      (馮)080-5069-1827\n";
            mail_message += "MAIL: eigyou@floboard.co.jp\n";
            mail_message += "本社: 東京都千代田区東神田二丁目7番4-305\n";
            mail_message += "開発センター: 東京都江東区亀戸6-3-2 寺島ビル303室\n";
            mail_message += "TEL: (03)6753-0948 FAX: (03)6325-3608\n";
            mail_message += "URL: http://www.floboard.co.jp\n";
            mail_message += "一般労働者派遣: 派13-308001\n";
            mail_message += "Pマーク: 第10824573(01)号";
            mail_message += "\nーーーーーーーーーーーーーーーーーーーーーーーーーー";
            

            setState({
                ...state,
                send_mail_arr : {
                    case_ids : checkedRows.join(","),
                    mail_to : "見込客の要員メールアドレス",
                    mail_subject : `【FloBoard案件】●●●●案件のご紹介`,
                    mail_message : mail_message,
                },
            })
            setOpen(true);
        });
    }

   
  

    const handleSkill = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        const filter_skill = filter_list.filter((filter) => {
            return filter == "skill_list";
        });
        if(filter_skill.length == 0){
            filter_list.push("skill_list");
        }
        if(isChecked === true){
            skill_list.push(value);
            setSkillList(skill_list);
            getData(filter_list,sale,hr,case_status, perPage,customer,skill_list,page, search,skill_list,sort,column,order, recurit_area,search_skill);
        }
        else{            
            // setSkillList(skill_list.filter((skill) => {
            //     return skill !== value;
            // }));
            const skill = skill_list.filter(function(skill){
                return skill != value; 
             });
             setSkillList(skill);
             getData(filter_list,sale,hr,case_status, perPage,customer,skill,page, search,skill,sort,column,order, recurit_area, search_skill);
        }
    }


    let location = useLocation();

    const goToDetail = function (colData:any, metaCell:any) {
        if(metaCell.colIndex){
            cases.forEach((element, index) => {
                if (metaCell.rowIndex == index ) {
                    localStorage.setItem('rootRoute', location.pathname);
                    localStorage.setItem("previousRoute" , location.pathname);
                    window.location.href = `/cases/${element["encode"]}`;
                }
            });
        }
       
    }
  
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);

    const options = {
        filterType: 'checkbox' as any,
        responsive: "scrollMaxHeight" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page : page,
        rowsPerPageOptions:[10,20,50,100,200],
        selectableRows: true as any,
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
        // onDownload: (buildHead:any, buildBody:any, columns:any, rows:any) => {
        //     console.log(rows); //  <-------- can't get only selected rows
        //   },
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
            const handleClick = () => {
                
                //console.log(selectedRows.data);
                
            };

                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                cases.forEach((element, index) => {
                    if (rows.includes(index)) {
                        csvData.push(element);
                    }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "案件" +date;
                }
                let headers  : any[]= [];
                
                if(customerchecked && salerchecked){
                     headers = [ 
                        {
                            key: "case_id",
                            label: "案件ID",
                        },
                        {
                            key: "case_name",
                            label: "案件名",
                        },
                        {
                            key: "customer_id",
                            label: "取引先ID",
                        },
                        {
                            key: "customer-company",
                            label: "取引先",
                        },
                        {
                            key: "start_date_plan",
                            label: "開始年月日"
                        },
                        {
                            key: "case_overview",
                            label: "案件概要"
                        },
                        {
                            key: "required_skill",
                            label: "要求スキル"
                        },
                        {
                            key: "work_place",
                            label: "作業場所",
                        },
                        {
                            key: "case_period_type",
                            label: "期間",
                        },
                        {
                            key: "recruit_num",
                            label: "人数",
                        },
                        {
                            key: "saler_name_jp",
                            label: "営業担当者",
                        },
                        {
                            key: "hr_name_jp",
                            label: "HR担当者",
                        },
                        {
                            key: "requested_date",
                            label: "依頼日"
                        },
                        {
                            key: "update_date",
                            label: "更新日"
                        },   
                     ];
                }else if(customerchecked){
                    headers  = [
                            {
                                key: "case_id",
                                label: "案件ID",
                            },
                            {
                                key: "case_name",
                                label: "案件名",
                            },
                            {
                                key: "customer_id",
                                label: "取引先ID",
                            },
                            {
                                key: "customer-company",
                                label: "取引先",
                            },
                            {
                                key: "start_date_plan",
                                label: "開始年月日",
                            },
                            {
                                key: "case_period_type",
                                label: "期間種別",
                            },
                            {
                                key: "recruit_num",
                                label: "募集人数",
                            },
                            {
                                key: "case_overview",
                                label: "案件概要",
                            },
                            {
                                key: "required_skill",
                                label: "要求スキル",
                            },
                            {
                                key: "work_place",
                                label: "作業場所",
                            },
                            {
                                key: "hr_name_jp",
                                label: "HR担当者",
                            },
                            {
                                key: "requested_date",
                                label: "依頼日",
                            },
                            {
                                key: "update_date",
                                label: "更新日",
                            },
                    ];
                }else if(salerchecked){
                    headers = [
                        {
                            key: "case_id",
                            label: "案件ID"
                        },
                        {
                            key: "case_name",
                            label: "案件名",
                        },
                        {
                            key: "start_date_plan",
                            label: "開始年月日",
                        },
                        {
                            key: "case_period_type",
                            label: "期間種別",
                        },
                        {
                            key: "recruit_num",
                            label: "募集人数",
                        },
                        {
                            key: "case_overview",
                            label: "案件概要",
                        },
                        {
                            key: "work_place",
                            label: "作業場所",
                        },
                        {
                            key: "saler_name_jp",
                            label: "営業担当者",
                        },
                        {
                            key: "hr_name_jp",
                            label: "HR担当者",
                        },
                        {
                            key: "requested_date",
                            label: "依頼日",
                        },
                        {
                            key: "update_date",
                            label: "更新日",
                        },
                    ];
                }else{
                    headers = [
                        { label: "案件ID", key: "case_id" },
                        { label: "案件名", key: "case_name" },
                        { label: "開始年月日", key: "start_date_plan" },
                        { label: "期間", key: "case_period_type" },
                        { label: "人数", key: "recruit_num" },
                        { label: "案件概要", key: "case_overview" },
                        { label: "要求スキル", key: "required_skill" },
                        { label: "作業場所", key: "work_place"},
                        { label: "HR担当者", key: "hr_name_jp" },   
                        { label: "依頼日", key: "requested_date" },   
                        { label: "更新日", key: "update_date" },   
                    ];
                }


            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            setSelectedRows([]);
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onRowClick: (rowData:any, rowState:any) => {
            // console.log("start");
        //    goToDetail(rowData, rowState);
            // console.log("end");
        },
        onCellClick : (colData:any, cellMeta:any) =>{
            if(cellMeta.colIndex == 1){
               goToDetail(colData, cellMeta);
            }
        },
        onRowsDelete(rowData :any, rowState:any) {
            handleDelete(rowData, rowState);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
        // onChangePage: (page) => {
        // this.setState({page}, () => {
        //     this.sort(this.state.sortOrder.name, this.state.sortOrder.direction);
        // });
        // }
    };
 
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {

        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    localStorage.setItem("case_list_search", e.target.value);
                    getData(filter_list,sale,hr,case_status,perPage,customer,saler,page, e.target.value, skill_list,sort,column,order, recurit_area, search_skill);              
                break;
            case "search_skill":
                setSearchSkill(e.target.value);
                getData(filter_list,sale,hr,case_status,perPage,customer,saler,page, search, skill_list,sort,column,order, recurit_area, e.target.value);              
                break;
            default:
                break;
        }
        
    };  
    
    const handleStatus = function(status:any){
        setActive(status);
        setCaseStatus(status);
        localStorage.setItem("case_list_status", status);
        // if(status == "now_available" || status == "next_month_available" || status == "next_two_month_available"){
        //     setProcessingActive(status);
        //     showProcessingStatus(true);
        //     setActive("processing");
          
        // }else if(status == "processing"){
        //     showProcessingStatus(true);
        //     setProcessingActive("");
        // }else{
            setProcessingActive(status);
            showProcessingStatus(false);
        //}
        getData(filter_list,sale,hr,status, state.perPage,customer,saler,state.page, search, skill_list,sort,column,order, recurit_area, search_skill);
        // getData(filter_list,sale,hr,status,skill_list,search,sort);
    }

    const deleteCase = () => {
        if(selectedRows.length == 0){
            alert("削除したい案件をチャックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            cases.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["case_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            CaseListService.deleteList(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }else{
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error);
            });
        }
       
    }

    const modal_box = (
        <div>
              <Row>
                    <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </Col>            
                </Row>
                <h3>一括メール送信</h3>
                <form className="form" onSubmit={emailSend}>
                    <input type="hidden" name="case_ids" value={send_mail_arr["case_ids"]} />
                   <div className="form-group">
                        <label htmlFor="exampleInputEmail1">件名:</label> 
                        <input type="text" className="form-control" name="mail_subject"  value={send_mail_arr['mail_subject']} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">TO:</label> 
                        <input type="text" className="form-control" name="mail_to"  value={send_mail_arr['mail_to']}  disabled/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">メール内容:</label> 
                        <textarea name="mail_message" rows={12} cols={40} className="form-control"  value={send_mail_arr['mail_message']} id="send_mail_model_text">
                        </textarea>
                    </div>                    
                    <Row>
                        <div className="form-group mt-5 text-center">
                            {/* <button type="submit" className="button btn_cancel">キャンセル</button> */}
                            <button type="submit" className="button submit-btn btn_standard">送信</button>
                        </div>
                    </Row>
                </form>
        </div>
    )     
  
      
    return(
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>

                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" value={search} placeholder = {(login_user.role==1 || login_user.role==2 || login_user.role==9)?"検索(案件ID、案件名、一行アピール、職種、募集エリア、案件概要、作業場所、取引先名を検索)":"検索(案件ID、案件名、一行アピール、職種、募集エリア、案件概要、作業場所、スキルを検索)"}
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
                </AppBar>
                <Container>
                        <Row>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                <h3 className='header-left-design'>案件一覧 </h3>
                            </Col>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={4}>
                                <div className="mt-3 text-center">
                                    {( loading   == true) ? <Loader /> :<> </>}
                                </div>
                            </Col>
                            <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="float-end">
                                <div className="mt-4">
                                {
                                (() => {
                                    if(login_user.role ==1 || login_user.role == 2 || login_user.role == 8 ||login_user.role == 9) {
                                    return(<Link to={`/cases/create`} className="btn_add">
                                        案件追加
                                    </Link>)
                                    }
                                })()
                                }
                                </div>
                            </Col>
                        </Row> 
                        {/* <div className="row" >
                            <div className="offset-md-1 float-start  mt_40">
                                <ul className="status-menu  m-0 filter" data-filter="status" style={show_processing_status==true ? {display:"inline-flex"} : {display:"none"}}>
                                    {processing_status_list != null && processing_status_list.map(status => (
                                        <li className={(status[0] == processing_active)?processing_active:""}>
                                            <a onClick={() => handleStatus(status[0])}>
                                                {status[1]}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div> */}
                        <Row>
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                <ul className="status-menu filter responsive-list" data-filter="status">
                                    {status_list.map(status => (
                                        <li  className={"list-item " +((status[0] == active)?active:"")}>
                                            <a onClick={() => handleStatus(status[0])}>
                                                {status[1]}
                                            </a>
                                            {/* {
                                                (() => {
                                                    if(status[0] == "processing"){
                                                        return(
                                                            <ul className="filter sub-status-menu" data-filter="status" style={show_processing_status==true ? {display:"inline-flex"} : {display:"none"}}>
                                                                {processing_status_list != null && processing_status_list.map(status => (
                                                                    <li className={(status[0] == processing_active)?processing_active:""}>
                                                                        <a onClick={() => handleStatus(status[0])}>
                                                                            {status[1]}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )
                                                
                                                    }})()
                                                } */}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                <ul className="status-menu filter responsive-list" data-filter="status">
                                    {status_list1 != null && status_list1.map(status => (
                                        <li className={"list-item "+((status[0] == active)?active:"")}>
                                            <a onClick={() => handleStatus(status[0])}>
                                                {status[1]}
                                            </a>
                                        </li>
                                    ))}
                                    {status_list2 != null && status_list2.map(status => (
                                        <li className={"list-item "+((status[0] == active)?active:"")}>
                                            <a onClick={() => handleStatus(status[0])}>
                                                {status[1]}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col  xxl={6} xl={6} md={6} sm={6} xs={12} className="check-list">
                                {
                                            (() => {
                                                if(login_user.role==9 || (login_user.role==1 || login_user.role==2 || login_user.role==4 && login_user.user_id == flb_sale_id) ){
                                                    return(
                                                        <input className="" type="checkbox" id="customer_chk" name="customer" checked={customerchecked} onChange={handleChange} /> 
                                                    )
                                                }
                                            })()
                                 }
                                 {
                                            (() => {
                                                if(login_user.role==9 || (login_user.role==1 || login_user.role==2 || login_user.role==4 && login_user.user_id == flb_sale_id)){
                                                    return(
                                                        <label htmlFor="customer_chk" className="radio-group-lable">取引先表示</label> 
                                                    )
                                                }
                                            })()
                                 }
                                {/* <input className="" type="checkbox" id="flexCheckDefault" name="customer" checked={customerchecked} onChange={handleChange} /> 取引先表示 */}
                                {
                                            (() => {
                                                if(login_user.role==2 || login_user.role==3 || login_user.role==4 || login_user.role==9){
                                                    return(
                                                        <input className="ml_20" type="checkbox" value="" id="saler_chk" name ="saler" checked={salerchecked} onChange={handleChange} /> 
                                                    )
                                                }
                                            })()
                                 }
                                 {
                                            (() => {
                                                if(login_user.role==2 || login_user.role==3 || login_user.role==4 || login_user.role==9){
                                                    return(
                                                        <label htmlFor="saler_chk" className="radio-group-lable">営業担当者表示</label> 
                                                    )
                                                }
                                            })()
                                 }
                                {/* <input className="ml_20" type="checkbox" value="" id="flexCheckDefault" name ="saler" checked={salerchecked} onChange={handleChange} /> 営業担当者表示 */}
                                {
                                            (() => {
                                                if(login_user.role!=3 && login_user.role!=7){
                                                    return(
                                                        <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteCase}>削除</button>
                                                    )
                                                }
                                            })()
                                 }
                                   {
                                            (() => {
                                                if(login_user.role==1 || login_user.role==2 || login_user.role==4 || login_user.role==9){
                                                    return(
                                                        <button type="button" className="btn_standard ml_20 btn-sm" onClick={() => {
                                                            sendMail();
                                                            }}>一括案件送信
                                                        </button>
                                                    )
                                                }
                                            })()
                                     }  
                                {/* <button type="button" className="btn_danger ml_20 btn-sm" onClick={deleteCase}>削除</button> */}
                            </Col>
                        </Row>
                        {/* <Row className="p-2"> */}
                        <Row className="p-2 g-0">
                            <Col xxl={10} xl={12} md={12} sm={12} xs={12}>
                                <div className="check-list filter" data-filter="skill_list" id="case-filter-by-skill" style={{paddingTop:'15px'}}>
                                    <input type="checkbox" id="java" name="java" value="java" onChange={e => handleSkill(e)} />
                                    <label htmlFor="java"> JAVA</label><span className="space"></span>
                                    <input type="checkbox" id="c" name="c" value="c" onChange={e => handleSkill(e)} />
                                    <label htmlFor="c"> C/C++</label><span className="space"></span>
                                    <input type="checkbox" id="asp" name="asp" value="asp" onChange={e => handleSkill(e)} />
                                    <label htmlFor="asp"> ASP.NET/C#</label><span className="space"></span>
                                    <input type="checkbox" id="vb" name="vb" value="vb" onChange={e => handleSkill(e)}/>
                                    <label htmlFor="vb"> VB/VB.NET</label><span className="space"></span>
                                    <input type="checkbox" id="php" name="php" value="php" onChange={e => handleSkill(e)}/>
                                    <label htmlFor="php"> PHP</label><span className="space"></span>
                                    <input type="checkbox" id="ruby_python" name="ruby" value="ruby_python" onChange={e => handleSkill(e)}/>
                                    <label htmlFor="ruby_python"> Ruby/Python</label><span className="space"></span>
                                    <input type="checkbox" id="sap" name="sap" value="sap"  onChange={e => handleSkill(e)}/>
                                    <label htmlFor="sap"> SAP</label><span className="space"></span>
                                    <input type="checkbox" id="plsql" name="plsql" value="plsql" onChange={e => handleSkill(e)} />
                                    <label htmlFor="plsql"> PLSQL(PL/SQL)</label><span className="space"></span>
                                    <input type="checkbox" id="handoutai" name="handoutai" value="handoutai" onChange={e => handleSkill(e)} />
                                    <label htmlFor="handoutai"> 半導体</label><span className="space"></span>
                                    <input type="checkbox" id="other" name="other" value="other" onChange={e => handleSkill(e)} />
                                    <label htmlFor="other"> その他</label>
                                </div>
                            </Col>
                            <Col xxl={2} xl={12} md={12} sm={12} xs={12}>
                                <input type="text" placeholder="and スキルを検索" name="search_skill" id="search_skill" className="form_control ml-2" onChange={onChange} style={{borderRadius:"15px !important", width:"100%",height:"30px"}}/>
                                {/* <input type="text" placeholder="and スキルを検索" name="search_skill" id="search_skill" className="form_control ml-2" onChange={onChange} style={{borderRadius:"15px !important",width:"303px",height:"30px"}}/> */}
                            </Col>
                        </Row>
                        <Row className="p-2">
                            <Col xxl={3} xl={3} md={3} sm={3} xs={12} className="xs-hidden">
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                            </Col>
                            <Col xxl={9} xl={9} md={9} sm={9} xs={12} className="filter_select xs-hidden">
                                <div className="float-right">
                                    {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <label htmlFor="sort" className="control-label">営業担当者</label>
                                                    )
                                                }
                                            })()
                                     }
                                     {
                                            (() => {
                                                if(login_user.role!=7){
                                                    return(
                                                        <select className="filter" name="flb_sales_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="-1">全て</option>
                                                            {saleList.map(s_id => {
                                                            return(<option value={s_id["user_id"]}> {s_id["name_jp"]}</option>)
                                                 })
                                                    }
                                                        </select> 
                                                    )
                                                }
                                            })()
                                     }
                                
                                     {
                                            (() => {
                                                if(login_user.role!=8){
                                                    return(
                                                        <label htmlFor="sort" className="control-label">HR担当者</label>
                                                    )
                                                }
                                            })()
                                     }
                                    {
                                            (() => {
                                                if(login_user.role!=8){
                                                    return(
                                                        <select className="filter" name="flb_hr_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                                        <option value="-1">全て</option>
                                                            {hrList.map(h_id => {
                                                            return(<option value={h_id["user_id"]}> {h_id["name_jp"]}</option>
                                             )
                                            })
                                    }
                                    </select> 
                                                    )
                                                }
                                            })()
                                     }
                                    <label htmlFor="sort" className="control-label">募集</label>
                                    <select className="filter" name="recurit_area"  aria-label=".form-select-sm example" onChange={onSelect}>
                                        <option value="-1">全て</option>
                                        {recuritAreaList.map(recurit_area => {
                                                return(<option value={recurit_area}> {recurit_area}</option>)
                                            })
                                        }
                                    </select> 
                                    <select name="list_length" id="list_length" value={no_of_rows} className="list_length" onChange={onSelect}>
                                        <option value="10">10</option>
                                        <option value="20" >20</option>
                                        <option value="50" >50</option>
                                        <option value="100" >100</option>
                                        <option value="200" >200</option>
                                    </select>
                                    <label htmlFor="sort" className="control-label">件表示</label>
                                    <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                        previousPage();
                                        }}>
                                     <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                    </button>
                                    <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                        nextPage();
                                        }}>
                                        <i className="fa fa-chevron-right" aria-hidden="true" style={{color: showNextColor}}></i>  
                                    </button> 

                                </div>
                            </Col>
                            <Col xxl={9} xl={9} md={9} sm={9} xs={12} className="filter_select pc-hidden">
                                <Row>
                                    <Col xs={12}>
                                        {
                                                (() => {
                                                    if(login_user.role!=7){
                                                        return(
                                                            <label htmlFor="sort" className="control-label">営業担当者</label>
                                                        )
                                                    }
                                                })()
                                        }
                                        {
                                                (() => {
                                                    if(login_user.role!=7){
                                                        return(
                                                            <select className="filter" name="flb_sales_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                                            <option value="-1">全て</option>
                                                                {saleList.map(s_id => {
                                                                return(<option value={s_id["user_id"]}> {s_id["name_jp"]}</option>)
                                                    })
                                                        }
                                                            </select> 
                                                        )
                                                    }
                                                })()
                                        }  
                                    </Col>
                                    <Col xs={12}>
                                        {
                                                (() => {
                                                    if(login_user.role!=8){
                                                        return(
                                                            <label htmlFor="sort" className="control-label">HR担当者</label>
                                                        )
                                                    }
                                                })()
                                        }
                                        {
                                                (() => {
                                                    if(login_user.role!=8){
                                                        return(
                                                            <select className="filter" name="flb_hr_id" aria-label=".form-select-sm example" onChange={onSelect}>
                                                            <option value="-1">全て</option>
                                                                {hrList.map(h_id => {
                                                                return(<option value={h_id["user_id"]}> {h_id["name_jp"]}</option>
                                                )
                                                })
                                        }
                                        </select> 
                                                        )
                                                    }
                                                })()
                                        }
                                    </Col>
                                    <Col xs={12}>
                                        <label htmlFor="sort" className="control-label">募集</label>
                                        <select className="filter" name="recurit_area"  aria-label=".form-select-sm example" onChange={onSelect}>
                                            <option value="-1">全て</option>
                                            {recuritAreaList.map(recurit_area => {
                                                    return(<option value={recurit_area}> {recurit_area}</option>)
                                                })
                                            }
                                        </select> 
                                    </Col>
                                    <Col xs={12}>
                                        <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                        <select name="sort" id="sort" onChange={onSelect} style={{marginRight:"unset"}}>
                                            <option value="0" selected>新しい順</option>
                                            <option value="1">古い順</option>
                                        </select>
                                   
                                        <div className="float-right">
                                            <select name="list_length" id="list_length" value={no_of_rows} className="list_length" onChange={onSelect}>
                                                <option value="10">10</option>
                                                <option value="20" >20</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>
                                                <option value="200" >200</option>
                                            </select>
                                            <label htmlFor="sort" className="control-label">件表示</label>
                                            <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                                previousPage();
                                                }}>
                                            <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                            </button>
                                            <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                                nextPage();
                                                }}>
                                                <i className="fa fa-chevron-right" aria-hidden="true" style={{color: showNextColor}}></i>  
                                            </button> 
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                         
                                <div className='datatable freeze-sale-header freeze-sale-cell frozen-columns  person_list xs-hidden'>                                  
                                    <MUIDataTable
                                        title={''}
                                        data={cases}
                                        columns={columnVisible}
                                        options = {options}
                                    />
                                </div>
                            </Col>
                            <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                                <div className='datatable mobile pc-hidden'>
                                        <MUIDataTable
                                            title={''}
                                            data={cases}
                                            columns={columnVisible}
                                            options = {options}
                                        />
                                </div>
                            </Col>
                        </Row>
                </Container>
                                 
            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
        >                                
            <Box sx={ PCModalStyle}>
               {modal_box}
            </Box>
        </Modal>

        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
        >                                
            <Box sx={MobileModalStyle}>
               {modal_box}
            </Box>
          
        </Modal>
        </React.Fragment>
    );
};
export default CaseList;