import React, {useState , useEffect} from 'react';
// import {User} from "../../models/User";
import UserService from "../../services/UserService";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Topbar from "../../components/common/Topbar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import DropDownList from "../../components/common/DropDownList";
import { CSVLink, CSVDownload } from "react-csv";
import {Link} from "react-router-dom";
import {UserModel} from "../../models/IUser";
import Modal from '@mui/material/Modal';
import {Box} from '@mui/material';
import DatePicker from "react-datepicker";
import {showErrorInfo, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";
import Loader from "../../components/common/Loader";
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface IState{
    loading :boolean;
    users :  [];
    errorMessage : string;  
    perPage : number;
    page : number;
    column: string;
    order: string;
    totalRows : number;
    user_model : UserModel;
    priviledgegrp_list : [];
    position_list : [];
    role_list : [];
}
interface IProps{}

let UserList:React.FC<IProps> = () =>{
    
    let [state, setState] = useState<IState>({
        loading : false,
        users :  [],
        errorMessage : "",
        perPage : 10,
        page : 0,
        column : "",
        order : "",
        totalRows : 0,
        user_model : {
            user_id : "",
            name_jp : "",
            kana_name : "",
            email : "",
            photo: "",
            password : "",
            tel_no : "",
            privilege_grp_cd : "",
            company : "",
            dept_name : "",
            position : "",
            respond_area : "",
            role : "",
            admission_date : "",
            withdraw_date : "",
            withdraw_reason : "",
            memo : ""
        },
        priviledgegrp_list : [],
        position_list : [],
        role_list : []
    });
    const [search, setSearch] = useState("");  
    const [sort, setSort] = useState("0");
    const [no_of_rows, setNoOfRows] = useState(10);
    const [current_page, setCurrentPage] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [pwdPlaceholder, setPwdPlaceholder] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false); 
    const [selectedWithdrawDate, setSelectedWithdrawDate] = useState(null);
    const [selectedAdmissionDate, setSelectedAdmissionDate] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [width, setWidth] = useState(719);
    const [height, setHeight] = useState(700);
    const [showPrevColor, setShowPrevColor] = useState("grey");
    const [showNextColor, setShowNextColor] = useState("grey");
    
    const getData = async (perPage:number, page:number, search:any, sort:any, column:string, order:string) => {
        setState({...state,loading :true,})
        UserService.list( perPage, page, search, sort, column, order).then((response)=>{
            setState({
                ...state,
                loading:false,
                users:response.data.data,
                page : response.data.current_page,
                column : response.data.column,
                order : response.data.order,
                perPage : response.data.per_page,
                totalRows : response.data.total,
                priviledgegrp_list : response.data.select_options.privilege_grp_cd,
                position_list : response.data.select_options.position,
                role_list :  response.data.select_options.role
            })
            if(Math.floor(response.data.total/response.data.per_page) >0){
                setShowNextColor("blue");
            }else{
                console.log("Response2222 " +response.data.per_page);
                setShowNextColor("grey");
            }
            if(0 == response.data.current_page-1){
                console.log("Response3333 " +response.data.per_page);
                setShowPrevColor("grey");
            }
            // if(Math.ceil(totalRows/perPage)-2 == current_page){  // Last Page
            //     setShowNextColor("grey");
            // }
        }).catch((error) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
            setState({
                ...state,
                loading:false,
                errorMessage:error.message
            })
        })
    }   
        
    let {loading, users, perPage, page, column, order, totalRows, errorMessage , user_model,priviledgegrp_list,position_list,role_list} = state;
    const [selected, setSelected] = useState('base');
    
    useEffect(()=>{
        setState({...state, loading: true});
        getData(perPage, page, search, sort, column, order);
    }
    , [perPage]);
    let changePage = (page:any) => {
        setCurrentPage(page);
        getData(perPage, page+1, search, sort, column, order);
    };
    let changeRowsPerPage = (perPage:any) => {
        getData(perPage, page, search, sort, column, order);
    };

    const onSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        switch (e.target.name) {
            case "sort":
                setSort(e.target.value);
                getData(perPage, page, search, e.target.value, column, order);
                break;
            case "list_length":
                changeRowsPerPage(e.target.value);
                setNoOfRows(parseInt(e.target.value));
                setState({
                    ...state,
                    perPage : parseInt(e.target.value)

                })
                break;
                
            default:
                break;
        }
     }; 

     const handleResize = (event:any) => {
        const newWidth = event.target.offsetWidth;
        const newHeight = event.target.offsetHeight;
        setWidth(newWidth);
        setHeight(newHeight);
      };

    const defaultColumns = [
        {
            name: "user_id",
            label: "編集",
            options: {
                filter: true,
                resizable: true,
                // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "500px" }}),
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    // return(<Link to={`/cases/${tableMeta.rowData[1]}`} >{value}</Link>);
                        return(<button type="button" className="btn_standard btn-sm" onClick={() => {
                            updateUser(`${tableMeta.rowData[0]}`);
                            }}>編集
                        </button>);
                    },
                },
        },
        {
            "name" : 'user_id', 
            "label" : "利用者ID"
        },
        {
            "name" : 'name_jp',
            "label" : "漢字氏名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'kana_name', 
            "label" : "カタカナ氏名",
            class: "longText",
            options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"150px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'email', 
            "label" : "メールアドレス",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'tel_no', 
            "label" : "電話番号"
        },
        {
            "name" : 'privilege_grp_cd', 
            "label" :  "職種"
        },//
        {
            "name" : 'company', 
            "label" : "会社名",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'dept_name',
            "label" : "部門名",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },//
        {
            "name" : 'position', 
            "label" : "役職"
        },//
        {
            "name" : 'respond_area', 
            "label" : "担当エリア"
        },
        {
            "name" : 'role', 
            "label" : "権限"
        },
        {
            "name" : 'admission_date', 
            "label" : "入会年月日"
        },
        {
            "name" : 'withdraw_date', 
            "label" : "退会年月日"
        },
        {
            "name" : 'withdraw_reason', 
            "label" : "退会理由",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'memo', 
            "label" : "特記事項",
             class: "longText",
             options: {
                filter: true,
                customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
                    return(
                            <div className='longText' title={value} style={{width:"100px"}}>
                                    {value}
                            </div>
                        );
                    },
                },
        },
        {
            "name" : 'database_id', 
            "label" : "データベースID"
        },
    ]

    let handleSort = (column:any, order:any) => {
        getData(perPage, page, search, sort, column, order);
    };

    let nextPage = () => {
        
        // if(Math.floor(totalRows/perPage) >0){
        //     setShowNextColor("blue");
        // }
    
        if(Math.floor(totalRows/perPage) >0){
            changePage(current_page+1);
            setCurrentPage(current_page+1);
            setState({
                ...state,
                page : current_page+1
            })
            setShowNextColor("blue");
        }
        
        if(Math.ceil(totalRows/perPage)-2 == current_page){ // Last Page
            setShowNextColor("grey");
        }   
        if(0 < current_page+1){
            setShowPrevColor("blue");
        }     
    
    }
    
    let previousPage = () => {
        if(current_page > 0){
            setCurrentPage(current_page-1);
            changePage(current_page - 1);
            setState({
                ...state,
                page : current_page-1
            });
            setShowPrevColor("blue");
        }
        if(0 == current_page-1){
            setShowPrevColor("grey");
        } 
    
        if(Math.floor(totalRows/perPage) >0){
            setShowNextColor("blue");
        } 
        
    }

    const options = {
        filterType: 'checkbox' as any,
        responsive: "scrollMaxHeight" as any,
        selectableRowsHeader: true as any, 
        rowsPerPage: perPage,
        page: page,
        rowsPerPageOptions:[10,20,50,100,200],
        selectableRows: true as any,
        // selectableRowsOnClick: true  as any,
        count: totalRows,
        serverSide: true,
        filter : false,
        print: false,
        viewColumns : false,
        sort: true,
        search : false,
        download :false,
        // resizableColumns: true,
    
        customToolbarSelect: (selectedRows:any, displayData:any, setSelectedRows:any) => {         
                let rows : any[] = [];
                selectedRows.data.forEach((element:any, index:any) => {
                    rows.push(element.dataIndex);
                })
                let csvData : any[] = [];
                users.forEach((element, index) => {
                        if (rows.includes(index)) {
                            csvData.push(element);
                        }
                });
                const getFileName = () => {
                    let now = new Date().toLocaleDateString();
                    let year = now.slice(0,4);
                    let month = '0' + now.slice(5,6);
                    let day = now.slice(7);
                    let date = year+month+day;
                    return "利用者" +date;
                }
            let headers = [
                {
                    "key" : 'user_id', 
                    "label" : "利用者ID"
                },
                {
                    "key" : 'name_jp',
                    "label" : "漢字氏名"
                },
                {
                    "key" : 'kana_name', 
                    "label" : "カタカナ氏名"
                },
                {
                    "key" : 'email', 
                    "label" : "メールアドレス"
                },
                {
                    "key" : 'tel_no', 
                    "label" : "電話番号"
                },
                {
                    "key" : 'privilege_grp_cd', 
                    "label" :  "職種"
                },//
                {
                    "key" : 'company', 
                    "label" : "会社名"
                },
                {
                    "key" : 'dept_name',
                    "label" : "部門名"
                },//
                {
                    "key" : 'position', 
                    "label" : "役職"
                },//
                {
                    "key" : 'respond_area', 
                    "label" : "担当エリア"
                },
                {
                    "key" : 'role', 
                    "label" : "権限"
                },
                {
                    "key" : 'admission_date', 
                    "label" : "入会年月日"
                },
                {
                    "key" : 'withdraw_date', 
                    "label" : "退会年月日"
                },
                {
                    "key" : 'withdraw_reason', 
                    "label" : "退会理由"
                },
                {
                    "key" : 'memo', 
                    "label" : "特記事項"
                }
              ];
            var now = new Date();
            return (
                <CSVLink  className="btn_standard text-decoration-none" filename={getFileName()} data={csvData}  headers={headers}>ダウンロード</CSVLink>
            );
          },
        textLabels: {
            body: {
              noMatch: "データはありません。",
              toolTip: "Sort"
            },
            pagination: {
              next: "Next Page",
              previous: "Previous Page",
              rowsPerPage: "件表示", 
              displayRows: "すべての件数：",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "行 を選択しました。",
                delete: "削除",
                deleteAria: "選択した行を削除しました。",
              },
          },
        rowsSelected: selectedRows,
        onRowSelectionChange: (rowsSelectedData:any, allRows:any, rowsSelected:any) => {
            setSelectedRows(rowsSelected);
        },
        onChangePage (currentPage:any) {
            changePage(currentPage);
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage (numberOfRows:any) {
            changeRowsPerPage(numberOfRows);
            setNoOfRows(numberOfRows);
        },
        onColumnSortChange: (changedColumn:any, direction:any) => {
            let order = 'desc';
            if (direction === 'asc') {
              order = 'asc';
            }
            handleSort(changedColumn, order);
          },
    };
 
    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => { 
        switch (e.target.name) {
            case "search":
                    setSearch(e.target.value);
                    getData(perPage, page, e.target.value, sort, column, order);
                break;
            default:
                break;
        }
      };
     

     const getMuiTheme = () =>
     createTheme({
         components: {
         MUIDataTable: {
             styleOverrides:{
                 responsiveScroll: {
                     maxHeight: '580px',
                     overflowX : "scroll"
                   },
             }
         },
         MuiPaper: {
             styleOverrides:{
                 root: {
                     width: "100%",
                    //  marginTop: "5%",
                    //  marginLeft: "5%",
                    //  marginBottom: "20%"
                 }
             }
         },  
         MUIDataTableHeadCell: {
                 styleOverrides:{
                   root: {
                       backgroundColor: "#444",
                       padding: '0px'
                   }
                 }
         },
         MuiTableCell: {
             styleOverrides:{
                 head: {
                         color : 'white'
                 }
             }
         },
           MUIDataTableBodyCell: {
             styleOverrides:{
               root: {
                   backgroundColor: "#f1f1f1",
                   width: "100%",
                   padding: '0px'
               }
             }
           }
         }
       })
     
    //    const style = {
    //     position: 'absolute' as 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     minHeight: 700,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //   };
      let updateTextArea = (event:React.ChangeEvent<HTMLTextAreaElement>):void => {
        setState({
            ...state,
            user_model : {
                ...state.user_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });  
    }
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            ...state,
            user_model : {
                ...state.user_model,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
        setShowPassword(true);
    };
    const onSelectUser = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            ...state,
            user_model : {
                ...state.user_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });
    }

    const handleDateChange = function(date:any, column_name:any){
        let selected_data =(date != null)? date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate()) : "";
        // if (selected_data === ""){
        //     setError('入力してください')
        // }else{
        //     setError('')
        // }
        //let selected_data = date.getFullYear() +"-"+ (date.getMonth() + 1) +"-"+ (date.getDate());        
            switch (column_name) {
                case "admission_date":
                    setSelectedAdmissionDate(date);
                    setState({
                        ...state,
                        user_model : {
                            ...state.user_model,
                            admission_date : selected_data // input name 'username' set value 'event target value'
                        },
                    });

                    break;
                case "withdraw_date":
                    setSelectedWithdrawDate(date);
                    setState({
                        ...state,
                        user_model : {
                            ...state.user_model,
                           withdraw_date : selected_data // input name 'username' set value 'event target value'
                        },
                    });
                    break;
                
                default:
                    break;
            }
    
        }
    

    
    const storeUpdateUser = (event : React.FormEvent<HTMLFormElement>):void => { 
        event.preventDefault();

        // alert("Error");
        // alert(state.user_model.admission_date);
        // if(state.user_model.admission_date === ""){
        //     event.preventDefault();
        //     setError('入力してください');
        // }else{
        //     event.preventDefault();
        //     setError('');
        // }
        if(state.user_model.user_id == ""){
            
            UserService.store(state.user_model).then((response) => {
                window.location.href = "/setting/users/list";           
             }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                 if(error.response.data){
                     showErrorInfo(error.response.data);
                 }
             })
        }else{
            UserService.update(state.user_model, state.user_model.user_id).then((response) => {
                console.log(response);
                window.location.href = "/setting/users/list";           
             }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
                if(error.response.data){
                    showErrorInfo(error.response.data);
                }
     
             })
        }
        
    };

    const addUser = () => {
        setState({
            ...state,
            user_model : {
            user_id : "",
            name_jp : "",
            kana_name : "",
            email : "",
            photo : "",
            password : "",
            tel_no : "",
            privilege_grp_cd : "営業",
            company : "",
            dept_name : "",
            position : "担当",
            respond_area : "",
            role : "1",
            admission_date : "",
            withdraw_date : "",
            withdraw_reason : "",
            memo : ""
            }
        })
        $(".input-box .error").remove(); 
        setPwdPlaceholder("");
        setOpen(true);
    }

    let updateUser = (user_id:any) => {
        UserService.showUser(user_id).then((data)=>{
           setState({
            ...state,
            user_model : {
                user_id : data.data.user_id,
                name_jp : data.data.name_jp,
                kana_name : data.data.kana_name,
                email : data.data.email,
                photo : data.data.photo,
                password : data.data.password,
                tel_no : data.data.tel_no,
                privilege_grp_cd : data.data.privilege_grp_cd,
                company : data.data.company,
                dept_name : data.data.dept_name,
                position : data.data.position,
                respond_area : data.data.respond_area,
                role : data.data.role,
                admission_date : data.data.admission_date,
                withdraw_date : data.data.withdraw_date,
                withdraw_reason : data.data.withdraw_reason,
                memo : data.data.memo
            }
           });
           $(".input-box .error").remove();
           setPwdPlaceholder("*******");
           setOpen(true);
        }).catch((error:any) => {
            if(error.response.status == 403){
                alert(error.response.data.message);
            }
        });
    }

    const deleteUser = () => {
        if(selectedRows.length == 0){
            alert("削除したいパートナーをチェックしてください。");
            return false;
        }
        let ans = window.confirm("削除を実施します、大丈夫ですか？");
        if(ans){
            let ids : any[] = [];
            users.forEach((element, index) => {            
                if (selectedRows.length > 0 && selectedRows.includes(index)) {
                    ids.push(element["user_id"]);
                }
            });
    
            let data = {};
            data["ids"] = ids;
            UserService.deleteList(data).then((data)=>{
                if(data.data.success == false){
                    alert(data.data.info.message);
                }
                window.location.href = "/setting/users/list";           
            }).catch((error) => {
                if(error.response.status == 403){
                    alert(error.response.data.message);
                }
            });
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const edit_modal_box = (
        <div>
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={8}>
                        <h3>利用者{(user_model['user_id'] == "")? "登録": "編集"}</h3>
                    </Col>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={4}>
                         <button type="button" className="modal_close" onClick={handleClose}>X</button>
                    </Col>            
                </Row>
                {/* <h3>案件割り当て編集</h3> */}
                <form className="form user_form" onSubmit={storeUpdateUser}>
                    {/* <BulkMailModal data={send_mail_arr} /> */}
                    <input type="hidden" name="user_id" value={user_model["user_id"]} />
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required text">漢字氏名:</label> 
                                <input type="text" className="form-control" name="name_jp"  value={user_model['name_jp']}  onChange={updateInput} /> 
                            </div>       
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required text">カタカナ氏名:</label> 
                                <input type="text" className="form-control" name="kana_name"  value={user_model['kana_name']}  onChange={updateInput} />
                            </div>          
                        </Col>
                    </Row>      
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required text">メールアドレス:</label> 
                                <input type="text" className="form-control" name="email"  value={user_model['email']}  onChange={updateInput} /> 
                            </div>       
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">パスワード:</label> 
                                <input type={(showPassword==false) ?'text' : 'password'} className="form-control" name="password" placeholder={pwdPlaceholder} value={user_model['password']}  onChange={updateInput}/>
                                <i className="fas fa-eye eye-icon"  onClick={togglePasswordVisibility}/>
                            </div>
                        </Col>
                    </Row> 
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">電話番号:</label> 
                                <input type="text" className="form-control" name="tel_no"  value={user_model['tel_no']}  onChange={updateInput} /> 
                            </div>       
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={3} className="input-box">
                            <div className="form-group input-box">
                                <label htmlFor="exampleInputEmail1" >職種:</label> 
                                <select name="privilege_grp_cd" onChange={onSelectUser} className="form-control" value={user_model["privilege_grp_cd"]} style={{width:'100%'}}>
                                    {priviledgegrp_list.map(privilege_grp => {
                                            return(<option value={privilege_grp}> {privilege_grp}</option>)
                                        })
                                    }
                                </select>                           
                            </div>          
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={3} className="input-box">
                            <div className="form-group input-box">
                                <label htmlFor="exampleInputEmail1">役職:</label> 
                                <select name="position" onChange={onSelectUser}  className="form-control"  value={user_model["position"]} >
                                    {position_list.map(position => {
                                            return(<option value={position}> {position}</option>)
                                        })
                                    }
                                </select>                           
                            </div>          
                        </Col>
                    </Row>      
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="input-required">会社名:</label> 
                                <input type="text" className="form-control" name="company"  value={user_model['company']}  onChange={updateInput} />                                     
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">部門名:</label> 
                                <input type="text" className="form-control" name="dept_name"  value={user_model['dept_name']}  onChange={updateInput} />                                     
                            </div>           
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">担当エリア:</label> 
                                <input type="text" className="form-control" name="respond_area"  value={user_model['respond_area']}  onChange={updateInput} />                                     
                            </div>  
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={6} className="input-box">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">権限:</label> 
                                 <select name="role" onChange={onSelectUser} value={user_model["role"]} className="form-control">
                                    {role_list.map(role_list => {
                                            return(<option value={role_list[0]}> {role_list[1]}</option>)
                                        })
                                    }
                                </select>
                            </div>     
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group" style={{position:"relative",top:"11px"}}>
                                    <label htmlFor="exampleInputEmail1" className="input-required">入会年月日:</label><br/> 
                                    <DatePicker 
                                            value={(user_model["admission_date"] != null)?user_model["admission_date"]:""}
                                            dateFormat="yyyy/MM/dd"
                                            locale="ja"
                                            selected={selectedAdmissionDate} 
                                            onChange={date =>
                                                handleDateChange(date, "admission_date")
                                            }
                                            name="admission_date"
                                            isClearable
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="form-control"
                                    />
                            </div>                           
                        </Col>
                        <Col xxl={3} xl={3} md={3} sm={3} xs={6} className="input-box">
                            <div className="form-group" style={{position:"relative",top:"11px"}}>
                                <label htmlFor="contract_price_type">退会年月日:</label><br/>
                                <DatePicker 
                                        value={(user_model["withdraw_date"] != null)?user_model["withdraw_date"]:""}
                                        dateFormat="yyyy/MM/dd"
                                        locale="ja"
                                        selected={selectedWithdrawDate} 
                                        onChange={date =>
                                            handleDateChange(date, "withdraw_date")
                                        }
                                        name="withdraw_date"
                                        isClearable
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="form-control"
                                />     
                                </div>            
                        </Col>
                        <Col xxl={6} xl={6} md={6} sm={6} xs={12} className="input-box">
                            <div className="form-group" style={{position: "relative",top: "5px"}}>
                                <label htmlFor="withdraw_reason">退会理由</label>
                                <textarea className="form-control" rows={2} name = "withdraw_reason" id= "withdraw_reason" aria-label="With textarea" value={user_model['withdraw_reason']} onChange={updateTextArea}></textarea>
                            </div>
                        </Col>
                        {/* {(error)?<span id='star' className='error' style={{left:"10px",position:"relative",top:"10px"}}>{error}</span>:""} */}
                    </Row>
                    <Row>
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12} className="input-box">
                            <div className="form-group" style={{position: "relative",top: "5px"}}>
                                <label htmlFor="withdraw_reason">特記事項</label>
                                <textarea className="form-control" rows={2} name = "memo" id= "memo" aria-label="With textarea" value={user_model['memo']} onChange={updateTextArea}></textarea>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="form-group mt_3 text-center">
                            <button type="submit" className="button submit-btn btn_standard">{(user_model['user_id'] == "")? "登録": "更新"}</button>
                        </div>
                    </Row>
                </form>
        </div>
    )

    return(
        
       <React.Fragment>
         <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
                >
                 <Toolbar>
                <Typography variant="h6">
                    <header className="top-baner">
                        <Row>
                            <Col xxl={10} xl={10} md={10} sm={10} xs={10}>
                                <div id="search-autocomplete" className="form-outline">
                                    <input type="text" name="search" id="search" placeholder = "検索(漢字氏名、カタカナ氏名、メールアドレ、会社名、部署名、特記事項を検索)"
                                        className="form-control"  onChange={onChange}/>
                                </div>
                            </Col>
                            <Col xxl={2} xl={2} md={2} sm={2} xs={2} className="mt-4">
                                <DropDownList />
                            </Col>
                        </Row>
                    </header>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                    <Row>
                        <Col xs={12} className="pc-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6}>
                            <h3 className='header-left-design'>
                                利用者一覧
                            </h3>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={4} className="xs-hidden">
                            <div className="mt-3 text-center">
                                {( loading   == true) ? <Loader /> :<> </>}
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} md={4} sm={4} xs={6} className="float-end">
                            <div className="anken-add action mt-4">
                                <button type="button" className="btn_standard ml_20 btn-sm" onClick={addUser}>追加</button>
                                <button type="button" className="btn_danger ml_20 btn-sm"  onClick={deleteUser}>削除</button>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="pt-2 pb-2">
                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}>
                            <div style={{display:"inline"}}>
                                <span className="search_result_num"> 検索件数 : {totalRows}件</span>
                                <select name="sort" id="sort" className="filter" onChange={onSelect}>
                                    <option value="0" selected>新しい順</option>
                                    <option value="1">古い順</option>
                                </select>
                            </div>
                            <div className="float-right">
                                <select name="list_length" id="list_length" value={no_of_rows} className="filter list_length" onChange={onSelect}>
                                    <option value="10">10</option>
                                    <option value="20" >20</option>
                                    <option value="50" >50</option>
                                    <option value="100" >100</option>
                                    <option value="200" >200</option>
                                </select>
                                <label htmlFor="sort" className="control-label">件表示</label>
                                <button type="button" className="btn-sm previous-page-icon" style={{border:"0px"}} onClick={() => {
                                            previousPage();
                                            }}>
                                    <i className="fa fa-chevron-left" aria-hidden="true" style={{color: showPrevColor}}></i>
                                </button>
                                <button type="button" className=" btn-sm next-page-icon" style={{border:"0px"}} onClick={() => {
                                            nextPage();
                                            }}>
                                    <i className="fa fa-chevron-right" aria-hidden="true"  style={{color: showNextColor}}></i>  
                                </button> 
                            </div>
                        </Col>
                    </Row>   
                           
                    <div className='datatable user-freeze-header user-freeze-cell user-frozen-columns xs-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {users}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
                    <div className='datatable mobile pc-hidden'>
                        <MUIDataTable
                            title = {""}
                            data = {users}
                            columns={defaultColumns}
                            options={options}
                        />
                    </div>
            </Container>

            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'none', sm: 'block' },
            }}
            > 
                <Box
                sx={PCModalStyle}
                >
                {edit_modal_box}
                </Box>
            </Modal>    
            <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={ {
                display: { xs: 'block', sm: 'none' },
            }}
            > 
                <Box
                sx={MobileModalStyle}
                >
                {edit_modal_box}
                </Box>
            </Modal>           
        </React.Fragment>
    )

}

export default UserList;